import request from '../utils/request';
import qs from "qs";

//蜗牛查询列表
export const WnPhoneInfo = phone => {
	return request.get('/api/Snail/phoneInfo?phone=' + phone)
};
//蜗牛用户信息批量查询
export const batchQueryRecord = param => {
	return request.post('/api/Snail/batchQueryRecord', qs.stringify(param))
};
//蜗牛停复机记录
export const stopOrOpenRecord = param => {
	return request.post('/api/Snail/stopOrOpenRecord', qs.stringify(param))
};

//蜗牛批量查询
export const batchQuery = param => {
	return request.post('/api/Snail/batchQuery', qs.stringify(param))
};