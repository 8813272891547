<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-select size="small" v-if="isAdmin" @change="agentChange" v-model="query.agentId" filterable
					clearable placeholder="代理商" class="handle-select mr10" style="width: 260px">
					<el-option v-for="item in agents" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-select size="small" v-if="isSubAgent" v-model="query.subAgentId" @change="getfetchDefaultCompanies"
					filterable clearable placeholder="子代理" class="handle-select mr10" style="width: 260px;">
					<el-option v-for="item in subAgentList" :key="item.id" :label="item.name"
						:value="item.id"></el-option>
				</el-select>
				<el-select size="small" v-model="query.companyId" @change="companyChange" filterable clearable
					placeholder="企业" class="handle-select mr10" style="width: 260px">
					<el-option v-for="item in companys" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-select size="small" v-model="query.departmentId" filterable clearable placeholder="部门"
					class="handle-select mr10" style="width: 180px">
					<el-option v-for="item in departments" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-input size="small" v-model.trim="query.account" clearable placeholder="员工手机号"
					class="handle-input mr10" style="width: 180px"></el-input>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search"
					@click="handleSearch">搜索</el-button>
				<el-button size="small" type="primary" :class="[theme]" class="handle-del mr10 m_t_10"
					@click="batchEdit">批量设置有效期</el-button>

				<el-card v-if="isAgent" shadow="hover" :body-style="{ padding: '0px' }" style="margin-top: 20px;">
					<div class="grid-content grid-con-1">
						<i class="el-icon-s-cooperation grid-con-icon"></i>
						<div class="grid-cont-right">
							<div class="grid-num">{{ invalidateDate }}</div>
							<div>可分配时长（月）</div>
						</div>
					</div>
				</el-card>
			</div>

			<el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header"
				@selection-change="handleSelectionChange" :header-cell-style="{ 'text-align': 'center' }"
				:row-style="{ height: '20px' }" :cell-style="{ padding: '6px' }" :row-class-name="tableRowClassName">
				<el-table-column type="selection" width="55" align="center"></el-table-column>
				<el-table-column v-if="show" width="130" prop="id" label="id" align="center"></el-table-column>
				<el-table-column prop="account" width="120" label="账号" align="center"></el-table-column>
				<el-table-column prop="role" label="角色" align="center"></el-table-column>
				<el-table-column prop="name" label="姓名" align="center"></el-table-column>
				<el-table-column prop="companyName" width="320" label="公司" align="center"></el-table-column>
				<el-table-column prop="departmentName" label="部门" align="center"></el-table-column>
				<el-table-column prop="createDate" label="创建时间" align="center"></el-table-column>
				<el-table-column prop="invalidateDate" label="失效时间" align="center"></el-table-column>
				<el-table-column label="操作" width="240" align="center">
					<template #default="scope">
						<el-button size="small" type="text" icon="el-icon-edit"
							@click="handleEdit(scope.$index, scope.row.id)">编辑</el-button>
						<el-button size="small" v-if="isAdmin" type="text" icon="el-icon-edit"
							@click="riskEdit(scope.$index, scope.row.id)">设置验证次数</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination">
				<el-pagination background layout="total, sizes, prev, pager, next, jumper"
					:current-page="query.pageIndex" :page-sizes="[100, 200, 300, 400]" :page-size="query.pageSize"
					:total="pageTotal" @size-change="handleSizeChange"
					@current-change="handlePageChange"></el-pagination>
			</div>
		</div>

		<el-dialog title="角色配置" v-model="editVisible" width="500px" @close="closeDialog('form')"
			:close-on-click-modal="false">
			<el-form :model="form" :rules="rules" ref="form" label-width="100px">
				<el-form-item label="账号" prop="account"><el-input size="small" v-model="form.account" placeholder="手机号"
						disabled></el-input></el-form-item>
				<el-form-item label="姓名" prop="name"><el-input size="small" v-model="form.name"
						disabled></el-input></el-form-item>
				<el-form-item label="角色" prop="role">
					<el-radio-group v-model="form.role" @change="roleChange">
						<el-radio label="agent" v-if="agentDis">代理商</el-radio>
						<el-radio label="company" v-if="companyDis">企业管理员</el-radio>
						<el-radio label="normal">普通员工</el-radio>
					</el-radio-group>
				</el-form-item>

				<el-form-item label="代理商" prop="agentId" v-if="showAgent">
					<el-select size="small" v-model="form.agentId" filterable clearable placeholder="请选择代理商">
						<el-option v-for="item in agents" :key="item.value" :label="item.label"
							:value="item.value"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="有效期" prop="invalidateDateIncrease">
					<el-select size="small" v-model="form.invalidateDateIncrease" filterable clearable
						placeholder="请设置有效期">
						<el-option key="1" label="一个月" value="1"></el-option>
						<el-option key="2" label="两个月" value="2"></el-option>
						<el-option key="3" label="三个月" value="3"></el-option>
						<el-option key="6" label="六个月" value="6"></el-option>
						<el-option key="12" label="12个月" value="12"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item>
					<el-button size="small" style="margin-left: 55%;" @click="editVisible = false">取消</el-button>
					<el-button size="small" v-if="updateButton" type="primary" :class="[theme]"
						@click="updateUser('form')">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-dialog title="批量设置有效期" v-model="batchVisible" width="450px" @close="closeDialog('form')"
			:close-on-click-modal="false">
			<el-form :model="form" :rules="rules" ref="form" label-width="100px">
				<el-form-item label="可分配时长"><el-input size="small"
						v-model="form.invalidateDate"></el-input></el-form-item>
				<el-form-item label="有效期" prop="invalidateDateIncrease">
					<el-select size="small" v-model="form.invalidateDateIncrease" filterable clearable
						placeholder="请设置有效期" style="width: 100%;">
						<el-option key="1" label="一个月" value="1"></el-option>
						<el-option key="2" label="两个月" value="2"></el-option>
						<el-option key="3" label="三个月" value="3"></el-option>
						<el-option key="6" label="六个月" value="6"></el-option>
						<el-option key="12" label="12个月" value="12"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item>
					<el-button size="small" style="margin-left: 55%;" @click="batchVisible = false">取消</el-button>
					<el-button size="small" v-if="updateButton" type="primary" :class="[theme]"
						@click="batchUpdate()">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-dialog title="设置次数" v-model="riskVisible" width="450px" @close="closeDialog('form')"
			:close-on-click-modal="false">
			<el-form :model="risk" :rules="rules" ref="form" label-width="100px">
				<el-form-item label="添加次数"><el-input-number size="small" style="width: 200px" v-model="risk.num"
						:min="1" label="号码验证次数"></el-input-number></el-form-item>

				<el-form-item>
					<el-button size="small" style="margin-left: 55%;" @click="riskVisible = false">取消</el-button>
					<el-button size="small" type="primary" :class="[theme]" @click="riskCount()">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	import { fetchData, fetchUserById, setRoleAndValidDate, batchUpdate, riskCount } from '../api/userIndex';
	import { fetchDepartment } from '../api/index.js';
	import { fetchAgent, fetchInitAgent } from '../api/agentIndex.js';
	import { fetchCompanyByAgentId, fetchDefaultCompanies } from '../api/companyIndex';
	import { querySubAgent } from '../api/serialIndex.js';
	export default {
		name: 'role',
		data() {
			return {
				theme: localStorage.getItem('theme'),
				query: {
					companyId: '',
					departmentId: '',
					pageIndex: 1,
					pageSize: 100,
					subAgentId: ''
				},
				fileList: [],
				agents: [],
				companys: [],
				departments: [],
				tableData: [],
				multipleSelection: [],
				delList: [],
				editVisible: false,
				batchVisible: false,
				riskVisible: false,
				updateButton: false,
				showAgent: false,
				agentDis: false,
				isAdmin: false,
				isSubAgent: false,
				isAgent: false,
				companyDis: false,
				pageTotal: 0,
				form: {
					role: '',
					agentId: '',
					invalidateDate: 0,
					invalidateDateIncrease: 0
				},
				risk: {
					num: 1
				},
				rules: {
					checked: [{ required: true, message: '请选择角色', trigger: 'change' }]
				},
				idx: -1,
				id: -1,
				show: false,
				upload: false,
				loading: false,
				invalidateDate: 0,
				token: { Authorization: localStorage.getItem('token'), UserRole: localStorage.getItem('ms_role') }
			};
		},
		created() {
			const role = localStorage.getItem('ms_role');
			this.subAgent = localStorage.getItem('subAgent');
			if (role === 'admin') {
				this.isAdmin = true;
				this.agentDis = true;
				this.companyDis = true;
			}
			if (role === 'agent') {
				this.isAgent = true;
				this.companyDis = true;
				if (this.subAgent == 'true') {
					this.isSubAgent = true;
					this.getquerySubAgent();
				} else {
					this.isSubAgent = false;
				}
			}
			if (role === 'subAgent') {
				this.isAgent = true;
			}

			this.init();
		},
		methods: {
			getfetchDefaultCompanies() {
				this.query.companyId = '';
				let data = {
					currentUserId: localStorage.getItem('user'),
					subAgentId: this.query.subAgentId
				};
				fetchDefaultCompanies(data).then(res => {
					this.companys = res.data;
				});
			},
			getquerySubAgent() {
				let data = {
					agentId: this.query.agentId,
					userId: localStorage.getItem('user')
				};
				querySubAgent(data).then(res => {
					if (res.code == 200) {
						this.subAgentList = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			tableRowClassName({ rowIndex }) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},
			init() {
				let data1 = {
					currentUserId: localStorage.getItem('user')
				};
				fetchInitAgent(data1).then(res => {
					if (res.data) {
						this.invalidateDate = res.data.validCallTime;
					}
				});

				fetchAgent(localStorage.getItem('user')).then(res => {
					this.agents = res.data;
				});
				let data = {
					companyId: '',
					currentUserId: localStorage.getItem('user')
				};
				fetchDepartment(data).then(res => {
					this.departments = res.data;
				});
				if (this.subAgent == true) {
					this.isSubAgent = true;
					this.getquerySubAgent();
				}
				this.getfetchDefaultCompanies();
			},
			getData() {
				this.query.currentUserId = localStorage.getItem('user');
				fetchData(this.query).then(res => {
					if (res.code == 200) {
						this.tableData = res.data.list;
						this.pageTotal = res.data.total;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			agentChange(agentId) {
				this.query.agentId = agentId;
				this.query.companyId = '';
				this.query.departmentId = '';
				this.query.userId = '';
				this.form.agentId = agentId;
				this.form.companyId = '';
				this.form.departmentId = '';
				this.form.userId = '';
				if (agentId) {
					let data = {
						subAgent: JSON.parse(localStorage.getItem('subAgent').toLowerCase()),
						agentId: agentId
					};
					fetchCompanyByAgentId(data).then(res => {
						this.companys = res.data;
					});
				}
			},
			companyChange(companyId) {
				this.query.companyId = companyId;
				this.query.departmentId = '';
				this.query.userId = '';
				this.form.companyId = companyId;
				this.form.departmentId = '';
				this.form.userId = '';
				let data = {
					companyId: companyId,
					currentUserId: localStorage.getItem('user')
				};
				fetchDepartment(data).then(res => {
					this.departments = res.data;
					this.query.pageIndex = 1;
					this.getData();
				});
			},
			// 触发搜索按钮
			handleSearch() {
				this.query.pageIndex = 1;
				this.getData();
			},
			showForm() {
				this.updateButton = false;
			},
			updateUser(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						this.loading = true;
						this.form.currentUserId = localStorage.getItem('user');
						setRoleAndValidDate(this.form).then(res => {
							// this.$set(this.tableData, this.idx+1, this.form);
							if (res.code == 200) {
								this.$message.success(`修改第 ${this.idx + 1} 行成功`);
								this.getData();
							} else {
								this.$message.error(res.message);
							}
							this.editVisible = false;
							this.loading = false;
						});
					}
				});
			},
			riskCount() {
				riskCount(this.risk).then(res => {
					if (res.code == 200) {
						this.$message.success(`修改第 ${this.idx + 1} 行成功`);
					} else {
						this.$message.error(`修改失败`);
					}
					this.riskVisible = false;
					this.loading = false;
				});
			},
			batchUpdate() {
				if (this.form.invalidateDateIncrease === 0) {
					this.$message.error(`请选择有效期`);
				}
				let str = '';
				this.delList = this.delList.concat(this.multipleSelection);
				for (let i = 0; i < this.multipleSelection.length; i++) {
					str += this.multipleSelection[i].id + ',';
				}
				let param = {
					ids: str,
					invalidDate: this.form.invalidateDateIncrease,
					currentUserId: localStorage.getItem('user')
				};

				batchUpdate(param).then(res => {
					// this.$set(this.tableData, this.idx+1, this.form);
					if (res.code == 200) {
						this.$message.success(`批量设置有效期成功`);
						this.getData();
					} else {
						this.$message.error(res.message);
					}
					this.batchVisible = false;
					this.loading = false;
				});
			},
			// 多选操作
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			// 编辑操作
			handleEdit(index, id) {
				this.editVisible = true;
				this.idx = index;
				this.updateButton = true;
				// 获取详情
				fetchUserById(id).then(res => {
					this.form = res.data;
				});
			},
			riskEdit(index, id) {
				this.risk.userId = id;
				this.idx = index;
				this.riskVisible = true;
			},
			// 编辑操作
			batchEdit() {
				let str = '';
				this.delList = this.delList.concat(this.multipleSelection);
				for (let i = 0; i < this.multipleSelection.length; i++) {
					str += this.multipleSelection[i].id + ',';
				}
				if (str === '') {
					this.$message.error(`请选择员工`);
					return;
				}
				this.batchVisible = true;
				this.updateButton = true;
			},

			// 分页导航
			handlePageChange(val) {
				this.query.pageIndex = val;
				this.getData();
			},
			handleSizeChange(val) {
				this.query.pageSize = val;
				this.getData();
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			closeDialog(formName) {
				(this.form = {
					companyId: '',
					departmentId: '',
					account: '',
					password: '',
					name: ''
				}),
				this.resetForm(formName);
			},
			closeUpload() {
				this.$refs.upload.clearFiles();
			},
			roleChange(value) {
				if (value === 'agent') {
					this.showAgent = true;
				} else if (value === 'company') {
					this.showAgent = false;
				} else {
					this.showAgent = false;
				}
			}
		}
	};
</script>

<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}

	.down {
		border: 1px solid #dcdfe6;
		background: #4f7afd;
		color: #ffffff;
		transition: 0.1s;
		font-weight: 500;
		padding: 8px 16px;
		font-size: 12px;
		border-radius: 4px;
		margin-left: 10px;

		display: inline-block;
	}

	.handle-select {
		width: 120px;
	}

	.handle-input {
		width: 300px;
		display: inline-block;
	}

	.table {
		width: 100%;
		font-size: 12px;
	}

	.red {
		color: #ff0000;
	}

	.mr10 {
		margin-right: 10px;
	}

	.grid-con-icon {
		font-size: 50px;
		width: 100px;
		height: 100px;
		text-align: center;
		line-height: 100px;
		color: #fff;
	}

	.grid-cont-right {
		flex: 1;
		text-align: center;
		font-size: 20px;
		font-weight: bold;
		color: #999;
	}

	.grid-content {
		display: flex;
		align-items: center;
		height: 100px;
	}
</style>