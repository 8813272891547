<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-select size="small" v-if="isAdmin" @change="agentChange" v-model="query.agentId" filterable
					clearable placeholder="代理商" class="handle-select mr10" style="width: 260px">
					<el-option v-for="item in agents" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-select size="small" v-if="isSubAgent" v-model="query.subAgentId" @change="getfetchDefaultCompanies"
					filterable clearable placeholder="子代理" class="handle-select mr10" style="width: 260px;">
					<el-option v-for="item in subAgentList" :key="item.id" :label="item.name"
						:value="item.id"></el-option>
				</el-select>
				<el-select size="small" style="width: 260px" v-if="isAgent" v-model="query.companyId"
					@change="companyChange" filterable clearable placeholder="企业" class="handle-select mr10">
					<el-option v-for="item in companys" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-select size="small" v-model="query.departmentId" @change="departmentChange" filterable clearable
					placeholder="部门" class="handle-select mr10" style="width: 180px">
					<el-option v-for="item in departments" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-select size="small" v-model="query.userId" filterable clearable placeholder="员工"
					class="handle-select mr10" style="width: 180px">
					<el-option v-for="item in users" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-select size="small" v-model="query.type" filterable clearable placeholder="状态"
					class="handle-select mr10" style="width: 180px">
					<el-option v-for="item in typeList" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-date-picker size="small" type="date" placeholder="选择日期" v-model="query.beginDate"
					style="width: 200px;margin-right: 10px;" @change="getTime"></el-date-picker>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search" style="margin-top: 10px;"
					@click="handleSearch">搜索</el-button>
			</div>
			<el-table :data="tableData" border class="table" ref="multipleTable" v-loading="loading"
				:row-style="{ height: '20px' }" :header-cell-style="{ 'text-align': 'center' }"
				header-cell-class-name="table-header" row-key="aid" :row-class-name="tableRowClassName"
				:tree-props="{ children: 'children' }">
				<el-table-column prop="userPhone" label="账号" align="center"></el-table-column>
				<el-table-column prop="total" label="接通数量" align="center"></el-table-column>
				<el-table-column prop="notNull" label="录音正常数量" align="center"></el-table-column>
				<el-table-column label="合格率" align="center">
					<template #default="scope">
						<div v-if="scope.row.total > 0">
							<el-progress :percentage="(scope.row.ratio * 100).toFixed(2)"
								v-if="scope.row.ratio * 100 == 100" color="#67C23A"></el-progress>
							<el-progress :percentage="(scope.row.ratio * 100).toFixed(2)" v-else
								color="#F56C6C"></el-progress>
						</div>

						<!-- <span>{{ ((scope.row.notNull / scope.row.total) * 100).toFixed(2) }}%</span> -->
						<span v-else>暂无数据</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="190" align="center">
					<template #default="scope">
						<el-button size="small" type="text" style="color: #f56c6c;"
							@click="handleClose(scope.row)">停用</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination">
				<el-pagination background layout="total, sizes, prev, pager, next, jumper"
					:current-page="query.pageIndex" :page-sizes="[100, 300, 500, 1000]" :page-size="query.pageSize"
					:total="pageTotal" @size-change="handleSizeChange"
					@current-change="handlePageChange"></el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import { fetchDepartment, fetchUsersByDepartmentId, fetchDefaultUsers } from '../api/index.js';
	import { fetchCompanyByAgentId, fetchDefaultCompanies } from '../api/companyIndex';
	import { fetchAgent } from '../api/agentIndex';
	import { recordingRatio, download, recordingClose } from '../api/sumIndex';
	import { querySubAgent } from '../api/serialIndex.js';
	export default {
		name: 'recording',
		data() {
			return {
				theme: localStorage.getItem('theme'),
				query: {
					companyId: '',
					departmentId: '',
					userId: '',
					beginDate: '',
					pageIndex: 1,
					pageSize: 100,
					type: 0,
					subAgentId: ''
				},
				tableData: [],
				agents: [],
				companys: [],
				departments: [],
				users: [],
				isSubAgent: false,
				multipleSelection: [],
				typeList: [{
						value: 0,
						label: '全部'
					},
					{
						value: -1,
						label: '异常'
					}
				],
				isNotAdmin: true,
				isShow: false,
				isAgent: false,
				isAdmin: false,
				isCompany: false,
				pageTotal: 0,
				time: ''
			};
		},
		created() {
			let role = localStorage.getItem('ms_role');
			this.subAgent = localStorage.getItem('subAgent');
			this.getDate();
			if (role === 'admin') {
				this.isAdmin = true;
				this.isAgent = true;
				fetchAgent(localStorage.getItem('user')).then(res => {
					this.agents = res.data;
				});
			}

			if (role === 'agent') {
				this.isAgent = true;
				this.isNotAdmin = false;
				if (this.subAgent == 'true') {
					this.isSubAgent = true;
					this.getquerySubAgent();
				} else {
					this.isSubAgent = false;
				}
			}
			if (role === 'subAgent') {
				this.isAgent = true;
			}
			if (role === 'company') {
				this.isCompany = true;

				let data = {
					companyId: '',
					currentUserId: localStorage.getItem('user')
				};
				fetchDepartment(data).then(res => {
					this.departments = res.data;
				});
			}
			if (role === 'department') {
				let data = {
					companyId: '',
					currentUserId: localStorage.getItem('user')
				};
				fetchDepartment(data).then(res => {
					this.departments = res.data;
				});
			}
			this.init();
		},
		methods: {
			getfetchDefaultCompanies() {
				this.query.companyId = '';
				let data = {
					currentUserId: localStorage.getItem('user'),
					subAgentId: this.query.subAgentId
				};
				fetchDefaultCompanies(data).then(res => {
					this.companys = res.data;
				});
			},
			getquerySubAgent() {
				let data = {
					agentId: this.query.agentId,
					userId: localStorage.getItem('user')
				};
				querySubAgent(data).then(res => {
					if (res.code == 200) {
						this.subAgentList = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			getDate() {
				let now = new Date();
				let year = now.getFullYear(); //得到年份
				let month = now.getMonth(); //得到月份
				let date = now.getDate(); //得到日期
				let hour = ' 00:00:00'; //默认时分秒 如果传给后台的格式为年月日时分秒，就需要加这个，如若不需要，此行可忽略
				month = month + 1;
				month = month.toString().padStart(2, '0');
				date = date.toString().padStart(2, '0');
				this.query.beginDate = `${year}-${month}-${date}`; //
				this.time = `${year}-${month}-${date}`;
			},
			init() {
				fetchAgent(localStorage.getItem('user')).then(res => {
					this.agents = res.data;
				});
				if (this.subAgent == true) {
					this.isSubAgent = true;
					this.getquerySubAgent();
				}
				this.getfetchDefaultCompanies();
				let data = {
					companyId: '',
					currentUserId: localStorage.getItem('user')
				};
				fetchDepartment(data).then(res => {
					this.departments = res.data;
				});
				fetchDefaultUsers(localStorage.getItem('user')).then(res => {
					this.users = res.data;
				});
			},

			agentChange(agentId) {
				this.query.agentId = agentId;
				this.query.companyId = '';
				this.query.departmentId = '';
				this.query.userId = '';

				if (agentId) {
					let data = {
						subAgent: JSON.parse(localStorage.getItem('subAgent').toLowerCase()),
						agentId: agentId
					};
					fetchCompanyByAgentId(data).then(res => {
						this.companys = res.data;
					});
				}
			},
			companyChange(companyId) {
				this.query.companyId = companyId;
				this.query.departmentId = '';
				this.query.userId = '';
				let data = {
					companyId: companyId,
					currentUserId: localStorage.getItem('user')
				};
				fetchDepartment(data).then(res => {
					this.departments = res.data;
					this.query.pageIndex = 1;
					this.getData();
				});
			},
			departmentChange(departmentId) {
				let data = {
					departmentId: departmentId,
					currentUserId: localStorage.getItem('user')
				};
				fetchUsersByDepartmentId(data).then(res => {
					this.users = res.data;
				});
			},

			getTime() {
				if (this.query.beginDate) {
					this.time = this.toDateTimeStr(this.query.beginDate);
				} else {
					this.time = this.query.beginDate;
				}
			},
			getData() {
				this.loading = true;
				this.tableData = [];
				if (this.time === null) {
					this.$message.error('时间不能为空');
					return;
				}
				this.query.beginDate = this.time;
				recordingRatio(this.query).then(res => {
					this.loading = false;
					if (res.code == 200) {
						this.tableData = res.data.list;
						this.pageTotal = res.data.total;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			toDateTimeStr(date) {
				let y = date.getFullYear();
				let m = date.getMonth() + 1;
				let d = date.getDate();
				if (m < 10) {
					m = '0' + m;
				}
				if (d < 10) {
					d = '0' + d;
				}
				return y + '-' + m + '-' + d;
			},
			// 触发搜索按钮
			handleSearch() {
				this.query.pageIndex = 1;
				this.getData();
			},
			// 分页导航
			handlePageChange(val) {
				this.query.pageIndex = val;
				this.getData();
			},
			handleSizeChange(val) {
				this.query.pageSize = val;
				this.getData();
			},
			handleClose(row) {
				console.log(row, '三生三世');
				let data = {
					account: row.userPhone,
					userId: localStorage.getItem('user')
				};
				recordingClose(data).then(res => {
					if (res.code == 200) {
						this.$message.success(res.data);
						this.getData();
					} else {
						this.$message.error(res.message);
					}
				});
			},
			tableRowClassName({ row, rowIndex }) {
				if (row.status === false) {
					return 'warning-row';
				} else if (rowIndex === 3) {
					return 'success-row';
				}
				return '';
			}
		}
	};
</script>

<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}

	/* a:hover{background: #66b1ff} */
	.handle-select {
		width: 120px;
	}

	.table {
		width: 100%;
		font-size: 12px;
	}

	.red {
		color: #ff0000;
	}

	.mr10 {
		margin-right: 10px;
	}

	.handle-input {
		width: 200px;
		display: inline-block;
	}

	/deep/.el-table__body .warning-row {
		/* background-color: #baf89b !important; */
	}
</style>