<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-select size="small" v-model="query.agentId" filterable clearable placeholder="请选择代理"
					class="handle-select mr10" v-if="role == 'admin'">
					<el-option v-for="item in agents" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>

				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search" @click="handleSearch"
					v-if="role == 'admin'">搜索</el-button>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-plus"
					@click="handleAdd">添加</el-button>
			</div>

			<el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header"
				:header-cell-style="{ 'text-align': 'center' }" :row-class-name="tableRowClassName">
				<el-table-column prop="name" label="子代理姓名" align="center"></el-table-column>
				<el-table-column prop="phone" label="子代理账号" align="center"></el-table-column>
				<el-table-column label="操作" width="190" align="center">
					<template #default="scope">
						<el-button size="small" type="text" icon="el-icon-edit"
							@click="handleEdit(scope.row)">编辑</el-button>
						<el-button size="small" type="text" icon="el-icon-delete" style="color: #f56c6c;"
							@click="handleDelete(scope.$index, scope.row.id)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>

			<el-dialog :title="dialogTitle" v-model="addVisible" width="500px" @close="closeDialog('form')"
				:close-on-click-modal="false">
				<el-form :model="form" :rules="rules" ref="form" label-width="100px">
					<el-form-item label="代理" prop="name" v-if="role == 'admin'">
						<el-select size="small" v-model="query.agentId" filterable clearable placeholder="请选择代理"
							style="width: 100%;">
							<el-option v-for="item in agents" :key="item.value" :label="item.label"
								:value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="子代理姓名" prop="name"><el-input size="small"
							v-model="form.name"></el-input></el-form-item>
					<el-form-item label="子代理账号" prop="phone"><el-input size="small"
							v-model="form.phone"></el-input></el-form-item>
				</el-form>
				<span class="dialog-footer myRight">
					<el-button size="small" @click="closeDialog('form')">取消</el-button>
					<el-button size="small" v-if="addButton" type="primary" :class="[theme]"
						@click="addAgent('form')">确定</el-button>
					<el-button size="small" v-if="updateButton" type="primary" :class="[theme]"
						@click="updateAgent('form')">确定</el-button>
				</span>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import { querySubAgent, deleteSubAgent, addSubAgent, updateSubAgent } from '../api/serialIndex.js';
	import { fetchAgent } from '../api/agentIndex.js';

	export default {
		name: 'subagent',
		data() {
			return {
				theme: localStorage.getItem('theme'),
				query: {
					agentId: '',
					userId: localStorage.getItem('user')
				},
				tableData: [],
				agents: [],
				multipleSelection: [],
				delList: [],
				editVisible: false,
				addVisible: false,
				addButton: false,
				updateButton: false,
				selectForm: {
					region: 0
				},
				form: {
					name: '',
					phone: ''
				},
				dialogTitle: '',
				idx: -1,
				id: -1,
				loading: false,
				token: { Authorization: localStorage.getItem('token'), UserRole: localStorage.getItem('ms_role') },
				role: localStorage.getItem('ms_role')
			};
		},
		created() {
			this.init();
			if (this.role == 'agent') {
				this.getData();
			}
		},
		methods: {
			tableRowClassName({ rowIndex }) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},
			init() {
				fetchAgent(localStorage.getItem('user')).then(res => {
					this.agents = res.data;
				});
			},
			getData() {
				querySubAgent(this.query).then(res => {
					if (res.code == 200) {
						this.tableData = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			showForm() {
				this.addVisible = true;
				this.addButton = true;
				this.updateButton = false;
			},
			// 触发搜索按钮
			handleSearch() {
				if (this.role == 'admin') {
					if (this.query.agentId == '') {
						this.$message.error('请选择代理商');
					} else {
						this.getData();
					}
				} else {
					this.getData();
				}
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			// 编辑操作
			handleEdit(row) {
				this.addVisible = true;
				this.updateButton = true;
				this.dialogTitle = '编辑子代理';
				this.form = row;
			},
			handleAdd() {
				this.dialogTitle = '添加子代理';
				this.addButton = true;
				this.addVisible = true;
			},
			addAgent() {
				let data = {
					name: this.form.name,
					phone: this.form.phone,
					agentId: this.query.agentId,
					userId: localStorage.getItem('user')
				};
				addSubAgent(data).then(res => {
					if (res.code == 200) {
						this.addButton = false;
						this.addVisible = false;
						this.getData();
					} else {
						this.$message.error(res.message);
					}
				});
			},
			updateAgent() {
				let data = {
					name: this.form.name,
					phone: this.form.phone,
					id: this.form.id
				};
				updateSubAgent(data).then(res => {
					if (res.code == 200) {
						this.updateButton = false;
						this.addVisible = false;
						this.getData();
					} else {
						this.$message.error(res.message);
					}
				});
			},
			closeDialog() {
				this.addVisible = false;
				this.updateButton = false;
				this.addButton = false;
			},
			// 删除操作
			handleDelete(index, id) {
				// 二次确认删除
				this.$confirm('确定要删除吗？', '提示', {
						type: 'warning'
					})
					.then(() => {
						let data = {
							id: id
						};
						deleteSubAgent(data).then(res => {
							if (res.code == 200) {
								this.updateButton = false;
								this.addVisible = false;
								this.getData();
							} else {
								this.$message.error(res.message);
							}
						});
					})
					.catch(() => {});
			}
		}
	};
</script>

<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}

	/* a:hover{background: #66b1ff} */
	.handle-select {
		width: 240px;
	}

	.table {
		width: 100%;
		font-size: 12px;
	}

	.red {
		color: #ff0000;
	}

	.mr10 {
		margin-right: 10px;
	}

	.mr100 {
		position: absolute;
		right: 100px;
	}

	.myRight {
		position: absolute;
		right: 10px;
		bottom: 10px;
	}

	.handle-input {
		width: 200px;
		display: inline-block;
	}
</style>