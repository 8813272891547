<template>
	<div>
		<div class="container">
			<div class="handle-box">

				<el-input size="small" v-model="phone" clearable maxlength="12" placeholder="号码" style="width: 180px;"
					class="handle-input mr10"></el-input>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search"
					@click="getxsAxbPhoneInfo">搜索</el-button>
			</div>
			<div class="table-box" v-if="dxtDet">
				<div class="user-info-box">
					<div class="user-info-tit">账户信息</div>
				</div>
				<div class="user-info-box">
					<div class="info-box">
						<div class="info-name">号码：</div>
						<div class="info">{{ dxtDet.mobile }}</div>
					</div>
				</div>
				<div class="user-info-box">
					<div class="info-box">
						<div class="info-name">号码类型：</div>
						<div class="info">{{ dxtDet.type }}</div>
					</div>
				</div>
				<div class="user-info-box">
					<div class="info-box">
						<div class="info-name">状态：</div>
						<div class="info">{{ dxtDet.serverStatus }}</div>
					</div>
				</div>
				<!-- <div class="user-info-box">
					<div class="info-box">
						<div class="info-name">企业账户余额：</div>
						<div class="info" style="line-height: 34px;">{{ dxtDet.balance||0 }}元</div>
					</div>
				</div> -->
				<div class="user-info-box">
					<div class="info-box">
						<div class="info-name">有效期：</div>
						<div class="info">{{dxtDet.packageLimitTime}}</div>
					</div>
				</div>
				<div class="user-info-box">
					<div class="info-box">
						<div class="info-name">剩余时长：</div>
						<div class="info">{{ dxtDet.times||0}}分</div>
					</div>
				</div>
				<div class="user-info-box">
					<div class="info-box">
						<div class="info-name">总时长：</div>
						<div class="info">{{ dxtDet.totalTime||0}}分</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</template>
<script>
	import { xsAxbPhoneInfo } from '../api/index.js';
	export default {
		name: 'hySA',
		data() {
			return {
				theme: localStorage.getItem('theme'),
				phone: '',
				dxtDet: '',
				dosageVO: '',
			};
		},
		created() {
			if (this.phone) {
				this.getxsAxbPhoneInfo();
			}
		},
		methods: {
			getxsAxbPhoneInfo() {
				this.dxtDet = '';
				localStorage.setItem('userinfophone', this.phone);
				let data = {
					phone: this.phone
				};
				xsAxbPhoneInfo(data).then(res => {
					if (res.code == 200) {
						this.dxtDet = res.data.info;
					} else {
						this.$message.error(res.message);
					}
				});
			},
		}
	};
</script>

<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}

	.handle-select {
		width: 240px;
	}

	.mr10 {
		margin-right: 10px;
	}

	.user-info-tit {
		background: #fafafa;
		color: #000;
		font-weight: 600;
		text-align: center;
		height: 34px;
		line-height: 34px;
		border-right: 1px solid #ebeef5;
		border-bottom: 1px solid #ebeef5;
	}

	.info-box {
		display: flex;
		flex-direction: row;
		height: 34px;
		line-height: 34px;
		border-bottom: 1px solid #ebeef5;
		border-right: 1px solid #ebeef5;
	}

	.info-name {
		background: #fafafa;
		border-right: 1px solid #ebeef5;
		width: 300px;
		padding-left: 10px;
		font-weight: 600;
	}

	.user-info-box .info-box .info {
		padding-left: 10px;
		min-width: 200px;
		color: #226003;
	}

	.down {
		border: 1px solid #dcdfe6;
		background: #4f7afd;
		color: #ffffff;
		transition: 0.1s;
		font-weight: 500;
		padding: 6px 12px;
		font-size: 12px;
		border-radius: 4px;
		display: inline-block;
	}
</style>