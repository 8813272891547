<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-select size="small" v-if="isAdmin" @change="agentChange" v-model="query.agentId" filterable
					clearable placeholder="代理商" class="handle-select mr10" style="width: 260px;">
					<el-option v-for="item in agents" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-select size="small" v-model="query.companyId" @change="companyChange" filterable clearable
					placeholder="企业" class="handle-select mr10" style="width: 260px;">
					<el-option v-for="item in companys" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-select size="small" v-model="query.departmentId" filterable clearable placeholder="部门"
					class="handle-select mr10" style="width: 180px;">
					<el-option v-for="item in departments" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-input size="small" v-model.trim="query.phone" clearable placeholder="员工手机号"
					class="handle-input mr10" style="width: 180px;"></el-input>
				<el-date-picker v-model="timeArr" type="daterange" unlink-panels range-separator="至"
					start-placeholder="开始日期" end-placeholder="结束日期" size="small"></el-date-picker>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search" style="margin-left: 10px;"
					@click="handleSearch">搜索</el-button>
			</div>

			<el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header"
				:row-style="{ height: '20px' }" :cell-style="{ padding: '6px' }"
				:header-cell-style="{ 'text-align': 'center' }" @selection-change="handleSelectionChange"
				:row-class-name="tableRowClassName">
				<el-table-column v-if="show" prop="id" label="id" align="center"></el-table-column>
				<el-table-column prop="callTime" label="拨打时间" align="center"></el-table-column>
				<el-table-column prop="name" label="姓名" align="center"></el-table-column>
				<el-table-column prop="callPhone" label="主叫号码" align="center"></el-table-column>
				<el-table-column prop="company" width="350" label="公司" align="center"></el-table-column>
				<el-table-column prop="department" label="部门" align="center"></el-table-column>
				<el-table-column prop="calledPhone" label="被叫号码" align="center"></el-table-column>

				<!--                <el-table-column label="操作" width="190" align="center">-->
				<!--                    <template #default="scope">-->
				<!--                        <el-button size="small"-->
				<!--                            type="text"-->
				<!--                            icon="el-icon-remove"-->
				<!--                            @click="handleEdit(scope.$index, scope.row)"-->
				<!--                        >停机</el-button>-->
				<!--                    </template>-->
				<!--                </el-table-column>-->
			</el-table>
			<div class="pagination">
				<el-pagination background layout="total, sizes, prev, pager, next, jumper"
					:current-page="query.pageIndex" :page-sizes="[100, 200, 300, 400]" :page-size="query.pageSize"
					:total="pageTotal" @size-change="handleSizeChange"
					@current-change="handlePageChange"></el-pagination>
			</div>
		</div>

		<el-dialog title="号码停机" v-model="editVisible" width="500px" @close="closeDialog('form')">
			<el-form :model="form" :rules="rules" ref="form" label-width="100px">
				<el-form-item label="停机原因"><el-input size="small" type="textarea"
						v-model="form.remark"></el-input></el-form-item>

				<el-form-item>
					<el-button size="small" @click="editVisible = false">取消</el-button>
					<el-button size="small" type="primary" :class="[theme]" @click="stop()">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	import { batchUpdate } from '../api/userIndex';
	import { fetchDepartment } from '../api/index.js';
	import { stop, getInvalidData } from '../api/httpOperate.js';
	import { fetchAgent } from '../api/agentIndex.js';
	import { fetchCompanyByAgentId, fetchDefaultCompanies } from '../api/companyIndex';
	export default {
		name: 'stopPhone',
		data() {
			return {
				theme: localStorage.getItem('theme'),
				query: {
					companyId: '',
					departmentId: '',
					pageIndex: 1,
					pageSize: 30
				},
				fileList: [],
				agents: [],
				companys: [],
				departments: [],
				tableData: [],
				multipleSelection: [],
				delList: [],
				editVisible: false,
				batchVisible: false,
				updateButton: false,
				showAgent: false,
				agentDis: false,
				isAdmin: false,
				companyDis: false,
				pageTotal: 0,
				form: {
					remark: '',
					phoneNumber: ''
				},
				idx: -1,
				id: -1,
				show: false,
				upload: false,
				loading: false,
				timeArr: [],
				token: { Authorization: localStorage.getItem('token'), UserRole: localStorage.getItem('ms_role') }
			};
		},
		created() {
			const role = localStorage.getItem('ms_role');
			if (role === 'admin') {
				this.isAdmin = true;
				this.agentDis = true;
				this.companyDis = true;
			}
			if (role === 'agent') {
				this.companyDis = true;
			}
			this.getTime();
			this.init();
			// this.getData();
		},
		methods: {
			tableRowClassName({ rowIndex }) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},
			init() {
				fetchAgent(localStorage.getItem('user')).then(res => {
					this.agents = res.data;
				});
				let data2 = {
					currentUserId: localStorage.getItem('user')
				};
				fetchDefaultCompanies(data2).then(res => {
					this.companys = res.data;
				});
				let data = {
					companyId: '',
					currentUserId: localStorage.getItem('user')
				};
				fetchDepartment(data).then(res => {
					this.departments = res.data;
				});
				fetchAgent(localStorage.getItem('user')).then(res => {
					this.agents = res.data;
				});

				// this.getData()
			},
			getTime() {
				let startTime = new Date(new Date());
				let endTime = new Date();
				this.timeArr.push(startTime);
				this.timeArr.push(endTime);
			},
			getData() {
				// 日期

				if (this.timeArr === null) {
					this.$message.error('时间不能为空');
					return;
				}
				let beginDate = this.toDateTimeStr(this.timeArr[0]);
				let endDate = this.toDateTimeStr(this.timeArr[1]);
				console.log(this.timeArr, 'sdkdjkdj');

				this.query.beginDate = beginDate;
				this.query.endDate = endDate;
				getInvalidData(this.query).then(res => {
					if (res.code == 200) {
						// if (res.data.list.length === 0) {
						// 	this.$message.error('数据为空');
						// }
						this.tableData = res.data.list;
						this.pageTotal = res.data.total;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			toDateTimeStr(date) {
				let y = date.getFullYear();
				let m = date.getMonth() + 1;
				let d = date.getDate();
				if (m < 10) {
					m = '0' + m;
				}
				if (d < 10) {
					d = '0' + d;
				}

				return y + '-' + m + '-' + d;
			},

			agentChange(agentId) {
				this.query.agentId = agentId;
				this.query.companyId = '';
				this.query.departmentId = '';
				this.query.userId = '';
				this.form.agentId = agentId;
				this.form.companyId = '';
				this.form.departmentId = '';
				this.form.userId = '';
				if (agentId) {
					let data = {
						subAgent: JSON.parse(localStorage.getItem('subAgent').toLowerCase()),
						agentId: agentId
					};
					fetchCompanyByAgentId(data).then(res => {
						this.companys = res.data;
					});
				}
			},
			companyChange(companyId) {
				this.query.companyId = companyId;
				this.query.departmentId = '';
				this.query.userId = '';
				this.form.companyId = companyId;
				this.form.departmentId = '';
				this.form.userId = '';
				let data = {
					companyId: companyId,
					currentUserId: localStorage.getItem('user')
				};
				fetchDepartment(data).then(res => {
					this.departments = res.data;
					this.query.pageIndex = 1;
					this.getData();
				});
			},
			// 触发搜索按钮
			handleSearch() {
				this.query.pageIndex = 1;
				this.getData();
			},
			showForm() {
				this.updateButton = false;
			},
			stop() {
				if (this.form.remark === '') {
					this.$message.error(`请输入停机原因`);
					return;
				}
				stop(this.form).then(res => {
					// this.$set(this.tableData, this.idx+1, this.form);
					if (res.code == 200) {
						this.$message.success(`修改第 ${this.idx + 1} 行成功`);
					} else {
						this.$message.error(res.message);
					}
					this.editVisible = false;
					this.loading = false;
				});
			},
			batchUpdate() {
				if (this.form.invalidateDateIncrease === 0) {
					this.$message.error(`请选择有效期`);
				}
				let str = '';
				this.delList = this.delList.concat(this.multipleSelection);
				for (let i = 0; i < this.multipleSelection.length; i++) {
					str += this.multipleSelection[i].id + ',';
				}
				let param = {
					ids: str,
					invalidDate: this.form.invalidateDateIncrease,
					currentUserId: localStorage.getItem('user')
				};

				batchUpdate(param).then(res => {
					// this.$set(this.tableData, this.idx+1, this.form);
					if (res.code == 200) {
						this.$message.success(`批量设置有效期成功`);
					} else {
						this.$message.error(res.message);
					}
					this.batchVisible = false;
					this.loading = false;
				});
			},
			// 多选操作
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			// 编辑操作
			handleEdit(index, row) {
				this.editVisible = true;
				this.idx = index;
				this.updateButton = true;
				// 获取详情
				this.form.phoneNumber = row.account;
			},

			// 分页导航
			handlePageChange(val) {
				this.query.pageIndex = val;
				this.getData();
			},
			handleSizeChange(val) {
				this.query.pageSize = val;
				this.getData();
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			closeDialog(formName) {
				(this.form = {
					companyId: '',
					departmentId: '',
					account: '',
					password: '',
					name: ''
				}),
				this.resetForm(formName);
			},
			closeUpload() {
				this.$refs.upload.clearFiles();
			},
			roleChange(value) {
				if (value === 'agent') {
					this.showAgent = true;
				} else if (value === 'company') {
					this.showAgent = false;
				} else {
					this.showAgent = false;
				}
			}
		}
	};
</script>

<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}

	.down {
		border: 1px solid #dcdfe6;
		background: #4f7afd;
		color: #ffffff;
		transition: 0.1s;
		font-weight: 500;
		padding: 8px 16px;
		font-size: 12px;
		border-radius: 4px;
		margin-left: 10px;
	}

	.handle-select {
		width: 120px;
	}

	.handle-input {
		width: 300px;
		display: inline-block;
	}

	.table {
		width: 100%;
		font-size: 12px;
	}

	.red {
		color: #ff0000;
	}

	.mr10 {
		margin-right: 10px;
	}

	.table-td-thumb {
		display: block;
		margin: auto;
		width: 40px;
		height: 40px;
	}
</style>