<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-input size="small" v-model.trim="query.phoneNumber" clearable placeholder="被操作号码"
					class="handle-input mr10" style="width: 180px"></el-input>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search"
					@click="handleSearch">搜索</el-button>
			</div>

			<el-table :data="tableData" border class="table el-table" ref="multipleTable"
				header-cell-class-name="table-header" :header-cell-style="{ 'text-align': 'center' }"
				@selection-change="handleSelectionChange" :row-style="{ height: '20px' }"
				:cell-style="{ padding: '6px' }" :row-class-name="tableRowClassName">
				<el-table-column prop="userPhone" width="200" label="操作者" align="center"></el-table-column>
				<el-table-column prop="phoneNumber" width="200" label="被操作号码" align="center"></el-table-column>
				<el-table-column prop="operate" width="200" label="操作名称" align="center"></el-table-column>
				<el-table-column prop="createdAt" width="200" label="操作时间" align="center"></el-table-column>
				<el-table-column prop="content" label="内容" align="center" show-overflow-tooltip></el-table-column>
			</el-table>
			<div class="pagination">
				<el-pagination background layout="total, sizes, prev, pager, next, jumper"
					:current-page="query.pageIndex" :page-sizes="[100, 200, 300, 400]" :page-size="query.pageSize"
					:total="pageTotal" @size-change="handleSizeChange"
					@current-change="handlePageChange"></el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import { getOperationRecord } from '../api/index.js';
	export default {
		name: 'operationLog',
		data() {
			return {
				theme: localStorage.getItem('theme'),
				query: {
					phoneNumber: '',
					pageIndex: 1,
					pageSize: 100
				},
				tableData: [],
				pageTotal: 0,
				loading: false
			};
		},

		created() {
			const role = localStorage.getItem('ms_role');
			if (role === 'admin') {
				this.isAdmin = true;
				this.agentDis = true;
				this.companyDis = true;
			}
			if (role === 'agent') {
				this.isAgent = true;
				this.companyDis = true;
			}

			this.getData();
		},
		methods: {
			tableRowClassName({ rowIndex }) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},
			getData() {
				let data = {
					phoneNumber: this.query.phoneNumber,
					pageIndex: this.query.pageIndex,
					pageSize: this.query.pageSize,
					userId: localStorage.getItem('user')
				};
				getOperationRecord(data).then(res => {
					this.tableData = res.data.list;
					// res.data.list.forEach(i => {
					// 	let str=i.content.replace(/\n/g," \n ")
					// 	console.log(str,'dddd')
					// 	this.tableData.push(i);
					// });
					this.pageTotal = res.data.total;
				});
			},

			// 触发搜索按钮
			handleSearch() {
				this.query.pageIndex = 1;
				this.tableData = [];
				this.getData();
			},

			// 分页导航
			handlePageChange(val) {
				this.query.pageIndex = val;
				this.tableData = [];
				this.getData();
			},
			handleSizeChange(val) {
				this.query.pageSize = val;
				this.tableData = [];
				this.getData();
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			closeDialog(formName) {
				(this.form = {
					companyId: '',
					departmentId: '',
					account: '',
					password: '',
					name: ''
				}),
				this.resetForm(formName);
			},
			closeUpload() {
				this.$refs.upload.clearFiles();
			}
		}
	};
</script>

<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}

	.down {
		border: 1px solid #dcdfe6;
		background: #4f7afd;
		color: #ffffff;
		transition: 0.1s;
		font-weight: 500;
		padding: 8px 16px;
		font-size: 12px;
		border-radius: 4px;
		margin-left: 10px;

		display: inline-block;
	}

	.handle-select {
		width: 120px;
	}

	.handle-input {
		width: 300px;
		display: inline-block;
	}

	.table {
		width: 100%;
		font-size: 12px;
	}

	.red {
		color: #f56c6c;
	}

	.mr10 {
		margin-right: 10px;
	}

	.grid-con-icon {
		font-size: 50px;
		width: 100px;
		height: 100px;
		text-align: center;
		line-height: 100px;
		color: #fff;
	}

	.grid-cont-right {
		flex: 1;
		text-align: center;
		font-size: 20px;
		font-weight: bold;
		color: #999;
	}

	.grid-content {
		display: flex;
		align-items: center;
		height: 100px;
	}
</style>
<style scoped>
	.el-table .cell {
		white-space: pre-line;
	}
</style>