import request from '../utils/request';
import qs from "qs";

export const list = param => {
    return request.post('/api/department/list', qs.stringify(param))
};

export const addDepartment = param => {
    return request.post('/api/department/add', qs.stringify(param))
};

export const updateDepartment = param => {
    return request.post('/api/department/update', qs.stringify(param))
};

export const delDepartment= id => {
    return request.get('/api/department/delete?id=' + id)
};

export const fetchDepartmentById = departmentId => {
    return request.get('/api/department/fetchDepartmentById?departmentId=' + departmentId)
};

