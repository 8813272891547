<template>
	<div>
		<div class="container">
			<el-tabs v-model="activeName" @tab-click="handleClick">
				<el-tab-pane label="单个充值" name="single">
					<div class="handle-box">
						<el-select size="small" @change="agentChange" v-model="singlequery.agentId" filterable clearable
							placeholder="请选择代理商" class="handle-select mr10" style="width: 200px;">
							<el-option v-for="item in agents" :key="item.value" :label="item.label"
								:value="item.value"></el-option>
						</el-select>
						<el-select size="small" style="width: 200px" v-model="singlequery.companyId"
							@change="companyChange" filterable clearable placeholder="企业" class="handle-select mr10">
							<el-option v-for="item in companys" :key="item.value" :label="item.label"
								:value="item.value"></el-option>
						</el-select>
						<el-input size="small" v-model="singlequery.phone" clearable maxlength="12" placeholder="号码"
							style="width: 180px;" class="handle-input mr10"></el-input>
						<el-input size="small" v-model="singlequery.uid" clearable placeholder="流水号"
							style="width: 350px;" class="handle-input mr10"></el-input>
						<el-select size="small" style="width: 100px" v-model="singlequery.status" filterable clearable
							placeholder="状态" class="handle-select mr10">
							<el-option v-for="item in statusList" :key="item.value" :label="item.label"
								:value="item.value"></el-option>
						</el-select>
						<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search"
							@click="topUpRecord" class="m_t_10">搜索</el-button>
						<el-button size="small" type="primary" :class="[theme]" @click="getAdd"
							class="m_t_10">充值</el-button>
						<el-button size="small" type="primary" :class="[theme]" @click="getAPPAdd"
							class="m_t_10">APP充值</el-button>
					</div>
					<el-table :data="singletableData" border class="table" ref="multipleTable"
						header-cell-class-name="table-header" :row-style="{ height: '20px' }"
						:cell-style="{ padding: '6px' }" :header-cell-style="{ 'text-align': 'center' }"
						:row-class-name="tableRowClassName" @selection-change="handleSelectionChange">
						<el-table-column prop="agentName" label="代理商" align="center" width="300"></el-table-column>
						<el-table-column prop="companyName" label="企业" align="center"></el-table-column>
						<el-table-column prop="phone" label="用户名" align="center" width="130"></el-table-column>
						<el-table-column prop="amount" label="金额(元)" align="center" width="90"></el-table-column>
						<el-table-column prop="status" label="状态" align="center" width="90">
							<template #default="scope">
								<el-tooltip class="item" effect="dark" :content="scope.row.errMsg" placement="top"
									v-if="scope.row.status == '失败'">
									<span style="color: #f56c6c;">{{ scope.row.status }}</span>
								</el-tooltip>
								<span v-else>{{ scope.row.status }}</span>
							</template>
						</el-table-column>
						<el-table-column prop="createdAt" label="时间" width="180" align="center"></el-table-column>
						<el-table-column prop="userPhone" label="操作者" align="center" width="130"></el-table-column>
						<el-table-column label="流水号" width="400" align="center">
							<template #default="scope">
								<span style="cursor: pointer;">
									<i @click="gettopUpRecordDownload(scope.row.uid)" class="el-icon-download"
										:class="[theme3]" style="margin-right:10px;font-weight: 600;"></i>
									<span @click="copyUrl(scope.row.uid)">{{ scope.row.uid }}</span>
									<span @click="getReturnPremium(scope.row)"
										style="margin-left: 10px;color: red;">返销</span>
								</span>
							</template>
						</el-table-column>
					</el-table>
					<div class="pagination">
						<el-pagination background layout="total, sizes, prev, pager, next, jumper"
							:current-page="singlequery.pageIndex" :page-sizes="[10, 30, 50, 100, 300]"
							:page-size="singlequery.pageSize" :total="singletotal" @size-change="singlehandleSizeChange"
							@current-change="singlehandlePageChange"></el-pagination>
					</div>
				</el-tab-pane>
				<el-tab-pane label="批量充值" name="batch">
					<div class="handle-box">
						<el-button size="small" type="primary" :class="[theme]" @click="getaddVisible">批量充值</el-button>
						<a :href="`${path}batchRecharge.xlsx`" class="down" :class="[theme]" style="margin:10px 10px;"
							download="批量充值模板.xlsx">
							<i class="el-icon-download"></i>
							下载模板
						</a>
						<el-button size="small" type="primary" :class="[theme]"
							@click="batchTopUpRecords">刷新</el-button>
					</div>
					<el-table :data="batchtableData" border class="table" ref="multipleTable"
						header-cell-class-name="table-header" :row-style="{ height: '20px' }"
						:cell-style="{ padding: '6px' }" :header-cell-style="{ 'text-align': 'center' }"
						:row-class-name="tableRowClassName" @selection-change="handleSelectionChange">
						<el-table-column prop="uid" label="处理批次" align="center" width="350"></el-table-column>
						<el-table-column prop="count" label="总数量" align="center"></el-table-column>
						<el-table-column prop="success" label="成功" align="center"></el-table-column>
						<el-table-column prop="totalSuccess" label="成功金额(元)" align="center"></el-table-column>
						<el-table-column prop="err" label="失败" align="center">
							<template #default="scope">
								<span style="color: #f56c6c;">{{ scope.row.err }}</span>
							</template>
						</el-table-column>
						<el-table-column prop="totalErr" label="失败金额(元)" align="center">
							<template #default="scope">
								<span style="color: #f56c6c;">{{ scope.row.totalErr }}</span>
							</template>
						</el-table-column>
						<el-table-column prop="time" label="时间" align="center" width="180"></el-table-column>
						<el-table-column prop="userPhone" label="操作者" align="center"></el-table-column>
						<el-table-column label="操作" width="240" align="center">
							<template #default="scope">
								<a :href="scope.row.url" class="down" :class="[theme]" download="迪迦批量充值数据.text">
									<i class="el-icon-download"></i>
									下载
								</a>
							</template>
						</el-table-column>
					</el-table>
					<div class="pagination">
						<el-pagination background layout="total, sizes, prev, pager, next, jumper"
							:current-page="batchquery.pageIndex" :page-sizes="[10, 30, 50, 100, 300]"
							:page-size="batchquery.pageSize" :total="batchpageTotal"
							@size-change="batchhandleSizeChange"
							@current-change="batchhandlePageChange"></el-pagination>
					</div>
				</el-tab-pane>
				<el-tab-pane label="资金池" name="capitalpool">
					<el-row :gutter="20">
						<el-col :span="20">
							<el-row :gutter="20" class="mgb20">
								<el-col :span="8">
									<el-card shadow="hover" :body-style="{ padding: '0px' }">
										<div class="grid-content grid-con-2">
											<i class="el-icon-receiving grid-con-icon"></i>
											<div class="grid-cont-right">
												<div class="grid-num" :class="theme3">{{ capitaltotal || 0 }}</div>
												<div>剩余量</div>
											</div>
										</div>
									</el-card>
								</el-col>
							</el-row>
						</el-col>
						<el-col :span="4"><el-button size="small" type="primary" :class="[theme]"
								@click="addCapital">添加金额</el-button></el-col>
					</el-row>
					<div class="container">
						<el-table v-loading="loading" :data="capitaltableData" border class="table m_t_20"
							header-cell-class-name="table-header" :row-style="{ height: '20px' }"
							:header-cell-style="{ 'text-align': 'center' }" :cell-style="{ padding: '6px' }"
							:row-class-name="tableRowClassName">
							<el-table-column prop="label" label="数量" align="center">
								<template #default="scope">
									<span>{{ scope.row.label }}元</span>
								</template>
							</el-table-column>
							<el-table-column prop="value" label="时间" align="center"></el-table-column>
						</el-table>
					</div>

					<el-dialog title="添加金额" v-model="addCapitalNum" width="450px" @close="closeCapitalNum"
						:close-on-click-modal="false">
						<el-form :model="capitalForm" ref="form" label-width="100px">
							<el-form-item label="添加金额">
								<el-input size="small" style="width: 250px;" placeholder="请输入添加金额"
									v-model="capitalForm.amount"></el-input>
								<span class="m_l_10">元</span>
							</el-form-item>
							<div style="display: flex;flex-direction: row;justify-content: end;">
								<el-button size="small" class="btn" @click="closeCapitalNum">取消</el-button>
								<el-button size="small" class="btn" :class="[theme]" style="border: none;"
									type="success" @click="getcapitalPoolAdd">确定</el-button>
							</div>
						</el-form>
					</el-dialog>
				</el-tab-pane>
			</el-tabs>
			<el-dialog title="批量充值" v-model="addVisible" width="520px" @close="closeDialog">
				<el-form label-width="120px" :model="addForm" ref="editForm">
					<el-form-item label="上传文件" prop="">
						<el-upload ref="upload" action="/api/dxt/batchTopUp" :headers="token" :limit="1"
							:on-success="uploadSuccess" :auto-upload="false" :data="uploadData">
							<el-button size="small" type="primary" :class="[theme]"
								style="margin-top: 50px;">选择文件</el-button>
							<div class="el-upload__tip">只能导入xlsx文件</div>
						</el-upload>
					</el-form-item>
					<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
						<el-button size="small" @click="closeDialog">取消</el-button>
						<el-button size="small" type="primary" :class="[theme]" v-else
							@click="submitUpload">确定</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>
			<el-dialog title="充值" v-model="singleVisible" width="450px" @close="closesingleDialog">
				<el-form label-width="120px" :model="singleForm" ref="editForm">
					<el-form-item label="手机号"><el-input size="small" style="width: 250px;" placeholder="请输入手机号"
							v-model="singleForm.phone"></el-input></el-form-item>
					<el-form-item label="添加金额">
						<el-input v-model="singleForm.amount" size="small" style="width: 250px;" placeholder="请输入金额"
							οninput="value=value.replace(/[^0-9]/g,'')" />
					</el-form-item>
					<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
						<el-button size="small" @click="closesingleDialog">取消</el-button>
						<el-button size="small" type="primary" :class="[theme]" v-else @click="AddtopUp">确定</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>
			<el-dialog title="APP充值" v-model="APPVisible" width="450px" @close="closeAPPDialog">
				<el-form label-width="120px" :model="APPForm" ref="editForm">
					<el-form-item label="APP充值:" prop="bon">
						<el-radio-group v-model="APPForm.bon">
							<el-radio :label="true">开启</el-radio>
							<el-radio :label="false">关闭</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
						<el-button size="small" @click="closeAPPDialog">取消</el-button>
						<el-button size="small" type="primary" :class="[theme]" v-else
							@click="getstopDxtApp">确定</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import { batchQueryRecord, fundPoolAdd, fundPool, topUpRecord, batchTopUpRecord, topUpRecordDownload, AddtopUp,
		stopDxtApp, dxtEnabled, ReturnPremium } from '../api/dijia.js';
	import { fetchCompanyByAgentId, fetchDefaultCompanies } from '../api/companyIndex';
	import { fetchAgent } from '../api/agentIndex';
	export default {
		name: 'djRecharge',
		data() {
			return {
				theme: localStorage.getItem('theme'),
				theme3: localStorage.getItem('theme3'),
				agents: [],
				companys: [],
				singlequery: {
					pageIndex: 1,
					pageSize: 10,
					userId: localStorage.getItem('user'),
					uid: '',
					status: '',
					agentId: '',
					companyId: ''
				},
				singletableData: [],
				singletotal: '',
				statusList: [{ value: 0, label: '全部' }, { value: 1, label: '成功' }, { value: -1, label: '失败' }],
				singleVisible: false,
				singleForm: {
					userId: localStorage.getItem('user'),
					phone: '',
					amount: ''
				},
				batchquery: {
					pageIndex: 1,
					pageSize: 10
				},
				batchtableData: [],
				batchpageTotal: 0,
				dxtDet: '',
				activeName: 'single',
				uploadData: {
					userId: localStorage.getItem('user')
				},
				addVisible: false,
				tableData: [],
				pageTotal: 0,
				token: { Authorization: localStorage.getItem('token'), UserRole: localStorage.getItem('ms_role') },
				path: process.env.BASE_URL,
				query: {
					pageIndex: 1,
					pageSize: 10,
					userId: localStorage.getItem('user')
				},
				addCapitalNum: false,
				capitalForm: {
					amount: ''
				},
				capitaltableData: [],
				capitaltotal: '',
				APPVisible: false,
				APPForm: {
					bon: false
				}
			};
		},

		created() {
			this.init();
			this.topUpRecord();
		},

		methods: {
			tableRowClassName({ rowIndex }) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},
			handleClick(tab, event) {
				this.activeName = tab.props.name;
				if (tab.props.name == 'single') {
					this.topUpRecord();
				} else if (tab.props.name == 'batch') {
					this.batchTopUpRecords();
				} else if (tab.props.name == 'capitalpool') {
					this.getfundPool();
				}
			},
			topUpRecord() {
				topUpRecord(this.singlequery).then(res => {
					if (res.code == 200) {
						this.singletableData = res.data.list;
						this.singletotal = res.data.total;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			init() {
				fetchAgent(localStorage.getItem('user')).then(res => {
					this.agents = res.data;
				});
				let data1 = {
					currentUserId: localStorage.getItem('user')
				};
				fetchDefaultCompanies(data1).then(res => {
					this.companys = res.data;
				});
			},
			agentChange(agentId) {
				this.singlequery.agentId = agentId;
				this.singlequery.companyId = '';
				if (agentId) {
					let data = {
						subAgent: JSON.parse(localStorage.getItem('subAgent').toLowerCase()),
						agentId: agentId
					};
					fetchCompanyByAgentId(data).then(res => {
						this.companys = res.data;
					});
				}
			},

			copyUrl(item) {
				let cInput = document.createElement('input');
				cInput.value = item;
				document.body.appendChild(cInput);
				cInput.select();
				document.execCommand('copy');
				this.$message({
					type: 'succes',
					message: '复制成功'
				});
				document.body.removeChild(cInput);
			},

			batchTopUpRecords() {
				batchTopUpRecord(this.batchquery).then(res => {
					if (res.code == 200) {
						this.batchtableData = res.data.list;
						this.batchpageTotal = res.data.total;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			submitUpload() {
				if (this.$refs.upload.uploadFiles[0]) {
					this.loading = true;
					this.$refs.upload.submit();
				} else {
					this.$message.error('请选择上传文件');
				}
			},
			getReturnPremium(row) {
				this.$confirm('确定要返销该批次数据吗？', '提示', {
						type: 'warning'
					})
					.then(() => {
						let data = {
							userId: localStorage.getItem('user'),
							uid: row.uid,
							phone: row.phone
						};
						ReturnPremium(data).then(res => {
							if (res.code == 200) {
								this.topUpRecord();
							} else {
								this.topUpRecord();
								this.$message.error(res.message);
							}
						});
					})
					.catch(() => {});
			},
			gettopUpRecordDownload(uid) {
				this.$confirm('确定要下载该批次数据吗？', '提示', {
						type: 'warning'
					})
					.then(() => {
						let data = {
							uid: uid
						};
						topUpRecordDownload(data).then(res => {
							let link = document.createElement('a');
							link.style.display = 'none';
							link.href = res.data;
							document.body.appendChild(link);
							link.click();
						});
					})
					.catch(() => {});
			},
			//导入上传
			uploadSuccess(success) {
				setTimeout(() => {
					this.$refs.upload.clearFiles();
					this.addVisible = false;
				}, 2000);
				if (success.code == 200) {
					this.$message.success('上传成功');
					this.batchTopUpRecords();
				} else {
					// this.open(success.message);
					this.$message.error(success.message);
				}
			},
			getaddVisible() {
				this.addVisible = true;
			},
			closeDialog() {
				this.addVisible = false;
			},
			getAdd() {
				this.singleVisible = true;
			},

			closesingleDialog() {
				this.singleForm.amount = '';
				this.singleForm.phone = '';
				this.singleVisible = false;
			},
			AddtopUp() {
				let data = {
					phone: this.singleForm.phone,
					amount: this.singleForm.amount,
					userId: localStorage.getItem('user')
				};
				AddtopUp(data).then(res => {
					if (res.code == 200) {
						this.$message.success(res.data);
						setTimeout(() => {
							this.singleVisible = false;
							this.topUpRecord();
						}, 1000);
					} else {
						this.$message.error(res.message);
					}
				});
			},
			getAPPAdd() {
				this.APPVisible = true;
				this.getdxtEnabled();
			},
			closeAPPDialog() {
				this.APPVisible = false;
			},

			getstopDxtApp() {
				let data = {
					bon: this.APPForm.bon,
					userId: localStorage.getItem('user')
				};
				stopDxtApp(data).then(res => {
					if (res.code == 200) {
						this.$message.success(res.data);
						this.APPVisible = false;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			getdxtEnabled() {
				dxtEnabled().then(res => {
					if (res.code == 200) {
						this.APPForm.bon = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			singlehandlePageChange(val) {
				this.singlequery.pageIndex = val;
				this.topUpRecord();
			},
			singlehandleSizeChange(val) {
				this.singlequery.pageSize = val;
				this.topUpRecord();
			},
			batchhandlePageChange(val) {
				this.batchquery.pageIndex = val;
				this.batchTopUpRecords();
			},
			batchhandleSizeChange(val) {
				this.batchquery.pageSize = val;
				this.batchTopUpRecords();
			},
			addCapital() {
				this.addCapitalNum = true;
			},
			closeCapitalNum() {
				this.addCapitalNum = false;
				this.capitalForm.amount = '';
			},
			getcapitalPoolAdd() {
				let data = {
					amount: this.capitalForm.amount,
					userId: localStorage.getItem('user')
				};
				fundPoolAdd(data).then(res => {
					if (res.code == 200) {
						this.$message.success(res.data);
						this.addCapitalNum = false;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			getfundPool() {
				let data = {
					userId: localStorage.getItem('user')
				};
				fundPool(data).then(res => {
					if (res.code == 200) {
						this.capitaltableData = res.data.selectorList;
						this.capitaltotal = Number(res.data.total.toFixed(2));
					} else {
						this.$message.error(res.message);
					}
				});
			}
		}
	};
</script>

<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}

	/* a:hover {
	background: #66b1ff;
} */
	.handle-select {
		width: 240px;
	}

	.table {
		width: 100%;
		font-size: 12px;
	}

	.red {
		color: #f56c6c;
	}

	.mr10 {
		margin-right: 10px;
	}

	.mr100 {
		position: absolute;
		right: 100px;
	}

	.myRight {
		position: absolute;
		right: 10px;
		bottom: 10px;
	}

	.handle-input {
		width: 200px;
		display: inline-block;
	}

	.recharge-box .el-radio__input {
		display: none;
	}

	.recharge-box .el-radio.is-bordered {
		padding: 8px 14px 0 5px;
		border-radius: 4px;
		border: 1px solid #dcdfe6;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		height: 30px;
	}

	.table-box {
		width: 1100px;
		border: 1px solid #ebeef5;
		display: flex;
		flex-direction: row;
	}

	.user-info-box {
		/* border: 1px solid #ebeef5; */

		width: 300px;
	}

	.user-info-tit {
		background: #fafafa;
		color: #000;
		font-weight: 600;
		text-align: center;
		height: 34px;
		line-height: 34px;
		border-right: 1px solid #ebeef5;
		border-bottom: 1px solid #ebeef5;
	}

	.info-box {
		display: flex;
		flex-direction: row;
		height: 34px;
		line-height: 34px;
		border-bottom: 1px solid #ebeef5;
		border-right: 1px solid #ebeef5;
	}

	.info-name {
		background: #fafafa;
		border-right: 1px solid #ebeef5;
		width: 120px;
		padding-left: 10px;
		font-weight: 600;
	}

	.user-info-box .info-box .info {
		padding-left: 10px;
		color: green;
	}

	.down {
		border: 1px solid #dcdfe6;
		background: #4f7afd;
		color: #ffffff;
		transition: 0.1s;
		font-weight: 500;
		padding: 6px 12px;
		font-size: 12px;
		border-radius: 4px;

		display: inline-block;
	}

	.grid-content {
		display: flex;
		align-items: center;
		height: 100px;
	}

	/deep/ .el-col-16 {
		flex: 0 0 59.9%;
	}

	.grid-cont-right {
		flex: 1;
		text-align: center;
		font-size: 20px;
		font-weight: bold;
		color: #999;
	}

	.grid-num {
		font-size: 30px;
		font-weight: bold;
	}

	.grid-con-icon {
		font-size: 50px;
		width: 100px;
		height: 100px;
		text-align: center;
		line-height: 100px;
		color: #fff;
	}

	.grid-con-1 .grid-con-icon {
		background: linear-gradient(#ffb199, #ff0844);
	}

	.new {
		color: #b5b4b4;
		font-size: 12px;
	}

	.grid-con-1 .grid-num {
		color: #4f7afd;
	}

	.grid-con-2 .grid-con-icon {
		background: linear-gradient(#48c6ef, #6f86d6);
	}

	.grid-con-2 .grid-num {
		color: #4f7afd;
	}

	.grid-con-3 .grid-con-icon {
		background: linear-gradient(#e5b2ca, #7028e4);
	}

	.grid-con-3 .grid-num {
		color: #4f7afd;
	}

	.grid-con-4 .grid-con-icon {
		background: linear-gradient(#00c6fb, #005bea);
	}

	.grid-con-4 .grid-num {
		color: #4f7afd;
	}

	.grid-con-5 .grid-con-icon {
		background: linear-gradient(#89f7fe, #66a6ff);
	}

	.grid-con-5 .grid-num {
		color: #4f7afd;
	}
</style>