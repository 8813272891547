<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-input size="small" v-model="query.importFileName" clearable placeholder="请输入文件名称"
					style="width: 360px;" class="handle-input mr10"></el-input>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search" class="m_l_10"
					@click="handleSearch">搜索</el-button>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-plus" class="m_t_10"
					@click="handleAdd">添加</el-button>
			</div>
			<el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header"
				:row-style="{ height: '20px' }" :cell-style="{ padding: '6px' }"
				:header-cell-style="{ 'text-align': 'center' }" :row-class-name="tableRowClassName"
				@selection-change="handleSelectionChange">
				<el-table-column prop="operNo" label="操作工号" align="center" width="160"></el-table-column>
				<el-table-column prop="operName" label="操作人" align="center" width="160"></el-table-column>
				<el-table-column prop="importFileName" label="导入文件名" align="center"></el-table-column>
				<el-table-column prop="fileUrl" label="下载地址" align="center" width="100">
					<template #default="scope">
						<a :href="scope.row.fileUrl" style="cursor: pointer;color: #ff0000;" download="加白记录.text">
							<i class="el-icon-download"></i>
							下载文件
						</a>
					</template>
				</el-table-column>
				<el-table-column prop="exportFileName" label="下载文件名" align="center"></el-table-column>
				<el-table-column prop="createTime" label="创建时间" align="center">
					<template #default="scope">
						<span>{{ toDateTimeStr(scope.row.createTime) }}</span>
					</template>
				</el-table-column>
				<el-table-column prop="finishTime" label="完成时间" align="center">
					<template #default="scope">
						<span>{{ toDateTimeStr(scope.row.finishTime) }}</span>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination">
				<el-pagination background layout="total, sizes, prev, pager, next, jumper"
					:current-page="query.pageIndex" :page-sizes="[10, 30, 50, 100, 300]" :page-size="query.pageSize"
					:total="pageTotal" @size-change="handleSizeChange"
					@current-change="handlePageChange"></el-pagination>
			</div>
			<el-dialog title="批量加白" v-model="addVisible" width="520px" @close="closeDialog">
				<el-form label-width="120px" :model="addForm" ref="editForm">
					<el-form-item label="选择模式" prop="tab1">
						<el-radio-group v-model="tab1">
							<el-radio label="input">手动添加</el-radio>
							<el-radio label="fled">批量添加</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="类型" prop="status">
						<el-radio-group v-model="addForm.status">
							<el-radio label="0">新增</el-radio>
							<el-radio label="1">删除</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="手机号码" prop="phones" v-if="tab1 == 'input'">
						<el-input size="small" :rows="5" type="textarea" v-model="addForm.phones" auto-complete="off"
							placeholder="一行一个手机号码,一次最多添加500个号码"></el-input>
					</el-form-item>
					<el-form-item label="上传文件" prop="" v-else>
						<el-upload ref="upload" action="/api/Snail/FileBatchWhitening" :headers="token" :limit="1"
							:on-success="uploadSuccess" :auto-upload="false" :data="addForm">
							<el-button size="small" type="primary" :class="[theme]"
								style="margin-top: 50px;">选择文件</el-button>
							<div class="el-upload__tip">只能导入txt文件</div>
						</el-upload>
					</el-form-item>
					<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
						<el-button size="small" @click="closeDialog">取消</el-button>
						<el-button size="small" type="primary" :class="[theme]" v-if="tab1 == 'input'"
							@click="getBatchWhitening">确定</el-button>
						<el-button size="small" type="primary" :class="[theme]" v-else
							@click="submitUpload">确定</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import { BatchWhitening, getContrastInfo } from '../api/dijia.js';
	import { fetchCompanyByAgentId, fetchDefaultCompanies } from '../api/companyIndex';
	import { fetchAgent } from '../api/agentIndex';
	import { querySubAgent } from '../api/serialIndex.js';
	export default {
		name: 'WNWhitelist',
		data() {
			return {
				theme: localStorage.getItem('theme'),
				query: {
					importFileName: '',
					pageIndex: 1,
					pageSize: 10
				},
				tableData: [],
				pageTotal: 0,
				addVisible: false,
				addForm: {
					status: '0'
				},
				tab1: 'input',
				token: { Authorization: localStorage.getItem('token') }
			};
		},
		created() {
			let role = localStorage.getItem('ms_role');
			this.subAgent = localStorage.getItem('subAgent');
			if (role === 'agent') {
				this.isAgent = true;
				this.isNotAdmin = false;
				if (this.subAgent == 'true') {
					this.isSubAgent = true;
					this.getquerySubAgent();
				} else {
					this.isSubAgent = false;
				}
			}
			if (role === 'subAgent') {
				this.isAgent = true;
			}
			if (role === 'admin') {
				this.isAdmin = true;
				this.isAgent = true;
				this.isNotAdmin = false;
			}
			this.getContrastInfo();
		},
		methods: {
			tableRowClassName({ rowIndex }) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},
			getContrastInfo() {
				this.loading = true;
				this.tableData = [];
				getContrastInfo(this.query).then(res => {
					this.loading = false;
					if (res.code == 200) {
						this.tableData = res.data.list;
						this.pageTotal = res.data.total;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			// 触发搜索按钮
			handleSearch() {
				this.query.pageIndex = 1;
				this.getContrastInfo();
			},
			handleSizeChange(val) {
				this.query.pageSize = val;
				this.getContrastInfo();
			},
			// 分页导航
			handlePageChange(val) {
				this.query.pageIndex = val;
				this.getContrastInfo();
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},

			handleAdd() {
				this.addVisible = true;
			},

			toDateTimeStr(dateTime) {
				const date = new Date(dateTime);

				// 使用Date对象的方法来获取年月日时分秒
				const year = date.getFullYear();
				const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份是从0开始的，所以需要+1，并用'0'填充
				const day = String(date.getDate()).padStart(2, '0');
				const hours = String(date.getHours()).padStart(2, '0');
				const minutes = String(date.getMinutes()).padStart(2, '0');
				const seconds = String(date.getSeconds()).padStart(2, '0');

				// 拼接成完整的日期时间字符串
				const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

				// 返回格式化后的日期时间字符串
				return formattedDateTime;
			},
			getBatchWhitening() {
				let data = {
					phones: this.addForm.phones,
					status: this.addForm.status,
					userId: localStorage.getItem('user')
				};
				BatchWhitening(data).then(res => {
					if (res.code == 200) {
						this.$message.success(res.message);
						this.addForm.phones = '';
						this.addForm.status = '0';
						this.tab1 = 'input';
						this.addVisible = false;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			submitUpload() {
				if (this.$refs.upload.uploadFiles[0]) {
					this.loading = true;
					this.$refs.upload.submit();
					this.addForm.phones = '';
					this.addForm.status = '0';
					this.tab1 = 'input';
					this.$message.success('提交成功');
					this.addVisible = false;
				} else {
					this.$message.error('请选择上传文件');
				}
			},
			closeDialog() {
				this.addForm.phones = '';
				this.addForm.status = '0';
				this.tab1 = 'input';
				this.addVisible = false;
			}
		}
	};
</script>

<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}

	.handle-select {
		width: 240px;
	}

	.table {
		width: 100%;
		font-size: 12px;
	}

	.red {
		color: #f56c6c;
	}

	.mr10 {
		margin-right: 10px;
	}

	.mr100 {
		position: absolute;
		right: 100px;
	}

	.myRight {
		position: absolute;
		right: 10px;
		bottom: 10px;
	}

	.handle-input {
		width: 200px;
		display: inline-block;
	}

	.recharge-box .el-radio__input {
		display: none;
	}

	.recharge-box .el-radio.is-bordered {
		padding: 8px 14px 0 5px;
		border-radius: 4px;
		border: 1px solid #dcdfe6;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		height: 30px;
	}

	.status_color2 {
		color: #fff;
		background-color: #ff0000;
		border-radius: 3px;
		display: inline-block;
		width: 60px;
		height: 26px;
		text-align: center;
		line-height: 26px;
		font-size: 12px;
	}

	.bgred {
		background-color: #ff0000;
	}

	.bggreen {
		background-color: #67c23a;
	}
</style>