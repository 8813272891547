<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-input size="small" v-model="query.phoneNumber" clearable maxlength="12" placeholder="被举报号码"
					style="width: 180px;" class="handle-input mr10"></el-input>
				<el-select size="small" v-model="query.operator" filterable clearable placeholder="品牌"
					style="width: 180px;" class="handle-select mr10">
					<el-option v-for="item in cardTypeData" :key="item.value" :label="item.value"
						:value="item.value"></el-option>
				</el-select>
				<el-select size="small" v-model="query.vestingPerson" filterable clearable placeholder="归属人"
					style="width: 180px;" class="handle-select mr10">
					<el-option v-for="item in vestingPersonList" :key="item._id" :label="item._id"
						:value="item._id"></el-option>
				</el-select>
				<el-select size="small" v-model="query.companyName" filterable clearable placeholder="投诉对应企业"
					style="width: 180px;margin-top: 10px;" class="handle-select mr10">
					<el-option v-for="item in companyNameList" :key="item.sum" :label="item._id"
						:value="item._id"></el-option>
				</el-select>
				<el-select size="small" v-model="query.complaintChannel" filterable clearable placeholder="下发渠道"
					style="width: 180px;" class="handle-select mr10">
					<el-option v-for="item in complaintChannelList" :key="item.value" :label="item.value"
						:value="item.value"></el-option>
				</el-select>
				<el-select size="small" v-model="query.complaintType" filterable clearable placeholder="下发类型"
					style="width: 180px;" class="handle-select mr10">
					<el-option v-for="item in complaintTypeList" :key="item.value" :label="item.value"
						:value="item.value"></el-option>
				</el-select>
				<el-select size="small" v-model="query.whetherPay" filterable clearable placeholder="罚款是否收回"
					style="width: 180px;margin-top: 10px;" class="handle-select mr10">
					<el-option v-for="item in whetherPayList" :key="item.value" :label="item.value"
						:value="item.value"></el-option>
				</el-select>

				<el-select size="small" v-model="query.appealSuccessfully" filterable clearable placeholder="申诉成功"
					style="width: 180px;margin-top: 10px;" class="handle-select mr10">
					<el-option v-for="item in appealSuccessfullyList" :key="item.value" :label="item.value"
						:value="item.value"></el-option>
				</el-select>

				<el-date-picker v-model="timeArr" style="width: 280px;margin-right: 10px;margin-top: 10px;"
					type="daterange" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
					@change="getTime()" size="small"></el-date-picker>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search" style="margin-top: 10px;"
					class="m_t_20" @click="handleSearch">搜索</el-button>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-upload" style="margin-top: 10px;"
					class="m_t_20" @click="importForm">导入</el-button>
				<a :href="`${path}workOrder.xlsx`" class="down" style="margin:10px 10px;" download="工单模板.xlsx"
					:class="[theme]">
					<i class="el-icon-download"></i>
					下载模板
				</a>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-upload2  el-icon--right"
					style="margin-top: 10px;" @click="exportData">导出</el-button>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-upload2  el-icon--right"
					style="margin-top: 10px;" @click="batchDel">批量删除</el-button>
			</div>

			<el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header"
				:header-cell-style="{ 'text-align': 'center' }" @selection-change="handleSelectionChange"
				:row-style="{ height: '20px' }" :cell-style="{ padding: '6px' }" :row-class-name="tableRowClassName"
				style="width: 100%">
				<el-table-column type="selection" width="55" align="center"></el-table-column>
				<el-table-column prop="operator" fixed width="120" label="运营商" align="center"></el-table-column>
				<el-table-column prop="deliveryTime" width="140" label="下发日期" align="center"></el-table-column>
				<el-table-column prop="complaintTime" width="200" label="举报日期" align="center"></el-table-column>
				<el-table-column prop="complaintChannel" width="140" label="下发渠道" align="center"></el-table-column>
				<el-table-column prop="complaintType" width="120" label="下发类型" align="center"></el-table-column>
				<el-table-column prop="phoneNumberOfTheComplaint" width="140" label="举报号码"
					align="center"></el-table-column>
				<el-table-column prop="phoneNumber" width="140" label="被举报号码" align="center"></el-table-column>
				<el-table-column prop="complaintContent" width="200" label="举报内容" align="center"
					show-overflow-tooltip></el-table-column>
				<el-table-column prop="vestingPerson" width="200" label="归属人" align="center"></el-table-column>
				<el-table-column prop="companyName" width="200" label="投诉对应企业" align="center"></el-table-column>
				<el-table-column prop="isReduction" width="120" label="是否提交核减" align="center"></el-table-column>
				<el-table-column prop="whetherPay" width="120" label="罚款是否收回" align="center"></el-table-column>
				<el-table-column prop="payAmount" width="160" label="实际收回金额" align="center"></el-table-column>
				<el-table-column prop="appealSuccessfully" label="申诉成功" align="center"></el-table-column>
				<el-table-column prop="appealFailure" label="申诉失败" align="center"></el-table-column>
				<el-table-column prop="appealRejected" label="申诉驳回" align="center"></el-table-column>
				<el-table-column label="操作" width="190" align="center" fixed="right">
					<template #default="scope">
						<el-button size="small" style="cursor: pointer;" type="text" icon="el-icon-edit"
							@click="handleEdit(scope.row)">编辑</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination">
				<el-pagination background layout="total, sizes, prev, pager, next, jumper"
					:current-page="query.pageIndex" :page-sizes="[10, 30, 50]" :page-size="query.pageSize"
					:total="pageTotal" @size-change="handleSizeChange"
					@current-change="handlePageChange"></el-pagination>
			</div>
		</div>
		<div class="customer">
			<el-dialog title="文件导入" v-model="upload" width="500px" @close="closeUpload()" :close-on-click-modal="false">
				<el-upload ref="upload" action="/api/blackList/uploadWorkOrder" :headers="token" :limit="1"
					:on-success="uploadSuccess" :on-preview="handlePreview" :on-remove="handleRemove"
					:auto-upload="false" :data="uploadData">
					<el-button size="small" type="primary" :class="[theme]">选择文件</el-button>
					<div class="el-upload__tip">只能导入excel文件</div>
				</el-upload>
				<el-button size="small" style="margin: 10px 0 0 80%;" type="primary" :class="[theme]"
					v-loading.fullscreen.lock="loading" @click="submitUpload">确定上传</el-button>
			</el-dialog>
		</div>
		<div class="customer">
			<el-dialog title="编辑" v-model="editVisible" width="500px" :close-on-click-modal="false">
				<el-form :model="form" :rules="rules" ref="form" label-width="100px" class="edit-box">
					<el-form-item label="运营商"><el-input size="small" v-model="form.operator"
							disabled></el-input></el-form-item>
					<el-form-item label="下发日期"><el-input size="small" v-model="form.deliveryTime"
							disabled></el-input></el-form-item>
					<el-form-item label="举报日期"><el-input size="small" v-model="form.complaintTime"
							disabled></el-input></el-form-item>
					<el-form-item label="下发渠道"><el-input size="small" v-model="form.complaintChannel"
							disabled></el-input></el-form-item>
					<el-form-item label="下发类型"><el-input size="small" v-model="form.complaintType"
							disabled></el-input></el-form-item>
					<el-form-item label="举报电话"><el-input size="small" v-model="form.phoneNumberOfTheComplaint"
							disabled></el-input></el-form-item>
					<el-form-item label="被举报电话"><el-input size="small" v-model="form.phoneNumber"
							disabled></el-input></el-form-item>
					<el-form-item label="举报内容"><el-input size="small" v-model="form.complaintContent"
							disabled></el-input></el-form-item>
					<el-form-item label="归属人"><el-input size="small" v-model="form.vestingPerson"
							disabled></el-input></el-form-item>
					<el-form-item label="是否提交核减"><el-input size="small"
							v-model="form.isReduction"></el-input></el-form-item>
					<el-form-item label="罚款是否收回"><el-input size="small"
							v-model="form.whetherPay"></el-input></el-form-item>
					<el-form-item label="实际收回金额"><el-input size="small"
							v-model="form.payAmount"></el-input></el-form-item>
					<el-form-item label="申诉成功"><el-input size="small"
							v-model="form.appealSuccessfully"></el-input></el-form-item>
					<el-form-item label="申诉失败"><el-input size="small"
							v-model="form.appealFailure"></el-input></el-form-item>
					<el-form-item label="申诉驳回"><el-input size="small"
							v-model="form.appealRejected"></el-input></el-form-item>
					<el-form-item>
						<el-button size="small" style="margin-left: 55%;" @click="editVisible = false">取消</el-button>
						<el-button size="small" type="primary" :class="[theme]" @click="edit('form')">确定</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>
		</div>
		<div class="paw-Box">
			<el-dialog title="密码验证" v-model="pawBox" width="400px" :close-on-click-modal="false">
				<el-form :model="formPawBox" ref="formPawBox" label-width="100px" class="edit-box">
					<el-form-item label="密码验证"><el-input type="password" size="small"
							v-model="formPawBox.passWord"></el-input></el-form-item>
					<el-form-item>
						<el-button size="small" style="margin-left: 55%;" @click="pawBox = false">取消</el-button>
						<el-button size="small" type="primary" :class="[theme]" @click="submitPassWord">确定</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import {
		getupdateDownRecord,
		downloadDownRecord,
		getWorkOrderList,
		getVestingPerson,
		updateWorkOrder,
		uploadWorkOrder,
		downloadWorkOrder,
		verifyPassWord,
		batchDelete,
		vestingCompany
	} from '../api/reportNumber.js';
	import { getCardType } from '../api/cardType.js';
	export default {
		name: 'workOrder',
		data() {
			return {
				theme: localStorage.getItem('theme'),
				query: {
					pageIndex: 1,
					pageSize: 10,
					vestingPerson: '',
					phoneNumber: '',
					phoneNumberOfTheComplaint: '',
					operator: '',
					complaintChannel: '',
					complaintType: '',
					startTime: '',
					endTime: '',
					userId: '',
					whetherPay: '',
					appealSuccessfully: '',
					companyName: ''
				},
				timeArr: [],
				companyNameList: [],
				complaintChannelList: [{ value: '12321举报中心' }, { value: '公安部协查' }, { value: '省公安协查' }],
				complaintTypeList: [{ value: '骚扰电话' }, { value: '诈骗电话' }],

				cardTypeData: [],
				vestingPersonList: [],
				tableData: [],
				multipleSelection: [],
				delList: [],
				pageTotal: 0,
				path: process.env.BASE_URL,
				idx: -1,
				id: -1,
				loading: false,
				token: { Authorization: localStorage.getItem('token'), UserRole: localStorage.getItem('ms_role') },
				upload: false,
				isAdmin: false,
				editVisible: false,
				str: '', //多选
				whetherPayList: [{ label: '是', value: '0' }, { label: '否', value: '1' }],
				appealSuccessfullyList: [{ label: '是', value: '0' }, { label: '否', value: '1' }],
				form: {
					pageIndex: 1,
					pageSize: 10,
					vestingPerson: '',
					phoneNumberOfTheComplaint: '',
					operator: '',
					complaintChannel: '',
					complaintType: '',
					endTime: '',
					startTime: '',
					userId: ''
				},
				uploadData: {
					userId: localStorage.getItem('user')
				},
				pawBox: false,
				formPawBox: {
					passWord: '',
					userId: localStorage.getItem('user')
				}
			};
		},
		created() {
			let role = localStorage.getItem('ms_role');
			if (role === 'normal') {
				this.isNormal = false;
			}
			if (role === 'admin') {
				this.isAdmin = true;
			}
			if (role === 'agent') {
				this.isAgent = true;
			}
			if (localStorage.getItem('timestamp')) {
				this.getWorkOrderLists();
				this.getCardTypes();
				this.getvestingCompany();
				this.getVestingPersons();
			} else {
				this.pawBox = true;
				this.tableData = [];
				localStorage.removeItem('timestamp');
			}
		},
		methods: {
			tableRowClassName({ rowIndex }) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},
			getWorkOrderLists() {
				if (Date.parse(new Date()) - localStorage.getItem('timestamp') > 1000 * 60 * 60 * 3) {
					this.pawBox = true;
					this.tableData = [];
					localStorage.removeItem('timestamp');
				} else {
					let data = {
						pageSize: this.query.pageSize,
						pageIndex: this.query.pageIndex,
						vestingPerson: this.query.vestingPerson,
						phoneNumberOfTheComplaint: this.query.phoneNumberOfTheComplaint,
						phoneNumber: this.query.phoneNumber.replaceAll(' ', ''),
						operator: this.query.operator,
						complaintChannel: this.query.complaintChannel,
						complaintType: this.query.complaintType,
						companyName: this.query.companyName,
						startTime: this.query.startTime,
						endTime: this.query.endTime,
						whetherPay: this.query.whetherPay,
						appealSuccessfully: this.query.appealSuccessfully,
						userId: localStorage.getItem('user')
					};
					getWorkOrderList(data).then(res => {
						localStorage.setItem('timestamp', Date.parse(new Date()));
						this.tableData = res.data.list;
						this.pageTotal = res.data.total;
					});
				}
			},
			//品牌
			getvestingCompany() {
				if (Date.parse(new Date()) - localStorage.getItem('timestamp') > 1000 * 60 * 60 * 3) {
					this.pawBox = true;
					this.tableData = [];
					localStorage.removeItem('timestamp');
				} else {
					let data = {
						userId: localStorage.getItem('user')
					};
					vestingCompany(data).then(res => {
						if (res.code == 200) {
							this.companyNameList = res.data;
						} else {
							this.$message.error(res.message);
						}
					});
				}
			},
			//品牌
			getCardTypes() {
				if (Date.parse(new Date()) - localStorage.getItem('timestamp') > 1000 * 60 * 60 * 3) {
					this.pawBox = true;
					this.tableData = [];
					localStorage.removeItem('timestamp');
				} else {
					getCardType().then(res => {
						if (res.code == 200) {
							this.cardTypeData = res.data;
						} else {
							this.$message.error(res.message);
						}
					});
				}
			},
			//归属人
			getVestingPersons() {
				if (Date.parse(new Date()) - localStorage.getItem('timestamp') > 1000 * 60 * 60 * 3) {
					this.pawBox = true;
					this.tableData = [];
					localStorage.removeItem('timestamp');
				} else {
					let data = {
						userId: localStorage.getItem('user')
					};
					getVestingPerson(data).then(res => {
						if (res.code == 200) {
							this.vestingPersonList = res.data;
						} else {
							this.$message.error(res.message);
						}
					});
				}
			},
			toDateTimeStr(date) {
				let y = date.getFullYear();
				let m = date.getMonth() + 1;
				let d = date.getDate();
				if (m < 10) {
					m = '0' + m;
				}
				if (d < 10) {
					d = '0' + d;
				}
				return y + '-' + m + '-' + d;
			},
			//筛选时间
			getTime() {
				this.timeArr.push(this.query.startTime);
				this.timeArr.push(this.query.endTime);
				this.query.startTime = this.toDateTimeStr(this.timeArr[0]);
				this.query.endTime = this.toDateTimeStr(this.timeArr[1]);
			},
			//导入
			importForm() {
				if (Date.parse(new Date()) - localStorage.getItem('timestamp') > 1000 * 60 * 60 * 3) {
					this.pawBox = true;
					this.tableData = [];
					localStorage.removeItem('timestamp');
				} else {
					this.upload = true;
				}
			},
			//导入上传
			uploadSuccess(success) {
				setTimeout(() => {
					this.loading = false;
					this.$refs.upload.clearFiles();
					this.upload = false;
				}, 2000);
				if (success.code == 200) {
					this.$message.success('上传成功');
					this.getWorkOrderLists();
				} else {
					this.$message.error(success.message);
				}
			},
			submitPassWord() {
				verifyPassWord(this.formPawBox).then(res => {
					if (res.code == 200) {
						if (res.data == true) {
							this.$message.success('验证成功');
							localStorage.setItem('timestamp', Date.parse(new Date()));
							this.pawBox = false;
							this.formPawBox.passWord = '';
							this.getWorkOrderLists();
							this.getCardTypes();
							this.getvestingCompany();
							this.getVestingPersons();
						} else {
							this.formPawBox.passWord = '';
							this.$message.error('验证失败');
						}
					} else {
						this.$message.error(res.message);
					}
				});
			},
			closeUpload() {
				this.$refs.upload.clearFiles();
			},
			submitUpload() {
				if (this.$refs.upload.uploadFiles[0]) {
					this.loading = true;
					this.$refs.upload.submit();
				} else {
					this.$message.error('请选择上传文件');
				}
			},

			handleRemove(file, fileList) {
				console.log(file, fileList);
			},
			handlePreview(file) {
				console.log(file);
			},

			// 编辑操作
			handleEdit(row) {
				this.form = row;
				this.editVisible = true;
			},

			edit(formName) {
				this.$refs[formName].validate(valid => {
					if (valid) {
						this.loading = true;
						this.form.userId = localStorage.getItem('user');
						if (Date.parse(new Date()) - localStorage.getItem('timestamp') > 1000 * 60 * 60 * 3) {
							this.pawBox = true;
							this.tableData = [];
							localStorage.removeItem('timestamp');
						} else {
							updateWorkOrder(this.form).then(res => {
								if (res.code == 200) {
									this.$message.success('修改成功');
									this.getWorkOrderLists();
								} else {
									this.$message.error(res.message);
								}
								this.editVisible = false;
							});
						}
					}
				});
			},
			// 触发搜索按钮
			handleSearch() {
				this.query.pageIndex = 1;
				this.getWorkOrderLists();
			},

			exportData() {
				this.loading = true;
				this.query.userId = localStorage.getItem('user');
				if (Date.parse(new Date()) - localStorage.getItem('timestamp') > 1000 * 60 * 60 * 3) {
					this.pawBox = true;
					this.tableData = [];
					localStorage.removeItem('timestamp');
				} else {
					let str = '';
					this.delList = this.delList.concat(this.multipleSelection);
					for (let i = 0; i < this.multipleSelection.length; i++) {
						str += this.multipleSelection[i].id + ',';
					}
					this.query.ids = str;
					downloadWorkOrder(this.query).then(res => {
						const blob = new Blob([res], {
							type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
						});
						let downloadElement = document.createElement('a');
						let href = window.URL.createObjectURL(blob);
						downloadElement.href = href;
						let fileName = '工单记录' + '.xlsx';
						downloadElement.download = fileName;
						document.body.appendChild(downloadElement);
						downloadElement.click();
						document.body.removeChild(downloadElement);
						window.URL.revokeObjectURL(href);
						this.loading = false;
						this.getWorkOrderLists();
					});
				}
			},

			// 分页导航
			handlePageChange(val) {
				this.query.pageIndex = val;
				this.getWorkOrderLists();
			},
			handleSizeChange(val) {
				this.query.pageSize = val;
				this.getWorkOrderLists();
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			closeDialog(formName) {
				this.editVisible = false;
				this.getWorkOrderLists();
				(this.form = {
					remark: ''
				}),
				this.resetForm(formName);
			},
			// 多选操作
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			/* 删除 */
			batchDel() {
				let str = '';
				this.delList = this.delList.concat(this.multipleSelection);
				for (let i = 0; i < this.multipleSelection.length; i++) {
					str += this.multipleSelection[i].id + ',';
				}
				if (str === '') {
					this.$message.error(`请选择线索`);
					return;
				} else {
					let param = {
						ids: str
					};
					// 二次确认删除
					this.$confirm('确定要删除吗？', '提示', {
							type: 'warning'
						})
						.then(() => {
							batchDelete(param).then(res => {
								if (res.code == 200) {
									this.$message.success(res.data);
									this.getWorkOrderLists();
								} else {
									this.$message.error(res.message);
								}
							});
						})
						.catch(() => {});
				}
				this.multipleSelection = [];
			}
		}
	};
</script>

<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}

	.down {
		border: 1px solid #dcdfe6;
		background: #4f7afd;
		color: #ffffff;
		transition: 0.1s;
		font-weight: 500;
		padding: 6px 12px;
		font-size: 12px;
		border-radius: 4px;
		display: inline-block;
	}

	.handle-select {
		width: 240px;
	}

	.table {
		width: 100%;
		font-size: 12px;
	}

	.red {
		color: #f56c6c;
	}

	.mr10 {
		margin-right: 10px;
	}

	.mr100 {
		position: absolute;
		right: 100px;
	}

	.myRight {
		position: absolute;
		right: 10px;
		bottom: 10px;
	}

	.handle-input {
		width: 200px;
		display: inline-block;
	}

	.customer .el-upload {
		display: flex !important;
		flex-direction: column !important;
		align-items: center !important;
		justify-content: center !important;
		width: 100% !important;
		line-height: 50px !important;
	}

	.customer .btn {
		background-color: #4f7afd;
		margin-top: 20px;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		border: none;
		margin-left: 80%;
	}

	/deep/.edit-box .el-form-item {
		margin-bottom: 8px !important;
	}

	.tablemasaik {
		background-image: url(../assets/img/masaike.jpg) !important;
	}
</style>
<style>
	.customer .el-upload {
		width: 100% !important;
		display: flex !important;
		flex-direction: column !important;
		align-items: center !important;
		justify-content: center !important;
	}

	.customer .btn {
		background-color: #4f7afd;
		margin-top: 20px;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		border: none;
		margin-left: 80%;
	}
</style>