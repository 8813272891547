<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-select size="small" v-if="isAdmin" @change="agentChange" v-model="query.agentId" filterable
					clearable placeholder="请选择代理商" class="handle-select mr10" style="width: 260px;">
					<el-option v-for="item in agents" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-select size="small" v-if="isSubAgent" v-model="query.subAgentId" @change="getfetchDefaultCompanies"
					filterable clearable placeholder="子代理" class="handle-select mr10" style="width: 260px;">
					<el-option v-for="item in subAgentList" :key="item.id" :label="item.name"
						:value="item.id"></el-option>
				</el-select>
				<el-select size="small" style="width: 260px" v-if="isAgent" v-model="query.companyId"
					@change="companyChange" filterable clearable placeholder="企业" class="handle-select mr10">
					<el-option v-for="item in companys" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-select size="small" style="width: 180px" v-model="query.status" filterable clearable
					placeholder="是否处理" class="handle-select mr10">
					<el-option v-for="item in disposeList" :key="item.label" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-input size="small" v-model.trim="query.phoneNumber" clearable placeholder="请输入客户手机号" class="mr10"
					style="width: 180px;"></el-input>
				<el-select size="small" style="width: 180px" v-model="query.label" filterable clearable placeholder="原因"
					class="handle-select mr10">
					<el-option label="诈骗" value="诈骗"></el-option>
					<el-option label="辱骂" value="辱骂"></el-option>
					<el-option label="违规" value="违规"></el-option>
					<el-option label="话术不一样" value="话术不一样"></el-option>
					<el-option label="其他" value="其他"></el-option>
				</el-select>

				<el-date-picker v-model="timeArr" type="daterange" style="width: 260px;margin-right: 10px;"
					range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" size="small"
					:clearable="false"></el-date-picker>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search"
					@click="handleSearch">搜索</el-button>
				<el-button size="small" type="primary" :class="[theme]" @click="addRiskVisibleShow">加入黑名单总库</el-button>
			</div>

			<el-row>
				<el-col :span="24">
					<el-table :data="tableData" border class="table" ref="multipleTable"
						header-cell-class-name="table-header" :cell-style="{ cursor: 'pointer', padding: '6px' }"
						:row-style="{ height: '20px' }" :header-cell-style="{ 'text-align': 'center' }"
						:row-class-name="tableRowClassName">
						<el-table-column prop="agentName" label="代理" align="center" width="240"></el-table-column>
						<el-table-column prop="companyName" label="企业名称" align="center" width="240"></el-table-column>
						<el-table-column prop="phoneNumber" label="客户手机号" align="center" width="115"></el-table-column>
						<el-table-column prop="userPhone" label="坐席" align="center" width="115"></el-table-column>
						<el-table-column prop="callDate" label="拨打时间" align="center" width="155"></el-table-column>
						<el-table-column prop="createdAt" label="质检时间" align="center" width="155"></el-table-column>
						<el-table-column prop="label" label="标签" align="center" width="120">
							<template #default="scope">
								<el-tag>{{ scope.row.label }}</el-tag>
							</template>
						</el-table-column>
						<el-table-column prop="dispose" label="是否处理" align="center" width="80">
							<template #default="scope">
								<el-tag v-if="scope.row.dispose" type="success">已处理</el-tag>
								<el-tag v-else type="danger">未处理</el-tag>
							</template>
						</el-table-column>
						<el-table-column prop="remark" label="备注" align="center" width="200"
							show-overflow-tooltip></el-table-column>
						<el-table-column prop="dispose" label="操作" align="center" width="180" fixed="right">
							<template #default="scope">
								<el-button size="small" type="primary" :class="[theme]"
									@click="getrecordings(scope.row)">查看录音</el-button>
								<el-button size="small" type="primary" :class="[theme]"
									@click="getriskVisible(scope.row)">修改</el-button>
							</template>
						</el-table-column>
					</el-table>
				</el-col>
			</el-row>

			<div class="pagination">
				<el-pagination background layout="total, sizes, prev, pager, next, jumper"
					:current-page="query.pageIndex" :page-sizes="[100, 300, 500, 1000]" :page-size="query.pageSize"
					:total="pageTotal" @size-change="handleSizeChange"
					@current-change="handlePageChange"></el-pagination>
			</div>
		</div>

		<el-dialog title="加入黑名单总库" v-model="addRiskVisible" width="550px" @close="closeDialog()"
			:close-on-click-modal="false">
			<el-form :model="addRiskForm" :rules="rules" ref="riskForm" label-width="100px">
				<el-form-item label="号码:" prop="phones">
					<el-input size="small" v-model="addRiskForm.phones" type="textarea" :rows="5"
						placeholder="一行一个手机号码,一次最多添加500个号码"></el-input>
				</el-form-item>
				<el-form-item style="display: flex;flex-direction: row;justify-content: flex-end;">
					<el-button size="small" @click="closeaddRiskVisible">取消</el-button>
					<el-button size="small" type="primary" :class="[theme]" @click="getaddRiskVisible">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-dialog title="标记风险" v-model="riskVisible" width="550px" @close="closeDialog()"
			:close-on-click-modal="false">
			<el-form :model="riskForm" :rules="rules" ref="riskForm" label-width="100px">
				<el-form-item label="状态:" prop="dispose">
					<el-select size="small" v-model="riskForm.dispose" filterable clearable placeholder="是否处理">
						<el-option label="已处理" :value="true"></el-option>
						<el-option label="未处理" :value="false"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="备注:" prop="remark"><el-input size="small" v-model="riskForm.remark"
						placeholder="请输入备注"></el-input></el-form-item>
				<el-form-item label="加入黑名单总库" prop="riskBox">
					<el-switch v-model="riskForm.riskBox" active-color="#13ce66" inactive-color="#ff4949"
						@change="setswitch"></el-switch>
				</el-form-item>
				<el-form-item style="display: flex;flex-direction: row;justify-content: flex-end;">
					<el-button size="small" @click="closeDialog">取消</el-button>
					<el-button size="small" type="primary" :class="[theme]" @click="getriskRecordUpdate">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-dialog title="录音详情" v-model="audioVisible" width="750px" @close="closeDialog2()"
			:close-on-click-modal="false" style="display: flex;flex-direction: row;">
			<audio-player ref="audio" class="audio-box" :audiofileurl="customerDetails.recordPath"
				:audiorow="customerDetails" :key="Audiotimer"></audio-player>
		</el-dialog>
	</div>
</template>

<script>
	import {
		fetchDepartment,
		fetchUsersByDepartmentId,
		fetchDefaultUsers,
		riskList,
		riskRecordUpdate,
		getriskTel
	} from '../api/index.js';
	import { fetchCompanyByAgentId, fetchDefaultCompanies } from '../api/companyIndex';
	import { fetchAgent } from '../api/agentIndex';
	import AudioPlayer from '../components/audioPlayer3.vue';
	import { querySubAgent } from '../api/serialIndex.js';
	export default {
		name: 'riskList',
		components: {
			AudioPlayer
		},
		data() {
			return {
				timeArr: [],
				theme: localStorage.getItem('theme'),
				query: {
					agentId: '',
					companyId: '',
					userId: '',
					pageIndex: 1,
					pageSize: 30,
					status: 0,
					label: '',
					subAgentId: '',
					phoneNumber: '',
				},
				isSubAgent: false,
				disposeList: [{
						value: 0,
						label: '全部'
					},
					{
						value: 1,
						label: '已处理'
					},
					{
						value: 2,
						label: '未处理'
					}
				],
				tableData: [],
				agents: [],
				companys: [],
				role: '',
				pageTotal: 0,
				riskVisible: false,
				riskForm: {
					dispose: false,
					riskBox: false
				},
				isAdmin: false,
				isAgent: false,
				audioVisible: false,
				customerDetails: '',
				Audiotimer: '',
				addRiskVisible: false,
				addRiskForm: {
					phones: ''
				}
			};
		},

		created() {
			this.role = localStorage.getItem('ms_role');
			this.subAgent = localStorage.getItem('subAgent');
			if (this.role === 'agent') {
				this.isAgent = true;
				this.isNotAdmin = false;
				console.log(typeof this.subAgent, '11111');
				if (this.subAgent == 'true') {
					this.isSubAgent = true;
					this.getquerySubAgent();
				} else {
					this.isSubAgent = false;
				}
			}
			if (this.role === 'admin') {
				this.isAdmin = true;
				this.isAgent = true;
				this.isNotAdmin = false;
			}
			if (this.role === 'subAgent') {
				this.isAgent = true;
			}
			this.init();
			this.getData();
		},

		methods: {
			getfetchDefaultCompanies() {
				this.query.companyId = '';
				let data = {
					currentUserId: localStorage.getItem('user'),
					subAgentId: this.query.subAgentId
				};
				fetchDefaultCompanies(data).then(res => {
					this.companys = res.data;
				});
			},
			getquerySubAgent() {
				let data = {
					agentId: this.query.agentId,
					userId: localStorage.getItem('user')
				};
				querySubAgent(data).then(res => {
					if (res.code == 200) {
						this.subAgentList = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			getrecordings(row) {
				this.audioVisible = true;
				this.customerDetails = row;
				this.Audiotimer = new Date().getTime();
			},
			tableRowClassName({ rowIndex }) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},

			init() {
				fetchAgent(localStorage.getItem('user')).then(res => {
					this.agents = res.data;
				});
				if (this.role === 'agent') {
					this.getquerySubAgent();
				}
				this.getfetchDefaultCompanies();
				let data = {
					companyId: '',
					currentUserId: localStorage.getItem('user')
				};
				fetchDepartment(data).then(res => {
					this.departments = res.data;
				});
				fetchDefaultUsers(localStorage.getItem('user')).then(res => {
					this.users = res.data;
				});
			},
			agentChange(agentId) {
				this.query.agentId = agentId;
				this.query.companyId = '';
				this.query.userId = '';
				if (agentId) {
					let data = {
						subAgent: JSON.parse(localStorage.getItem('subAgent').toLowerCase()),
						agentId: agentId
					};
					fetchCompanyByAgentId(data).then(res => {
						this.companys = res.data;
					});
				}
			},
			/* 切换部门 */
			companyChange(companyId) {
				this.query.companyId = companyId;
				this.query.departmentId = '';
				this.query.userId = '';
				let data = {
					companyId: companyId,
					currentUserId: localStorage.getItem('user')
				};
				fetchDepartment(data).then(res => {
					this.departments = res.data;
					this.query.pageIndex = 1;
					this.getData();
				});
			},
			getData() {
				if (this.timeArr.length > 0) {
					let data = {
						userId: localStorage.getItem('user'),
						agentId: this.query.agentId,
						companyId: this.query.companyId,
						status: this.query.status,
						label: this.query.label,
						phoneNumber: this.query.phoneNumber,
						pageIndex: this.query.pageIndex,
						pageSize: this.query.pageSize,
						startTime: this.toDateTimeStr(this.timeArr[0]),
						endTime: this.toDateTimeStr(this.timeArr[1])
					};
					riskList(data).then(res => {
						this.tableData = res.data.list;
						this.pageTotal = res.data.total;
					});
				} else {
					let data = {
						userId: localStorage.getItem('user'),
						agentId: this.query.agentId,
						companyId: this.query.companyId,
						status: this.query.status,
						label: this.query.label,
						phoneNumber: this.query.phoneNumber,
						pageIndex: this.query.pageIndex,
						pageSize: this.query.pageSize
					};
					riskList(data).then(res => {
						this.tableData = res.data.list;
						this.pageTotal = res.data.total;
					});
				}
			},
			addRiskVisibleShow() {
				this.addRiskVisible = true;
				this.addRiskForm.phones = '';
			},
			closeaddRiskVisible() {
				this.addRiskVisible = false;
				this.addRiskForm.phones = '';
			},
			getaddRiskVisible() {
				let data = {
					phones: this.addRiskForm.phones
				};
				getriskTel(data).then(res => {
					if (res.code == 200) {
						this.addRiskVisible = false;
						this.addRiskForm.phones = '';
						this.$message.success(res.message);
						this.getData();
					} else {
						this.$message.error(res.message);
					}
				});
			},
			getTime() {
				let startTime = new Date(new Date());
				let endTime = new Date();
				this.timeArr.push(startTime);
				this.timeArr.push(endTime);
			},
			toDateTimeStr(date) {
				let y = date.getFullYear();
				let m = date.getMonth() + 1;
				let d = date.getDate();
				if (m < 10) {
					m = '0' + m;
				}
				if (d < 10) {
					d = '0' + d;
				}
				return y + '-' + m + '-' + d;
			},
			// 触发搜索按钮
			handleSearch() {
				this.query.pageIndex = 1;
				this.query.label = this.query.label.toString();
				this.getData();
			},

			// 分页导航
			handlePageChange(val) {
				this.query.pageIndex = val;
				this.getData();
			},
			handleSizeChange(val) {
				this.query.pageSize = val;
				this.getData();
			},
			getriskVisible(row) {
				this.riskForm = row;
				this.riskVisible = true;
			},
			setswitch(e) {
				this.riskForm.riskBox = e;
			},
			getriskRecordUpdate() {
				let data = {
					id: this.riskForm.id,
					dispose: this.riskForm.dispose,
					remark: this.riskForm.remark
				};
				riskRecordUpdate(data).then(res => {
					if (res.code == 200) {
						this.riskVisible = false;
						this.$message.success(res.data);
						this.getData();
					} else {
						this.$message.success(res.message);
					}
				});
				if (this.riskForm.riskBox) {
					let data = {
						phones: this.riskForm.phoneNumber
					};
					getriskTel(data).then(res => {
						if (res.code == 200) {
							this.$message.success(res.message);
						} else {
							this.$message.error(res.message);
						}
					});
				}
			},
			closeDialog() {
				this.riskVisible = false;
			},
			closeDialog2() {
				this.audioVisible = false;
				this.Audiotimer = new Date().getTime();
			}
		}
	};
</script>

<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}

	.mr10 {
		margin-right: 10px;
	}

	.red {
		color: #f56c6c;
	}
</style>