import request from '../utils/request';
import qs from "qs";

export const stop = param => {
    return request.post('/api/invoke/stop', qs.stringify(param))
};

export const phoneValidate = param => {
    return request.post('/api/invoke/validate', qs.stringify(param))
};

export const exportData = path => {
    return request.get('/api/invoke/getData?path='+path, {
        responseType: 'blob'
    })
};

export const busitypes = param => {
    return request.get('/api/invoke/busitypes', qs.stringify(param))
};

export const recordList = data => {
	return request.get('/api/invoke/recordList?currentUserId=' + data.currentUserId)
};

export const getInvalidData = param => {
    return request.post('/api/invoke/getInvalidData', qs.stringify(param))
};

