<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-select size="small" @change="agentChange" v-model="query.agentId" filterable clearable
					placeholder="请选择代理商" class="handle-select mb10 m_r_10" v-if="isAdmin">
					<el-option v-for="item in agents" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-select size="small" v-if="isSubAgent" v-model="query.subAgentId" @change="getfetchDefaultCompanies"
					filterable clearable placeholder="子代理" class="handle-select m_r_10" style="width: 260px;">
					<el-option v-for="item in subAgentList" :key="item.id" :label="item.name"
						:value="item.id"></el-option>
				</el-select>
				<el-select size="small" v-model="query.companyId" @change="getData()" filterable clearable
					placeholder="请选择企业" class="handle-select m_r_10">
					<el-option v-for="item in companys" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>

				<el-button size="small" type="primary" :class="[theme]" @click="getData()">搜索</el-button>
				<el-button size="small" type="primary" :class="[theme]" @click="handleAdd">添加</el-button>
			</div>
			<el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header"
				:row-style="{ height: '20px' }" :cell-style="{ padding: '6px' }"
				:header-cell-style="{ 'text-align': 'center' }" :row-class-name="tableRowClassName"
				@selection-change="handleSelectionChange">
				<el-table-column prop="companyName" label="企业名称" align="center"></el-table-column>
				<el-table-column prop="telA" label="号码" align="center"></el-table-column>
				<el-table-column prop="totalMinutes" label="套餐时长" align="center"></el-table-column>
				<el-table-column prop="monthUseMinutes" label="使用分钟" align="center"></el-table-column>

				<el-table-column prop="invalidateDate" label="套餐过期时间" align="center"></el-table-column>
				<el-table-column label="操作" align="center">
					<template #default="scope">
						<el-button size="small" type="text" icon="el-icon-edit" @click="handleEdit(scope.row)"
							v-if="isAdmin">分配套餐</el-button>
						<el-button size="small" type="text" @click="handlePurchase(scope.row)">购买套餐</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination">
				<el-pagination background layout="total, sizes, prev, pager, next, jumper"
					:current-page="query.pageIndex" :page-sizes="[30, 90, 200]" :page-size="query.pageSize"
					:total="pageTotal" @size-change="handleSizeChange"
					@current-change="handlePageChange"></el-pagination>
			</div>
			<el-dialog title="添加" v-model="addVisible" width="520px" @close="closeDialog">
				<el-form label-width="120px" :model="addForm" ref="addForm">
					<el-form-item label="代理" prop="agentId" v-if="isAdmin">
						<el-select size="small" @change="agentChange" v-model="addForm.agentId" filterable clearable
							placeholder="代理商" class="handle-select mr10" style="width: 100%">
							<el-option v-for="item in agents" :key="item.value" :label="item.label"
								:value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="企业" prop="companyId" v-if="isAdmin||isAgent">
						<el-select size="small" v-model="addForm.companyId" @change="companyChange" filterable clearable
							placeholder="企业" class="handle-select mr10" style="width: 100%">
							<el-option v-for="item in companys" :key="item.value" :label="item.label"
								:value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="部门" prop="departmentId">
						<el-select size="small" v-model="addForm.departmentId" filterable placeholder="请选择部门"
							style="width: 100%;">
							<el-option v-for="item in departments" :key="item.value" :label="item.label"
								:value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="号码" prop="telA">
						<el-input size="small" type="textarea" :rows="5" placeholder="一行一个号码,一次最多添加100个号码"
							v-model="addForm.telA"></el-input>
					</el-form-item>

					<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
						<el-button size="small" @click="closeDialog">取消</el-button>
						<el-button size="small" type="primary" :class="[theme]" @click="submitAdd">确定</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>

			<el-dialog title="分配套餐" v-model="allocationVisible" width="520px" @close="closeAllocationDialog">
				<el-form label-width="120px" :model="allocationForm" ref="allocationForm">
					<el-form-item label="套餐" prop="setMealId">
						<el-radio-group v-model="allocationForm.setMealId">
							<el-radio v-for="item in allocationList" :key="item.id" :label="item.id" :value="item.id"
								style="margin: 18px 10px;float: left;">
								<span v-if="item.type==1">套餐{{ item.minutes }}分钟</span>
								<span v-if="item.type==2">加量包{{ item.minutes }}分钟</span>
							</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
						<el-button size="small" @click="closeAllocationDialog">取消</el-button>
						<el-button size="small" type="primary" :class="[theme]" @click="allocationBtn">确定</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>
			<el-dialog title="购买套餐" v-model="purchaseVisible" width="520px" @close="closePurchaseDialog">
				<el-form label-width="120px" :model="purchaseForm" ref="purchaseForm">
					<el-form-item label="购买号码" prop="telA">
						<el-input placeholder="请输入号码" v-model='purchaseForm.telA' disabled></el-input>
					</el-form-item>
					<el-form-item label="套餐" prop="setMealId">
						<el-radio-group v-model="purchaseForm.setMealId">
							<el-radio v-for="item in allocationList" :key="item.id" :label="item.id" :value="item.id"
								style="margin: 18px 10px;float: left;">
								<span v-if="item.type==1">套餐{{ item.minutes }}分钟</span>
								<span v-if="item.type==2">加量包{{ item.minutes }}分钟</span>
							</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="支付类型" prop="payType">
						<el-radio-group v-model="purchaseForm.payType" @change="typeChange">
							<el-radio v-for="item in payList" :key="item.value" :label="item.value"
								:value="item.value">{{ item.label }}</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="扫码支付" v-show="weixinUrl">
						<div id="qrcode" ref="qrcode" style="position: relative;"></div>
						<div>
							<img src="../assets/img/wxpay.png" alt=""
								style="position: absolute;top: 46px;left:44px;width: 42px;height: 42px;" />
							<span style="font-size: 18px;color: #000;">金额：{{ total_fee / 100 }}元</span>
						</div>
					</el-form-item>
					<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
						<el-button size="small" @click="closePurchaseDialog">取消</el-button>
						<el-button size="small" type="primary" :class="[theme]"
							@click="submitTelABuyMinutes">确定</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import {
		telAList,
		telAAdd,
		callBackSetMealList,
		administratorAddPackage,
		telABuyMinutes,
		telACheckPay
	} from '../api/setMeal.js';
	import {
		fetchDefaultCompanies,
		fetchCompanyByAgentId
	} from '../api/companyIndex';
	import {
		querySubAgent
	} from '../api/serialIndex.js';
	import {
		fetchAgent
	} from '../api/agentIndex';
	import {
		fetchDepartment
	} from '../api/index.js';
	import qs from 'qs';
	import QRCode from 'qrcodejs2';
	export default {
		name: 'privateNumber',
		data() {
			return {
				theme: localStorage.getItem('theme'),
				query: {
					companyId: '',
					userId: localStorage.getItem('user'),
					phone: '',
					consume: '',
					pageIndex: 1,
					pageSize: 30,
					subAgentId: ''
				},
				tableData: [],
				pageTotal: 0,
				agents: [],
				companys: [],
				isAdmin: false,
				isAgent: false,
				isSubAgent: false,
				addVisible: false,
				addForm: {
					telA: ""
				},
				allocationForm: {},
				allocationVisible: false,
				allocationList: [],
				telAId: '',
				purchaseVisible: false,
				purchaseForm: {},
				out_trade_no: '',
				qr: '',
				payList: [{
						value: 'wxpay',
						label: '微信支付'
					},
					{
						value: 'alipay',
						label: '支付宝支付'
					}
				],
				weixinUrl: false,
				total_fee: '',
				clearInterval: null,
				i: 0
			};
		},

		created() {
			this.role = localStorage.getItem('ms_role');
			this.subAgent = localStorage.getItem('subAgent');
			if (this.role === 'agent') {
				this.isAgent = true;
				this.getData()
				if (this.subAgent == 'true') {
					this.isSubAgent = true;
					this.getquerySubAgent();
				} else {
					this.isSubAgent = false;
				}
			}
			if (this.role === 'subAgent') {
				this.isAgent = true;
			}
			if (this.role === 'admin') {
				this.isAdmin = true;
			}
			if (this.role === 'company') {
				this.getData()
			}

			this.init();
		},
		methods: {
			getfetchDefaultCompanies() {
				this.query.companyId = '';
				let data = {
					currentUserId: localStorage.getItem('user'),
					subAgentId: this.query.subAgentId
				};
				fetchDefaultCompanies(data).then(res => {
					this.companys = res.data;
				});
			},
			getquerySubAgent() {
				let data = {
					agentId: this.query.agentId,
					userId: localStorage.getItem('user')
				};
				querySubAgent(data).then(res => {
					if (res.code == 200) {
						this.subAgentList = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			tableRowClassName({
				rowIndex
			}) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},
			init() {
				fetchAgent(localStorage.getItem('user')).then(res => {
					this.agents = res.data;
				});
				if (this.subAgent == true) {
					this.isSubAgent = true;
					this.getquerySubAgent();
				}
				this.getfetchDefaultCompanies();
			},
			agentChange(agentId) {
				this.query.agentId = agentId;
				this.query.companyId = '';
				//代理商下拉
				if (agentId) {
					let data = {
						subAgent: JSON.parse(localStorage.getItem('subAgent').toLowerCase()),
						agentId: agentId
					};
					fetchCompanyByAgentId(data).then(res => {
						this.companys = res.data;
					});
				}
			},
			companyChange(companyId) {
				this.addForm.companyId = companyId;
				this.query.companyId = companyId;
				let data = {
					companyId: this.addForm.companyId,
					currentUserId: localStorage.getItem('user')
				};
				fetchDepartment(data).then(res => {
					this.departments = res.data;
				});
			},

			getData() {
				let data = {
					userId: localStorage.getItem('user'),
					agentId: this.query.agentId,
					companyId: this.query.companyId,
					pageIndex: this.query.pageIndex,
					pageSize: this.query.pageSize,
				};
				telAList(data).then(res => {
					if (res.code == 200) {
						this.tableData = res.data.list;
						this.pageTotal = res.data.total;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			handleAdd() {
				this.addVisible = true;
				this.addForm = {};
			},

			submitAdd() {
				let data = {
					userId: localStorage.getItem('user'),
					companyId: this.addForm.companyId,
					departmentId: this.addForm.departmentId,
					telA: this.addForm.telA,
				};
				telAAdd(data).then(res => {
					if (res.code == 200) {
						this.$message.success('添加成功');
						this.query.agentId = this.addForm.agentId;
						this.query.companyId = this.addForm.companyId;
						this.getData();
						this.addForm = {};
					} else {
						this.$message.error(res.message);
					}
					this.addVisible = false;
				});
			},

			closeDialog() {
				this.addVisible = false;
				this.addForm.phones = '';
			},
			handleEdit(row) {
				this.telAId = row.id;
				this.getcallBackSetMealList();
				this.allocationVisible = true;
			},
			getcallBackSetMealList() {
				callBackSetMealList().then(res => {
					if (res.code == 200) {
						this.allocationList = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			allocationBtn() {
				let data = {
					userId: localStorage.getItem('user'),
					setMealId: this.allocationForm.setMealId,
					telAId: this.telAId
				}
				administratorAddPackage(data).then(res => {
					if (res.code == 200) {
						this.allocationVisible = false;
						this.allocationForm.setMealId = '';
						this.$message.success(res.data);
						this.getData();
					} else {
						this.$message.error(res.message);
					}
				});
			},
			closeAllocationDialog() {
				this.allocationVisible = false;
				this.allocationForm.setMealId = '';
			},
			handlePurchase(row) {
				this.purchaseVisible = true;
				this.purchaseForm.telA = row.telA;
				this.purchaseForm.setMealId = row.id;
				this.getcallBackSetMealList();
			},
			closePurchaseDialog() {
				this.purchaseVisible = false;
			},
			submitTelABuyMinutes() {
				this.$refs.qrcode.innerHTML = '';
				let data = {
					userId: localStorage.getItem('user'),
					telA: this.purchaseForm.telA,
					setMealId: this.purchaseForm.setMealId,
					payType: this.purchaseForm.payType,
					client: 'web'
				};
				telABuyMinutes(data).then(res => {
					if (res.code == 200) {
						this.out_trade_no = res.data.out_trade_no;
						this.clearInterval = setInterval(res => {
							this.telACheckPay(this.out_trade_no);
							this.i = this.i + 1;
							if (this.i > 30) {
								clearInterval(this.clearInterval);
								this.$message.error('支付超时');
								this.weixinUrl = false;
								this.$refs.qrcode.innerHTML = '';
								this.purchaseForm.payType = '';
								this.i = 0;
							}
						}, 2000);

						if (this.purchaseForm.payType == 'wxpay') {
							this.weixinUrl = true;
							this.url = res.data.url;
							this.total_fee = res.data.total_fee;
							this.qr = new QRCode('qrcode', {
								width: 130,
								height: 130, // 高度
								text: this.url, // 二维码内容
								colorDark: '#000000',
								colorLight: '#ffffff'
							});
						} else if (this.purchaseForm.payType == 'alipay') {
							let divForm = document.getElementsByTagName('divform');
							if (divForm.length) {
								document.body.removeChild(divForm[0]);
							}
							const div = document.createElement('divform');
							div.innerHTML = res.data.orderForm;
							document.body.appendChild(div);
							document.forms[1].setAttribute('target', '_blank');
							document.forms[1].submit();
						}

					} else {
						this.$message.error(res.message);
					}
				});
			},
			telACheckPay() {
				let data = {
					orderId: this.out_trade_no
				};
				telACheckPay(data).then(res => {
					if (res.code == 200) {
						if (res.data == true) {
							this.$message.success('支付成功');
							this.$refs.qrcode.innerHTML = '';
							this.payUrl = false;
							this.$router.go(0);
							clearInterval(this.clearInterval);
						}
					} else {
						this.$message.error(res.message);
					}
				});
			},
			typeChange(e) {
				if (e == 'alipay') {
					this.weixinUrl = false;
					this.$refs.qrcode.innerHTML = '';
				}
				clearInterval(this.clearInterval);
			},
		}
	};
</script>

<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}
</style>