<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-input size="small" v-model="query.phone" clearable maxlength="12" placeholder="号码"
					style="width: 180px;" class="handle-input mr10"></el-input>

				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search"
					@click="getReturnPremiumRecord" class="m_t_10">搜索</el-button>
			</div>
			<el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header"
				:row-style="{ height: '20px' }" :cell-style="{ padding: '6px' }"
				:header-cell-style="{ 'text-align': 'center' }" :row-class-name="tableRowClassName"
				@selection-change="handleSelectionChange">
				<el-table-column prop="phone" label="号码" align="center"></el-table-column>
				<el-table-column prop="amount" label="金额(元)" align="center"></el-table-column>

				<el-table-column prop="time" label="时间" align="center"></el-table-column>

				<el-table-column label="流水号" align="center">
					<template #default="scope">
						<span style="cursor: pointer;">
							<span @click="copyUrl(scope.row.uid)">{{ scope.row.uid }}</span>
						</span>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination">
				<el-pagination background layout="total, sizes, prev, pager, next, jumper"
					:current-page="query.pageIndex" :page-sizes="[10, 30, 50, 100, 300]" :page-size="query.pageSize"
					:total="total" @size-change="handleSizeChange" @current-change="handlePageChange"></el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		ReturnPremiumRecord
	} from '../api/dijia.js';
	export default {
		name: 'djRebateList',
		data() {
			return {
				theme: localStorage.getItem('theme'),
				theme3: localStorage.getItem('theme3'),
				query: {
					phone: '',
					userId: localStorage.getItem('user'),
					pageIndex: 1,
					pageSize: 10
				},
				total: 0,
				tableData: []
			};
		},

		created() {
			this.getReturnPremiumRecord();
		},

		methods: {
			tableRowClassName({
				rowIndex
			}) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},

			getReturnPremiumRecord() {
				ReturnPremiumRecord(this.query).then(res => {
					if (res.code == 200) {
						this.tableData = res.data.list;
						this.total = res.data.total;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			handleSizeChange(val) {
				this.query.pageSize = val;
				this.getReturnPremiumRecord();
			},
			handlePageChange(val) {
				this.query.pageIndex = val;
				this.getReturnPremiumRecord();
			}
		}
	};
</script>

<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}

	/* a:hover {
	background: #66b1ff;
} */
	.handle-select {
		width: 240px;
	}

	.table {
		width: 100%;
		font-size: 12px;
	}

	.red {
		color: #f56c6c;
	}

	.mr10 {
		margin-right: 10px;
	}

	.mr100 {
		position: absolute;
		right: 100px;
	}

	.myRight {
		position: absolute;
		right: 10px;
		bottom: 10px;
	}

	.handle-input {
		width: 200px;
		display: inline-block;
	}

	.recharge-box .el-radio__input {
		display: none;
	}

	.recharge-box .el-radio.is-bordered {
		padding: 8px 14px 0 5px;
		border-radius: 4px;
		border: 1px solid #dcdfe6;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		height: 30px;
	}

	.table-box {
		width: 1100px;
		border: 1px solid #ebeef5;
		display: flex;
		flex-direction: row;
	}

	.user-info-box {
		/* border: 1px solid #ebeef5; */

		width: 300px;
	}

	.user-info-tit {
		background: #fafafa;
		color: #000;
		font-weight: 600;
		text-align: center;
		height: 34px;
		line-height: 34px;
		border-right: 1px solid #ebeef5;
		border-bottom: 1px solid #ebeef5;
	}

	.info-box {
		display: flex;
		flex-direction: row;
		height: 34px;
		line-height: 34px;
		border-bottom: 1px solid #ebeef5;
		border-right: 1px solid #ebeef5;
	}

	.info-name {
		background: #fafafa;
		border-right: 1px solid #ebeef5;
		width: 120px;
		padding-left: 10px;
		font-weight: 600;
	}

	.user-info-box .info-box .info {
		padding-left: 10px;
		color: green;
	}

	.down {
		border: 1px solid #dcdfe6;
		background: #4f7afd;
		color: #ffffff;
		transition: 0.1s;
		font-weight: 500;
		padding: 6px 12px;
		font-size: 12px;
		border-radius: 4px;

		display: inline-block;
	}

	.grid-content {
		display: flex;
		align-items: center;
		height: 100px;
	}

	/deep/ .el-col-16 {
		flex: 0 0 59.9%;
	}

	.grid-cont-right {
		flex: 1;
		text-align: center;
		font-size: 20px;
		font-weight: bold;
		color: #999;
	}

	.grid-num {
		font-size: 30px;
		font-weight: bold;
	}

	.grid-con-icon {
		font-size: 50px;
		width: 100px;
		height: 100px;
		text-align: center;
		line-height: 100px;
		color: #fff;
	}

	.grid-con-1 .grid-con-icon {
		background: linear-gradient(#ffb199, #ff0844);
	}

	.new {
		color: #b5b4b4;
		font-size: 12px;
	}

	.grid-con-1 .grid-num {
		color: #4f7afd;
	}

	.grid-con-2 .grid-con-icon {
		background: linear-gradient(#48c6ef, #6f86d6);
	}

	.grid-con-2 .grid-num {
		color: #4f7afd;
	}

	.grid-con-3 .grid-con-icon {
		background: linear-gradient(#e5b2ca, #7028e4);
	}

	.grid-con-3 .grid-num {
		color: #4f7afd;
	}

	.grid-con-4 .grid-con-icon {
		background: linear-gradient(#00c6fb, #005bea);
	}

	.grid-con-4 .grid-num {
		color: #4f7afd;
	}

	.grid-con-5 .grid-con-icon {
		background: linear-gradient(#89f7fe, #66a6ff);
	}

	.grid-con-5 .grid-num {
		color: #4f7afd;
	}
</style>