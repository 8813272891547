<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-select size="small" @change="agentChange" v-model="query.agentId" filterable clearable
					placeholder="请选择代理商" class="handle-select mb10 m_r_10" v-if="isAdmin">
					<el-option v-for="item in agents" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-select size="small" v-if="isSubAgent" v-model="query.subAgentId" @change="getfetchDefaultCompanies"
					filterable clearable placeholder="子代理" class="handle-select m_r_10" style="width: 260px;">
					<el-option v-for="item in subAgentList" :key="item.id" :label="item.name"
						:value="item.id"></el-option>
				</el-select>
				<el-select size="small" v-model="query.companyId" @change="getData()" filterable clearable
					placeholder="请选择企业" class="handle-select m_r_10">
					<el-option v-for="item in companys" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>

				<el-button size="small" type="primary" :class="[theme]" @click="getData()">搜索</el-button>
				<el-button size="small" type="primary" :class="[theme]" @click="handleAdd">添加</el-button>
			</div>
			<el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header"
				:row-style="{ height: '20px' }" :cell-style="{ padding: '6px' }"
				:header-cell-style="{ 'text-align': 'center' }" :row-class-name="tableRowClassName"
				@selection-change="handleSelectionChange">
				<el-table-column prop="companyName" label="企业名称" align="center" min-width="160"></el-table-column>
				<el-table-column prop="phone" label="手机号码" align="center" width="130"></el-table-column>
				<el-table-column prop="city" label="城市" align="center"></el-table-column>
				<el-table-column prop="setMeal" label="套餐" align="center">
					<template #default="scope">
						<span v-if="scope.row.setMeal == true">已购买</span>
						<span v-else>未购买</span>
					</template>
				</el-table-column>

				<el-table-column prop="telA" label="私人号码" align="center" width="130"></el-table-column>
				<el-table-column prop="balance" label="余额" align="center"></el-table-column>
				<el-table-column prop="monthUseMinutes" label="月份使用分钟数" align="center"></el-table-column>
				<el-table-column prop="totalMinutes" label="总分钟" align="center"></el-table-column>
				<el-table-column prop="setMealS" label="套餐包" align="center" width="140"></el-table-column>
				<el-table-column prop="invalidateDate" label="到期时间" align="center" width="175"></el-table-column>
				<el-table-column prop="untie" label="可解绑次数" align="center" width="100"></el-table-column>
				<el-table-column label="操作" width="280" align="center">
					<template #default="scope">
						<el-button size="small" type="text" v-if="scope.row.setMeal == false"
							@click="handleEdit(scope.row)">购买套餐</el-button>
						<el-button size="small" type="text" v-if="isAdmin || isAgent"
							@click="getAddDuration(scope.row)">坐席续费</el-button>
						<el-button size="small" type="text" v-if="scope.row.setMeal == true && scope.row.telA == ''"
							@click="binding(scope.row)">绑定</el-button>
						<el-button size="small" type="text" v-if="scope.row.telA && scope.row.untie == !0"
							@click="axbUnbind(scope.row)">解绑</el-button>
						<el-button size="small" type="text" icon="el-icon-delete" style="color: #f56c6c;"
							@click="handleDelete(scope.$index, scope.row.id)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination">
				<el-pagination background layout="total, sizes, prev, pager, next, jumper"
					:current-page="query.pageIndex" :page-sizes="[10, 30, 90, 200]" :page-size="query.pageSize"
					:total="pageTotal" @size-change="handleSizeChange"
					@current-change="handlePageChange"></el-pagination>
			</div>
		</div>
		<el-dialog :title="title" v-model="purchaseVisible" width="520px" @close="closeDialog">
			<el-form label-width="120px" :model="purchaseForm" ref="editForm">
				<el-form-item label="号码" prop="phone"><el-input size="small" v-model="purchaseForm.phone"
						:disabled="disabled"></el-input></el-form-item>
				<el-form-item label="套餐选择" prop="price">
					<el-select size="small" v-model="purchaseForm.setMealId" filterable clearable placeholder="请选择套餐">
						<el-option v-for="item in setMealListData" :key="item.id"
							:label="'移动套餐' + item.price + '元' + '-' + item.minutes + '分钟'" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="支付类型" prop="type">
					<el-radio-group v-model="purchaseForm.type" @change="typeChange">
						<el-radio v-for="item in payList" :key="item.value" :label="item.value"
							:value="item.value">{{ item.label }}</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="扫码支付" v-show="weixinUrl">
					<div id="qrcode" ref="qrcode" style="position: relative;"></div>
					<div>
						<img src="../assets/img/wxpay.png" alt=""
							style="position: absolute;top: 46px;left:44px;width: 42px;height: 42px;" />
						<span style="font-size: 18px;color: #000;">金额：{{ total_fee / 100 }}元</span>
					</div>
				</el-form-item>
				<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
					<el-button size="small" @click="closeDialog">取消</el-button>
					<el-button size="small" type="primary" :class="[theme]"
						@click="submitChoosePackage">确认购买</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-dialog title="绑定" v-model="bindingVisible" width="450px" @close="closeDialogBinding"
			style="position: relative;left: 20%;">
			<el-form label-width="120px" :model="bindingForm" ref="bindingForm">
				<el-form-item label="X号码" prop="phone"><el-input size="small" v-model="bindingForm.phone"
						:disabled="disabled"></el-input></el-form-item>
				<el-form-item label="实体号码" prop="telA"><el-input size="small"
						v-model="bindingForm.telA"></el-input></el-form-item>
				<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
					<el-button size="small" @click="closeDialogBinding">取消</el-button>
					<el-button size="small" type="primary" :class="[theme]" @click="submitBinding">绑定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-dialog title="坐席续费" v-model="addDurationVisible" width="400px" @close="closeDialogaddDuration">
			<el-form label-width="120px" :model="addDurationForm" ref="editForm">
				<el-form-item label="续期时间" prop="month" v-if="isAdmin || isAgent">
					<el-select size="small" v-model="addDurationForm.month" filterable clearable placeholder="请设置有效期">
						<el-option key="1" label="一个月" value="1"></el-option>
						<el-option key="2" label="两个月" value="2"></el-option>
						<el-option key="3" label="三个月" value="3"></el-option>
						<el-option key="6" label="六个月" value="6"></el-option>
						<el-option key="12" label="12个月" value="12"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="支付类型" prop="type" v-if="isAgent">
					<el-radio-group v-model="addDurationForm.type" @change="typeChange">
						<el-radio v-for="item in payList" :key="item.value" :label="item.value"
							:value="item.value">{{ item.label }}</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="扫码支付" v-show="weixinUrl">
					<div id="qrcode" ref="qrcode" style="position: relative;"></div>
					<div>
						<img src="../assets/img/wxpay.png" alt=""
							style="position: absolute;top: 46px;left:44px;width: 42px;height: 42px;" />
						<span style="font-size: 18px;color: #000;">金额：{{ total_fee / 100 }}元</span>
					</div>
				</el-form-item>
				<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
					<el-button size="small" @click="closeDialogaddDuration">取消</el-button>
					<el-button size="small" type="primary" :class="[theme]" @click="submitAddDuration">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	import {
		inventoryFindAssigned,
		setMealList,
		inventoryChoosePackage,
		axbBind,
		axbUnbind,
		inventoryCheckPay,
		addDuration
	} from '../api/setMeal.js';
	import { fetchDefaultCompanies, fetchCompanyByAgentId } from '../api/companyIndex';
	import { fetchAgent } from '../api/agentIndex';
	import { querySubAgent } from '../api/serialIndex.js';
	import qs from 'qs';
	import QRCode from 'qrcodejs2';
	export default {
		name: 'purchaseList',
		data() {
			return {
				theme: localStorage.getItem('theme'),
				query: {
					companyId: '',
					userId: localStorage.getItem('user'),
					pageIndex: 1,
					pageSize: 30,
					subAgentId: ''
				},
				title: '购买套餐',
				tableData: [],
				pageTotal: 0,
				agents: [],
				companys: [],
				purchaseForm: {
					phone: '',
					setMealId: ''
				},
				disabled: true,
				bindingVisible: false,
				purchaseVisible: false,
				addDurationVisible: false,
				setMealListData: [],
				weixinUrl: '',
				url: '',
				total_fee: '',
				out_trade_no: '',
				qr: '',
				payList: [{
						value: 'wxpay',
						label: '微信支付'
					},
					{
						value: 'alipay',
						label: '支付宝支付'
					}
				],
				clearInterval: null,
				isAdmin: false,
				isAgent: false,
				isSubAgent: false,
				addDurationForm: {
					ids: '',
					month: ''
				},
				bindingForm: {}
			};
		},

		created() {
			this.role = localStorage.getItem('ms_role');
			this.subAgent = localStorage.getItem('subAgent');
			if (this.role === 'admin') {
				this.isAdmin = true;
				this.isAgent = true;
			}
			if (this.role === 'agent') {
				this.isAgent = true;
				if (this.subAgent == 'true') {
					this.isSubAgent = true;
					this.getquerySubAgent();
				} else {
					this.isSubAgent = false;
				}
				this.getData();
			}
			if (this.role === 'subAgent') {
				this.isAgent = true;
			}
			this.init();
		},
		methods: {
			getfetchDefaultCompanies() {
				this.query.companyId = '';
				let data = {
					currentUserId: localStorage.getItem('user'),
					subAgentId: this.query.subAgentId
				};
				fetchDefaultCompanies(data).then(res => {
					this.companys = res.data;
				});
			},
			getquerySubAgent() {
				let data = {
					agentId: this.query.agentId,
					userId: localStorage.getItem('user')
				};
				querySubAgent(data).then(res => {
					if (res.code == 200) {
						this.subAgentList = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			tableRowClassName({ rowIndex }) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},
			init() {
				fetchAgent(localStorage.getItem('user')).then(res => {
					this.agents = res.data;
				});
				if (this.subAgent == true) {
					this.isSubAgent = true;
					this.getquerySubAgent();
				}
				this.getfetchDefaultCompanies();
			},
			agentChange(agentId) {
				this.query.agentId = agentId;
				this.query.companyId = '';
				//代理商下拉
				if (agentId) {
					let data = {
						subAgent: JSON.parse(localStorage.getItem('subAgent').toLowerCase()),
						agentId: agentId
					};
					fetchCompanyByAgentId(data).then(res => {
						this.companys = res.data;
					});
				}
			},

			getData() {
				let data = {
					agentId: this.query.agentId,
					companyId: this.query.companyId,
					phone: this.query.phone,
					userId: localStorage.getItem('user'),
					pageIndex: this.query.pageIndex,
					pageSize: this.query.pageSize
				};
				inventoryFindAssigned(data).then(res => {
					if (res.code == 200) {
						this.tableData = res.data.list;
						this.pageTotal = res.data.total;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			handleAdd() {
				this.disabled = false;
				this.purchaseVisible = true;
				this.purchaseForm = {};
			},
			handleEdit(row) {
				this.purchaseVisible = true;
				this.purchaseForm = row;
				setMealList().then(res => {
					if (res.code == 200) {
						res.data.forEach((i, index) => {
							if (i.type == 1) {
								this.setMealListData.push(i);
							}
						});
					} else {
						this.$message.error(res.message);
					}
				});
			},
			submitChoosePackage() {
				this.$refs.qrcode.innerHTML = '';
				let data = {
					userId: localStorage.getItem('user'),
					ids: this.purchaseForm.phone,
					setMealId: this.purchaseForm.setMealId,
					type: this.purchaseForm.type
				};
				inventoryChoosePackage(data).then(res => {
					if (res.code == 200) {
						this.out_trade_no = res.data.out_trade_no;
						if (this.purchaseForm.type == 'wxpay') {
							this.weixinUrl = true;
							this.url = res.data.url;
							this.total_fee = res.data.total_fee;
							this.qr = new QRCode('qrcode', {
								width: 130,
								height: 130, // 高度
								text: this.url, // 二维码内容
								colorDark: '#000000',
								colorLight: '#ffffff'
							});
						} else if (this.purchaseForm.type == 'alipay') {
							let divForm = document.getElementsByTagName('divform');
							if (divForm.length) {
								document.body.removeChild(divForm[0]);
							}
							const div = document.createElement('divform');
							div.innerHTML = res.data.orderForm;
							document.body.appendChild(div);
							document.forms[1].setAttribute('target', '_blank');
							document.forms[1].submit();
						}
						this.clearInterval = setInterval(res => {
							this.getinventoryCheckPay(this.out_trade_no);
						}, 2000);
					} else {
						this.$message.error(res.message);
					}
				});
			},
			typeChange(e) {
				if (e == 'alipay') {
					this.weixinUrl = false;
					this.$refs.qrcode.innerHTML = '';
				}
				clearInterval(this.clearInterval);
			},
			getinventoryCheckPay(orderId) {
				let data = {
					orderId: this.out_trade_no
				};
				inventoryCheckPay(data).then(res => {
					if (res.code == 200) {
						if (res.data == true) {
							this.$message.success('支付成功');
							this.$refs.qrcode.innerHTML = '';
							this.payUrl = false;
							this.$router.go(0);
							clearInterval(this.clearInterval);
						}
					} else {
						this.$message.error(res.message);
					}
				});
			},
			submitUpload() {
				let data = {
					userId: localStorage.getItem('user'),
					price: this.purchaseForm.price,
					id: this.purchaseForm.id
				};
				setMealUpdate(data).then(res => {
					if (res.code == 200) {
						this.$message.success('修改成功');
						this.purchaseForm = {};
						this.getData();
					} else {
						this.$message.error(res.message);
					}
					this.purchaseVisible = false;
				});
			},
			closeDialog() {
				this.purchaseVisible = false;
				this.weixinUrl = false;
				this.$refs.qrcode.innerHTML = '';
				clearInterval(this.clearInterval);
				this.purchaseForm.phones = '';
			},
			// 删除操作
			handleDelete(index, id) {
				// 二次确认删除
				this.$confirm('确定要删除吗？', '提示', {
						type: 'warning'
					})
					.then(() => {
						let data = {
							id: id
						};
						setMealDelete(data).then(res => {
							if (res.code == 200) {
								this.$message.success('删除成功');
								this.getData();
							} else {
								this.$message.error(res.message);
							}
						});
					})
					.catch(() => {});
			},
			binding(row) {
				this.bindingVisible = true;
				this.bindingForm.phone = row.phone;
				this.bindingForm.id = row.id;
			},
			closeDialogBinding() {
				this.bindingVisible = false;
				this.bindingForm = {};
			},
			submitBinding() {
				let data = {
					telA: this.bindingForm.telA,
					id: this.bindingForm.id
				};
				axbBind(data).then(res => {
					if (res.code == 200) {
						this.$message.success(res.data);
						this.getData();
						this.bindingVisible = false;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			axbUnbind(row) {
				this.$confirm('确定要解绑吗？', '提示', {
						type: 'warning'
					})
					.then(() => {
						let data = {
							telX: row.phone
						};
						axbUnbind(data).then(res => {
							if (res.code == 200) {
								this.$message.success(res.data);
								this.getData();
							} else {
								this.$message.error(res.message);
							}
						});
					})
					.catch(() => {});
			},
			getAddDuration(row) {
				this.addDurationVisible = true;
				this.addDurationForm = row;
			},
			closeDialogaddDuration() {
				this.addDurationVisible = false;
				this.addDurationForm.month = '';
			},
			submitAddDuration() {
				let data = {
					userId: localStorage.getItem('user'),
					ids: this.addDurationForm.id,
					month: this.addDurationForm.month,
					type: this.addDurationForm.type
				};
				addDuration(data).then(res => {
					if (res.code == 200) {
						if (this.isAdmin) {
							this.$message.success('修改成功');
							this.addDurationVisible = false;
							this.getData();
						} else {
							this.out_trade_no = res.data.out_trade_no;
							if (this.addDurationForm.type == 'wxpay') {
								this.weixinUrl = true;
								this.url = res.data.url;
								this.total_fee = res.data.total_fee;
								this.qr = new QRCode('qrcode', {
									width: 130,
									height: 130, // 高度
									text: this.url, // 二维码内容
									colorDark: '#000000',
									colorLight: '#ffffff'
								});
							} else if (this.addDurationForm.type == 'alipay') {
								let divForm = document.getElementsByTagName('divform');
								if (divForm.length) {
									document.body.removeChild(divForm[0]);
								}
								const div = document.createElement('divform');
								div.innerHTML = res.data.orderForm;
								document.body.appendChild(div);
								document.forms[1].setAttribute('target', '_blank');
								document.forms[1].submit();
							}
							this.clearInterval = setInterval(res => {
								this.getinventoryCheckPay();
							}, 2000);
						}
					} else {
						this.$message.error(res.message);
					}
				});
			}
		}
	};
</script>

<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}
</style>