<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-input size="small" v-model="query.orderId" clearable placeholder="流水编号搜索" class="handle-input mr10"
					style="width: 180px;"></el-input>

				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search"
					style="margin-left: 10px;margin-top: 10px;" @click="getData">搜索</el-button>
			</div>

			<el-table :data="tableData" border class="table" ref="multipleTable" v-loading="loading"
				header-cell-class-name="table-header" :row-style="{ height: '20px' }"
				:header-cell-style="{ 'text-align': 'center' }" :cell-style="{ padding: '6px' }"
				:row-class-name="tableRowClassName">
				<el-table-column prop="orderId" label="流水编号" align="center"></el-table-column>
				<el-table-column prop="amount" label="退款金额" align="center"></el-table-column>
				<el-table-column prop="time" label="退款时间" align="center"></el-table-column>
				<el-table-column prop="reason" label="退款原因" align="center"></el-table-column>
			</el-table>
			<div class="pagination">
				<el-pagination background layout="total, prev, pager, next" :current-page="query.pageIndex"
					:page-size="query.pageSize" :total="pageTotal" @current-change="handlePageChange"></el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import { getRefundList } from '../api/cardType';
	export default {
		name: 'refundList',
		data() {
			return {
				theme: localStorage.getItem('theme'),
				query: {
					orderId: '',
					userId: localStorage.getItem('user'),
					pageIndex: 1,
					pageSize: 10
				},
				tableData: [],
				multipleSelection: [],
				pageTotal: 0,
				timeArr: []
			};
		},
		created() {
			this.getData();
		},
		methods: {
			tableRowClassName({ rowIndex }) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},
			getData() {
				this.tableData = [];
				getRefundList(this.query).then(res => {
					if (res.code == 200) {
						this.tableData = res.data.list;
						this.pageTotal = res.data.total;
					} else {
						this.$message.error(res.message);
					}
				});
			},

			// 分页导航
			handlePageChange(val) {
				this.query.pageIndex = val;
				this.getData();
			},
			handleSizeChange(val) {
				this.query.pageSize = val;
				this.getData();
			}
		}
	};
</script>

<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}

	/* a:hover {
	background: #66b1ff;
} */
	.handle-select {
		width: 120px;
	}

	.table {
		width: 100%;
		font-size: 12px;
	}

	.red {
		color: #f56c6c;
	}

	.status_color {
		color: #fff;
		background-color: #ff0000;
		border-radius: 3px;
		display: inline-block;
		width: 60px;
		height: 26px;
		text-align: center;
		line-height: 26px;
		font-size: 12px;
	}

	.red {
		background-color: #ff0000;
	}

	.green {
		background-color: #67c23a;
	}

	.mr10 {
		margin-right: 10px;
	}

	.handle-input {
		width: 200px;
		display: inline-block;
	}
</style>