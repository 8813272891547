<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-input size="small" v-model="query.userPhone" clearable maxlength="12" placeholder="请输入主叫号码"
					style="width: 180px;" class="handle-input mr10"></el-input>
				<el-input size="small" v-model="query.phoneNumber" clearable maxlength="12" placeholder="请输入被叫号码"
					style="width: 180px;" class="handle-input mr10"></el-input>
				<el-select size="small" v-model="query.type" filterable placeholder="请选择" class="handle-select mr10"
					style="width: 180px">
					<el-option v-for="item in typeList" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-date-picker size="small" type="date" placeholder="选择外呼日期" v-model="query.date1"
					style="width: 200px;margin-right: 10px;" @change="getTime"></el-date-picker>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search" class="m_t_20"
					@click="getverifyLists">搜索</el-button>
			</div>
			<el-table :data="tableData" border class="table m_t_20" header-cell-class-name="table-header"
				:row-style="{ height: '20px' }" :header-cell-style="{ 'text-align': 'center' }"
				:cell-style="{ padding: '6px' }" :row-class-name="tableRowClassName">
				<el-table-column v-if="false" prop="path" align="center"></el-table-column>
				<el-table-column prop="userPhone" label="主叫号码" align="center"></el-table-column>
				<el-table-column prop="phoneNumber" label="被叫号码" align="center"></el-table-column>
				<el-table-column prop="remark" label="说明" align="center">
					<template #default="scope">
						<div v-if="scope.row.remark == '云企通过滤放行'"
							style="background: #40FF70;border-radius: 6px;color: #fff;text-align: center;display: inline-block;padding: 4px 10px;">
							{{ scope.row.remark }}
						</div>
						<div v-else-if="scope.row.remark == null">{{ scope.row.remark }}</div>
						<div v-else
							style="background: #ff0000;border-radius: 6px;color: #fff;text-align: center;display: inline-block;padding:4px 10px;">
							{{ scope.row.remark }}
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="createdAt" label="外呼日期" align="center"></el-table-column>
			</el-table>
			<div class="pagination">
				<el-pagination background layout="total, sizes, prev, pager, next, jumper"
					:current-page="query.pageIndex" :page-sizes="[30, 100, 200, 500]" :page-size="query.pageSize"
					:total="pageTotal" @size-change="handleSizeChange"
					@current-change="handlePageChange"></el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import { getverifyList } from '../api/reportNumber.js';

	export default {
		name: 'outboundCallRecords',
		data() {
			return {
				theme: localStorage.getItem('theme'),
				query: {
					date1: '',

					type: 0,
					pageIndex: 1,
					pageSize: 30
				},
				tableData: [],
				pageTotal: 0,
				time: '',
				typeList: [{
						value: 0,
						label: '全部'
					},
					{
						value: -1,
						label: '拦截'
					},
					{
						value: 1,
						label: '放行'
					}
				]
			};
		},
		created() {
			console.log(this.query.userPhone, 'skdjkjk');
		},
		methods: {
			tableRowClassName({ rowIndex }) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},
			getTime() {
				if (this.query.date1) {
					this.time = this.toDateTimeStr(this.query.date1);
				} else {
					this.time = this.query.date1;
				}
			},
			getverifyLists() {
				let data = {
					time: this.time,
					userId: localStorage.getItem('user'),
					phoneNumber: this.query.phoneNumber,
					type: this.query.type,
					userPhone: this.query.userPhone,
					pageIndex: this.query.pageIndex,
					pageSize: this.query.pageSize
				};
				getverifyList(data).then(res => {
					if (res.code == 200) {
						this.tableData = res.data.list;
						this.pageTotal = res.data.total;
					} else {
						this.$message.error(res.message);
					}
				});
			},

			toDateTimeStr(nS) {
				let date = new Date(nS);
				let YY = date.getFullYear() + '-';
				let MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
				let DD = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
				let hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
				let mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
				let ss = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
				return YY + MM + DD;
			},

			// 分页导航
			handlePageChange(val) {
				this.query.pageIndex = val;
				this.getverifyLists();
			},
			handleSizeChange(val) {
				this.query.pageSize = val;
				this.getverifyLists();
			}
		}
	};
</script>

<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}

	.handle-select {
		width: 120px;
	}

	.table {
		width: 100%;
		font-size: 12px;
	}

	.mr10 {
		width: 400px;
		margin-right: 10px;
	}

	.grid-content {
		display: flex;
		align-items: center;
		height: 100px;
	}

	/deep/ .el-col-16 {
		flex: 0 0 59.9%;
	}

	.grid-cont-right {
		flex: 1;
		text-align: center;
		font-size: 20px;
		font-weight: bold;
		color: #999;
	}

	.grid-num {
		font-size: 30px;
		font-weight: bold;
	}

	.grid-con-icon {
		font-size: 50px;
		width: 100px;
		height: 100px;
		text-align: center;
		line-height: 100px;
		color: #fff;
	}

	.grid-con-1 .grid-con-icon {
		background: linear-gradient(#ffb199, #ff0844);
	}

	.new {
		color: #b5b4b4;
		font-size: 12px;
	}

	.grid-con-1 .grid-num {
		color: #4f7afd;
	}

	.grid-con-2 .grid-con-icon {
		background: linear-gradient(#48c6ef, #6f86d6);
	}

	.grid-con-2 .grid-num {
		color: #4f7afd;
	}

	.grid-con-3 .grid-con-icon {
		background: linear-gradient(#e5b2ca, #7028e4);
	}

	.grid-con-3 .grid-num {
		color: #4f7afd;
	}

	.grid-con-4 .grid-con-icon {
		background: linear-gradient(#00c6fb, #005bea);
	}

	.grid-con-4 .grid-num {
		color: #4f7afd;
	}

	.grid-con-5 .grid-con-icon {
		background: linear-gradient(#89f7fe, #66a6ff);
	}

	.grid-con-5 .grid-num {
		color: #4f7afd;
	}

	.down {
		border: 1px solid #dcdfe6;
		background: #4f7afd;
		color: #ffffff;
		transition: 0.1s;
		font-weight: 500;
		padding: 8px 16px;
		font-size: 12px;
		border-radius: 4px;
		margin-left: 10px;

		display: inline-block;
	}
</style>
<style>
	.validate .el-upload {
		width: 100% !important;
		display: flex !important;
		flex-direction: column !important;
		align-items: center !important;
		justify-content: center !important;
	}

	.validate .btn {
		background-color: #4f7afd;
		margin-top: 20px;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		border: none;
		margin-left: 80%;
	}
</style>