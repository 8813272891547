<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-input size="small" v-model="query.phone" clearable placeholder="请输入手机号"
					style="width: 240px;margin-right: 10px;" class="handle-input mr10 mb10 m_t_10"></el-input>
				<!-- <el-select size="small" v-model="query.city" filterable clearable placeholder="请选择城市" class="handle-select m_r_10">
					<el-option v-for="item in cityList" :key="item.label" :label="item.label" :value="item.label"></el-option>
				</el-select> -->
				<el-select size="small" v-model="query.cardType" filterable clearable placeholder="请选择品牌"
					class="handle-select m_r_10" @change="getSJSZInventoryStatistics">
					<el-option v-for="item in cardType" :key="item.label" :label="item.label"
						:value="item.label"></el-option>
				</el-select>
				<el-select size="small" v-model="query.province" filterable clearable placeholder="请选择省份"
					class="handle-select m_r_10" @change="getSJSZInventoryStatistics">
					<el-option v-for="item in provinceList" :key="item.label" :label="item.label"
						:value="item.label"></el-option>
				</el-select>
				<el-select size="small" v-model="query.cityAlphabet" filterable clearable placeholder="请选择城市的首字母"
					class="handle-select m_r_10" @change="getSJSZInventoryStatistics">
					<el-option v-for="item in cityAlphabetList" :key="item.label" :label="item.label"
						:value="item.label"></el-option>
				</el-select>
				<el-button size="small" type="primary" :class="[theme]"
					@click="getSJSZInventoryStatistics()">搜索</el-button>
				<el-button size="small" type="primary" :class="[theme]" @click="handleAdd">入库</el-button>
				<el-button size="small" type="primary" :class="[theme]" @click="handleOut">出库</el-button>
				<a :href="`${path}SJinventory.xlsx`" class="down" style="margin:10px" download="出库模板.xlsx"
					:class="[theme]">
					<i class="el-icon-download"></i>
					下载模板
				</a>
			</div>
			<div class="city-box">
				<div v-for="item in cityList" :key="item.label"
					style="cursor: pointer;display: flex;flex-direction: row;justify-content: space-between;margin-bottom: 6px;"
					@click="tabCity(item)">
					<span>{{ item.label }}</span>
					<span>
						{{ item.value }}
						<i :class="[theme3]" class="el-icon-shopping-cart-full" style="margin-top: 3px;"></i>
					</span>
				</div>
			</div>
			<div style="width: 100%;height: 20px;clear: both;"></div>
			<el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header"
				:row-style="{ height: '20px' }" :cell-style="{ padding: '6px' }"
				:header-cell-style="{ 'text-align': 'center' }" :row-class-name="tableRowClassName"
				@selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55" align="center"></el-table-column>
				<el-table-column prop="city" label="城市" align="center"></el-table-column>
				<el-table-column prop="cardType" label="品牌" align="center"></el-table-column>
				<el-table-column prop="phone" label="号码" align="center"></el-table-column>
				<el-table-column prop="iccid" label="iccid" align="center"></el-table-column>
				<el-table-column label="操作" width="190" align="center">
					<template #default="scope">
						<el-button size="small" type="text" icon="el-icon-edit">出库</el-button>
						<!-- @click="toOptionalNumber2(scope.row)" -->
						<el-button size="small" type="text" icon="el-icon-delete" style="color: #f56c6c;">删除</el-button>
						<!--  @click="handleDelete(scope.$index, scope.row.id)" -->
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination">
				<el-pagination background layout="total, sizes, prev, pager, next, jumper"
					:current-page="query.pageIndex" :page-sizes="[10, 30, 90, 200]" :page-size="query.pageSize"
					:total="pageTotal" @size-change="handleSizeChange"
					@current-change="handlePageChange"></el-pagination>
			</div>
		</div>
		<el-dialog title="添加" v-model="addVisible" width="520px" @close="closeDialog">
			<el-form label-width="120px" :model="addForm" ref="editForm">
				<el-form-item label="上传文件" prop="minutes" class="customer">
					<el-upload ref="upload" action="/api/SJSZInventory/add" :headers="token" :limit="1"
						:on-success="uploadSuccess" :on-preview="handlePreview" :on-remove="handleRemove"
						:auto-upload="false" :data="uploadData">
						<el-button size="small" type="primary" :class="[theme]">选择文件</el-button>
						<div class="el-upload__tip">只能导入excel文件</div>
					</el-upload>
				</el-form-item>

				<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
					<el-button size="small" @click="closeDialog">取消</el-button>
					<el-button size="small" type="primary" :class="[theme]" @click="submitUpload">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-dialog title="出库" v-model="outVisible" width="520px" @close="closeDialog2">
			<el-form label-width="120px" :model="outForm" ref="editForm">
				<el-form-item label="所属销售">
					<el-select v-model="outForm.salesStaff" filterable allow-create default-first-option
						placeholder="请选择" size="small" style="width: 100%;">
						<el-option v-for="item in salesStaffList" :key="item" :label="item" :value="item"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="收货公司">
					<el-select v-model="outForm.companyName" filterable allow-create default-first-option
						placeholder="请选择" size="small" style="width: 100%;">
						<el-option v-for="item in companyList" :key="item" :label="item" :value="item"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="收货地址"><el-input size="small" type="text" placeholder="收货地址"
						v-model="outForm.address"></el-input></el-form-item>
				<el-form-item label="类型">
					<el-radio-group v-model="outForm.type">
						<el-radio :label="1">新增</el-radio>
						<el-radio :label="2">换卡</el-radio>
						<el-radio :label="3">补卡</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="出库号码"><el-input size="small" type="textarea" :rows="10" placeholder="一行一个号码一行"
						v-model="outForm.phones"></el-input></el-form-item>
				<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
					<el-button size="small" @click="closeDialog2">取消</el-button>
					<el-button size="small" type="primary" :class="[theme]" @click="submitOut">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	import {
		SJSZInventoryList,
		SJSZInventoryCardType,
		SJSZInventorystatistics,
		SJSZInventoryprovince,
		getSalesStaffList,
		getCompanyList,
		SJSZInventoryDeliveryStorage
	} from '../api/setMeal.js';
	import { fetchDefaultCompanies, fetchCompanyByAgentId } from '../api/companyIndex';
	import { fetchAgent } from '../api/agentIndex';
	import qs from 'qs';
	import QRCode from 'qrcodejs2';
	export default {
		name: 'SJinventory',
		data() {
			return {
				theme: localStorage.getItem('theme'),
				theme3: localStorage.getItem('theme3'),
				query: {
					city: '',
					pageIndex: 1,
					pageSize: 20,
					phone: '',
					province: '',
					cardType: '',
					cityAlphabet: ''
				},
				tableData: [],
				pageTotal: 0,
				cityList: [],
				provinceList: [],
				cardType: [],
				addForm: {
					pminutes: '',
					price: '',
					type: '1'
				},
				addVisible: false,
				optionalVisible: false,
				multipleSelection: [],
				agents: [],
				companys: [],
				optionalForm: {
					agentId: '',
					companyId: '',
					ids: '',
					type: ''
				},
				isAdmin: false,
				isAgent: false,
				role: '',
				weixinUrl: false,
				url: '',
				total_fee: '',
				qr: '',
				payList: [{
						value: 'wxpay',
						label: '微信支付'
					},
					{
						value: 'alipay',
						label: '支付宝支付'
					}
				],
				cityAlphabetList: [{
						value: 'A',
						label: 'A'
					},
					{
						value: 'B',
						label: 'B'
					},
					{
						value: 'C',
						label: 'C'
					},
					{
						value: 'D',
						label: 'D'
					},
					{
						value: 'E',
						label: 'E'
					},
					{
						value: 'F',
						label: 'F'
					},
					{
						value: 'G',
						label: 'G'
					},
					{
						value: 'H',
						label: 'H'
					},
					{
						value: 'I',
						label: 'I'
					},
					{
						value: 'J',
						label: 'J'
					},
					{
						value: 'K',
						label: 'K'
					},
					{
						value: 'L',
						label: 'L'
					},
					{
						value: 'M',
						label: 'M'
					},
					{
						value: 'N',
						label: 'N'
					},
					{
						value: 'O',
						label: 'O'
					},
					{
						value: 'P',
						label: 'P'
					},
					{
						value: 'Q',
						label: 'Q'
					},
					{
						value: 'R',
						label: 'R'
					},
					{
						value: 'S',
						label: 'S'
					},
					{
						value: 'T',
						label: 'T'
					},
					{
						value: 'U',
						label: 'U'
					},
					{
						value: 'V',
						label: 'V'
					},
					{
						value: 'W',
						label: 'W'
					},
					{
						value: 'X',
						label: 'X'
					},
					{
						value: 'Y',
						label: 'Y'
					},
					{
						value: 'Z',
						label: 'Z'
					}
				],
				clearInterval: null,
				out_trade_no: '',
				uploadData: {
					userId: localStorage.getItem('user')
				},
				path: process.env.BASE_URL,
				outVisible: false,
				outForm: {
					type: 1,
					salesStaff: '',
					companyName: ''
				},
				salesStaffList: [],
				companyList: []
			};
		},

		created() {
			this.role = localStorage.getItem('ms_role');

			this.init();
			this.getSJSZInventoryCardType();
			this.getSJSZInventoryStatistics();

			if (this.role === 'admin') {
				this.isAdmin = true;
			}

			if (this.role === 'agent') {
				this.isAgent = true;
			}
		},
		methods: {
			tableRowClassName({ rowIndex }) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},
			init() {
				fetchAgent(localStorage.getItem('user')).then(res => {
					//代理
					this.agents = res.data;
					this.options = res.data;
				});
				let data1 = {
					currentUserId: localStorage.getItem('user')
				};
				fetchDefaultCompanies(data1).then(res => {
					//默认企业
					this.companys = res.data;
				});
			},
			getData() {
				let data = {
					city: this.query.city,
					province: this.query.province,
					phone: this.query.phone,
					cardType: this.query.cardType,
					cityAlphabet: this.query.cityAlphabet,
					pageIndex: this.query.pageIndex,
					pageSize: this.query.pageSize
				};
				SJSZInventoryList(data).then(res => {
					if (res.code == 200) {
						this.tableData = res.data.list;
						this.pageTotal = res.data.total;
						this.$message.success(res.message);
					} else {
						this.$message.error(res.message);
					}
				});
			},

			closeDialog2() {
				clearInterval(this.clearInterval);
				this.optionalVisible = false;
			},
			getSJSZInventoryStatistics() {
				this.query.city = '';
				let data = {
					cardType: this.query.cardType,
					province: this.query.province,
					cityAlphabet: this.query.cityAlphabet
				};
				SJSZInventorystatistics(data).then(res => {
					if (res.code == 200) {
						this.cityList = res.data;
						this.getSJSZInventoryprovince();
					} else {
						this.$message.error(res.message);
					}
				});
			},
			getSJSZInventoryprovince() {
				SJSZInventoryprovince(this.query.cardType).then(res => {
					if (res.code == 200) {
						this.provinceList = res.data;
						this.getData();
					} else {
						this.$message.error(res.message);
					}
				});
			},
			getSJSZInventoryCardType() {
				SJSZInventoryCardType().then(res => {
					if (res.code == 200) {
						this.cardType = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			tabCity(item) {
				this.query.city = item.label;
				this.getData();
			},
			getSalesStaffList() {
				getSalesStaffList().then(res => {
					if (res.code == 200) {
						this.salesStaffList = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			getCompanyList() {
				getCompanyList().then(res => {
					if (res.code == 200) {
						this.companyList = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			handleOut() {
				this.outVisible = true;
				this.getSalesStaffList();
				this.getCompanyList();
			},
			submitOut() {
				let data = {
					address: this.outForm.address,
					type: this.outForm.type,
					phones: this.outForm.phones,
					companyName: this.outForm.companyName,
					salesStaff: this.outForm.salesStaff
				};
				SJSZInventoryDeliveryStorage(data).then(res => {
					if (res.code == 200) {
						this.$message.success(res.data);
						this.outVisible = false;
						this.outForm.address = '';
						this.outForm.type = 1;
						this.outForm.phones = '';
						this.outForm.companyName = '';
						this.outForm.salesStaff = '';
					} else {
						this.$message.error(res.message);
					}
				});
			},
			closeDialog2() {
				this.outVisible = false;
			},
			handleAdd() {
				this.addVisible = true;
				this.addForm = {};
			},
			handleEdit(row) {
				this.optionalVisible = true;
				this.addForm.price = row.price * 100;
				this.addForm.id = row.id;
			},
			submitAdd() {
				let data = {
					userId: localStorage.getItem('user'),
					price: this.addForm.price,
					minutes: this.addForm.minutes,
					type: this.addForm.type
				};
				setMealAdd(data).then(res => {
					if (res.code == 200) {
						this.$message.success('添加成功');
						this.addForm = {};
						this.getData();
					} else {
						this.$message.error(res.message);
					}
					this.addVisible = false;
				});
			},

			closeDialog() {
				this.addVisible = false;
				this.addForm.phones = '';
			},
			// 删除操作
			handleDelete(index, id) {
				// 二次确认删除
				this.$confirm('确定要删除吗？', '提示', {
						type: 'warning'
					})
					.then(() => {
						let data = {
							id: id
						};
						setMealDelete(data).then(res => {
							if (res.code == 200) {
								this.$message.success('删除成功');
								this.getData();
							} else {
								this.$message.error(res.message);
							}
						});
					})
					.catch(() => {});
			},
			// 多选操作
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			toOptionalNumber() {
				for (let i = 0; i < this.multipleSelection.length; i++) {
					this.optionalForm.ids += this.multipleSelection[i].id + ',';
				}
				if (this.optionalForm.ids === '') {
					this.$message.error(`请选择数据`);
					return;
				} else {
					this.optionalVisible = true;
				}
			},
			toOptionalNumber2(row) {
				this.optionalForm.ids = row.id;
				this.optionalVisible = true;
			},
			submitoptionalNumber() {
				this.$refs.qrcode.innerHTML = '';
				let data = {
					userId: localStorage.getItem('user'),
					companyId: this.optionalForm.companyId,
					ids: this.optionalForm.ids,
					type: this.optionalForm.type
				};
				inventoryOptionalNumber(data).then(res => {
					if (res.code == 200) {
						if (this.isAdmin) {
							this.$message.success('划拨成功');
							this.optionalVisible = false;
							this.getData();
						} else {
							this.out_trade_no = res.data.out_trade_no;
							if (this.optionalForm.type == 'wxpay') {
								this.weixinUrl = true;
								this.url = res.data.url;
								this.total_fee = res.data.total_fee;
								this.qr = new QRCode('qrcode', {
									width: 130,
									height: 130, // 高度
									text: this.url, // 二维码内容
									colorDark: '#000000',
									colorLight: '#ffffff'
								});
							} else if (this.optionalForm.type == 'alipay') {
								let divForm = document.getElementsByTagName('divform');
								if (divForm.length) {
									document.body.removeChild(divForm[0]);
								}
								const div = document.createElement('divform');
								div.innerHTML = res.data.orderForm;
								document.body.appendChild(div);
								document.forms[1].setAttribute('target', '_blank');
								document.forms[1].submit();
							}
							this.clearInterval = setInterval(res => {
								this.getinventoryCheckPay();
							}, 2000);
						}
					} else {
						this.$message.error(res.message);
					}
				});
			},
			typeChange(e) {
				if (e == 'alipay') {
					this.weixinUrl = false;
					this.$refs.qrcode.innerHTML = '';
				}
				clearInterval(this.clearInterval);
			},
			getinventoryCheckPay(orderId) {
				let data = {
					orderId: this.out_trade_no
				};
				inventoryCheckPay(data).then(res => {
					if (res.code == 200) {
						if (res.data == true) {
							this.$message.success('支付成功');
							this.$refs.qrcode.innerHTML = '';
							this.payUrl = false;
							this.$router.go(0);
							clearInterval(this.clearInterval);
						}
					} else {
						this.$message.error(res.message);
					}
				});
			},

			closeUpload() {
				this.$refs.upload.clearFiles();
			},
			submitUpload() {
				if (this.$refs.upload.uploadFiles[0]) {
					this.$refs.upload.submit();
				} else {
					this.$message.error('请选择上传文件');
				}
			},

			handleRemove(file, fileList) {
				console.log(file, fileList);
			},
			handlePreview(file) {
				console.log(file);
			},
			//导入上传
			uploadSuccess(success) {
				if (success.code == 200) {
					if (success.data == '导入成功0条数据') {
						this.$message.error(success.data);
					} else {
						this.$message.success(success.data);
						this.getData();
						this.addVisible = false;
					}
				} else {
					this.$message.error(success.message);
				}
			},
			// 分页导航
			handlePageChange(val) {
				this.query.pageIndex = val;
				this.getData();
			},
			handleSizeChange(val) {
				this.query.pageSize = val;
				this.getData();
			}
		}
	};
</script>

<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}

	.city-box {
		margin: 20px 0;
	}

	.city-box div {
		width: 140px;
		float: left;
		margin-right: 20px;
	}

	.city-box div span {
		margin-right: 6px;
	}

	.customer .el-upload {
		display: flex !important;
		flex-direction: column !important;
		align-items: center !important;
		justify-content: center !important;
		width: 100% !important;
		line-height: 50px !important;
	}

	.down {
		border: 1px solid #dcdfe6;
		background: #4f7afd;
		color: #ffffff;
		transition: 0.1s;
		font-weight: 500;
		padding: 6px 12px;
		font-size: 12px;
		border-radius: 4px;

		display: inline-block;
	}
</style>