<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-input size="small" v-model="query.phoneNum" clearable placeholder="请输入手机号码" style="width: 180px;"
					class="handle-input mr10"></el-input>
				<el-input size="small" v-model="query.name" clearable placeholder="请输入客户姓名" style="width: 180px;"
					class="handle-input mr10"></el-input>
				<el-input size="small" v-model="query.idNo" clearable placeholder="请输入身份证号" style="width: 180px;"
					class="handle-input mr10"></el-input>
				<el-date-picker size="small" v-model="timeArr" type="daterange" unlink-panels range-separator="至"
					start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
				<el-select size="small" v-model="query.orderState" filterable clearable placeholder="请选择订单状态"
					class="handle-select" style="width: 180px;margin-left: 10px;">
					<el-option v-for="item in orderStateList" :key="item.value" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search" class="m_l_10"
					@click="handleSearch">搜索</el-button>
			</div>
			<el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header"
				:row-style="{ height: '20px' }" :cell-style="{ padding: '6px' }"
				:header-cell-style="{ 'text-align': 'center' }" :row-class-name="tableRowClassName"
				@selection-change="handleSelectionChange">
				<el-table-column prop="phoneNum" label="手机号码" align="center" width="120"></el-table-column>
				<el-table-column prop="name" label="客户姓名" align="center" width="80"></el-table-column>
				<el-table-column prop="idNo" label="证件号码" align="center" width="150"></el-table-column>

				<el-table-column prop="prdName" label="号码套餐" align="center" width="80"></el-table-column>

				<el-table-column prop="verifyMethod" label="验证方式" align="center" width="90"></el-table-column>
				<el-table-column prop="submitTime" label="提交时间" align="center" width="100">
					<template #default="scope">
						{{ timestampToDateString2(scope.row.submitTime) }}
					</template>
				</el-table-column>
				<el-table-column prop="orderState" label="订单状态" align="center" width="90">
					<template #default="scope">
						<span v-if="scope.row.orderState == 'TR'">已退款</span>
						<span v-if="scope.row.orderState == 'NON_PAY'">未支付</span>
						<span v-if="scope.row.orderState == 'PAY_SUCCESS'">支付成功</span>
						<span v-if="scope.row.orderState == 'PAY_FAILED'">支付失败</span>
						<span v-if="scope.row.orderState == 'TUTU_RECHARGE_SUCCESS'">充值成功</span>
						<span v-if="scope.row.orderState == 'TUTU_RECHARGE_FAILED'">充值失败</span>
					</template>
				</el-table-column>
				<el-table-column prop="money" label="应付金额" align="center" width="100">
					<template #default="scope">
						<span>{{ scope.row.money }}元</span>
					</template>
				</el-table-column>
				<el-table-column prop="phoneBill" label="内含话费" align="center" width="100">
					<template #default="scope">
						<span>{{ scope.row.phoneBill }}元</span>
					</template>
				</el-table-column>
				<el-table-column prop="checkTime" label="审核时间" align="center" width="100">
					<template #default="scope">
						<span v-if="scope.row.checkTime">{{ timestampToDateString2(scope.row.checkTime) }}</span>
					</template>
				</el-table-column>
				<el-table-column prop="checkOpinion" label="审核意见" align="center" width="200"></el-table-column>
				<el-table-column prop="idAddress" label="证件地址" align="center" width="180"></el-table-column>
				<el-table-column prop="source" label="开户来源" align="center" width="80">
					<template #default="scope">
						<span v-if="scope.row.source == 'MOBILE'">移动端</span>
					</template>
				</el-table-column>

				<el-table-column label="操作" width="140" align="center" fixed="right">
					<template #default="scope">
						<el-button size="small" style="cursor: pointer;color: #ff0000;" type="text"
							@click="bigImg(scope.row)">查看图片</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination">
				<el-pagination background layout="total, sizes, prev, pager, next, jumper"
					:current-page="query.pageIndex" :page-sizes="[10, 30, 50, 100]" :page-size="query.pageSize"
					:total="pageTotal" @size-change="handleSizeChange"
					@current-change="handlePageChange"></el-pagination>
			</div>

			<el-dialog v-model="imgVisible" width="1000px">
				<div style="display: flex;flex-direction: row;">
					<div>
						<el-image style="height: 300px; width: 100%;" :src="srcList[0]" fit="contain"
							:preview-src-list="srcList"></el-image>
						<el-image style="height: 300px; width: 100%;" :src="srcList[1]" fit="contain"
							:preview-src-list="srcList"></el-image>
					</div>
					<div><el-image style="height: 600px; width: 100%;" :src="srcList[2]" fit="contain"
							:preview-src-list="srcList"></el-image></div>

				</div>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import {
		openAcctSchedule,
		fastdfsfile2,
		fastdfsfile
	} from '../api/dijia.js';

	export default {
		name: 'accountOpeningProgress',
		data() {
			return {
				theme: localStorage.getItem('theme'),
				query: {
					phoneNum: '',
					name: '',
					idNo: '',
					orderState: '',
					pageIndex: 1,
					pageSize: 10
				},
				tableData: [],
				pageTotal: 0,
				timeArr: [],
				orderStateList: [{
						value: 'TR',
						label: '已退款'
					},
					{
						value: 'NON_PAY',
						label: '未支付'
					},
					{
						value: 'PAY_SUCCESS',
						label: '支付成功'
					},
					{
						value: 'PAY_FAILED',
						label: '支付失败'
					},
					{
						value: 'TUTU_RECHARGE_SUCCESS',
						label: '充值成功'
					},
					{
						value: 'TUTU_RECHARGE_FAILED',
						label: '充值失败'
					}
				],
				imgData: '',
				imgVisible: false,
				srcList: []
			};
		},

		created() {
			this.getopenAcctSchedule();
		},
		methods: {
			tableRowClassName({
				rowIndex
			}) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},
			getopenAcctSchedule() {
				this.loading = true;
				this.tableData = [];
				if (this.timeArr.length > 0) {
					if (this.convertDateStringToTimestamp(this.toDateTimeStr(this.timeArr[0])) == this
						.convertDateStringToTimestamp(this.toDateTimeStr(this.timeArr[1]))) {
						const aaaaa = this.convertDateStringToTimestamp(this.toDateTimeStr(this.timeArr[1])) + 86390000;
						let data = {
							phoneNum: this.query.phoneNum.trim(),
							name: this.query.name,
							idNo: this.query.idNo,
							startSubmitTime: this.convertDateStringToTimestamp(this.toDateTimeStr(this.timeArr[0])),
							endSubmitTime: aaaaa,
							orderState: this.query.orderState,
							pageIndex: this.query.pageIndex,
							pageSize: this.query.pageSize
						};
						openAcctSchedule(data).then(res => {
							this.loading = false;
							if (res.code == 200) {
								this.tableData = res.data.list;
								this.pageTotal = res.data.total;
							} else {
								this.$message.error(res.message);
							}
						});
					} else {
						let data = {
							phoneNum: this.query.phoneNum.trim(),
							name: this.query.name,
							idNo: this.query.idNo,
							startSubmitTime: this.convertDateStringToTimestamp(this.toDateTimeStr(this.timeArr[0])),
							endSubmitTime: this.convertDateStringToTimestamp(this.toDateTimeStr(this.timeArr[1])),
							orderState: this.query.orderState,
							pageIndex: this.query.pageIndex,
							pageSize: this.query.pageSize
						};
						openAcctSchedule(data).then(res => {
							this.loading = false;
							if (res.code == 200) {
								this.tableData = res.data.list;
								this.pageTotal = res.data.total;
							} else {
								this.$message.error(res.message);
							}
						});
					}
				} else {
					let data = {
						phoneNum: this.query.phoneNum.trim(),
						name: this.query.name,
						idNo: this.query.idNo,
						orderState: this.query.orderState,
						pageIndex: this.query.pageIndex,
						pageSize: this.query.pageSize
					};
					openAcctSchedule(data).then(res => {
						this.loading = false;
						if (res.code == 200) {
							this.tableData = res.data.list;
							this.pageTotal = res.data.total;
						} else {
							this.$message.error(res.message);
						}
					});
				}
			},
			// 触发搜索按钮
			handleSearch() {
				this.query.pageIndex = 1;
				this.getopenAcctSchedule();
			},
			handleSizeChange(val) {
				this.query.pageSize = val;
				this.getopenAcctSchedule();
			},
			// 分页导航
			handlePageChange(val) {
				this.query.pageIndex = val;
				this.getopenAcctSchedule();
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			getTime() {
				//时间
				let startTime = new Date(new Date());
				let endTime = new Date();
				this.timeArr.push(startTime);
				this.timeArr.push(endTime);
			},
			toDateTimeStr(date) {
				let y = date.getFullYear();
				let m = date.getMonth() + 1;
				let d = date.getDate();
				if (m < 10) {
					m = '0' + m;
				}
				if (d < 10) {
					d = '0' + d;
				}
				return y + '-' + m + '-' + d;
			},
			convertDateStringToTimestamp(dateString) {
				const parts = dateString.split('-');
				const year = parseInt(parts[0], 10);
				const month = parseInt(parts[1], 10) - 1; // 注意月份是从0开始的
				const day = parseInt(parts[2], 10);
				const date = new Date(year, month, day);
				const timestamp = date.getTime();
				return timestamp;
			},
			timestampToDateString2(timestamp) {
				const date = new Date(timestamp);
				const year = date.getFullYear();
				const month = String(date.getMonth() + 1).padStart(2, '0');
				const day = String(date.getDate()).padStart(2, '0');
				const hours = String(date.getHours()).padStart(2, '0');
				const minutes = String(date.getMinutes()).padStart(2, '0');
				const seconds = String(date.getSeconds()).padStart(2, '0');

				// 拼接年月日时分秒字符串
				return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
			},

			bigImg(row) {
				// this.imgData = '';
				this.srcList = [];
				this.imgVisible = true;
				// this.imgData = row;
				// this.srcList.push(row.idFrontSimUrl);
				// this.srcList.push(row.idBackSimUrl);
				// this.srcList.push(row.idFrontUrl);
				this.getReverseFile(row.idFrontSimUrl)
				this.getReverseFile(row.idBackSimUrl)
				this.getReverseFile(row.idFrontUrl)

			},
			getReverseFile(fileName) {
				let data = {
					fileName: fileName,
					open: true,
				};
				fastdfsfile(data).then(res => {
					const blob = new Blob([res], {
						type: 'image/png'
					});
					const url = URL.createObjectURL(blob);
					this.srcList.push(url);
				});
			},
		}
	};
</script>

<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}

	.handle-select {
		width: 240px;
	}

	.table {
		width: 100%;
		font-size: 12px;
	}

	.red {
		color: #f56c6c;
	}

	.mr10 {
		margin-right: 10px;
	}

	.mr100 {
		position: absolute;
		right: 100px;
	}

	.myRight {
		position: absolute;
		right: 10px;
		bottom: 10px;
	}

	.handle-input {
		width: 200px;
		display: inline-block;
	}

	.recharge-box .el-radio__input {
		display: none;
	}

	.recharge-box .el-radio.is-bordered {
		padding: 8px 14px 0 5px;
		border-radius: 4px;
		border: 1px solid #dcdfe6;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		height: 30px;
	}

	.status_color2 {
		color: #fff;
		background-color: #ff0000;
		border-radius: 3px;
		display: inline-block;
		width: 60px;
		height: 26px;
		text-align: center;
		line-height: 26px;
		font-size: 12px;
	}

	.bgred {
		background-color: #ff0000;
	}

	.bggreen {
		background-color: #67c23a;
	}
</style>