<template>
	<div>
		<div class="container">
			<el-tabs v-model="activeName" @tab-click="handleClick">
				<el-tab-pane label="充值" name="single">
					<div class="handle-box">
						<el-input size="small" v-model="singlequery.phone" clearable placeholder="充值号码"
							style="width: 180px;" class="handle-input mr10"></el-input>
						<el-input size="small" v-model="singlequery.batchNo" clearable maxlength="12" placeholder="批次号"
							style="width: 180px;" class="handle-input mr10"></el-input>
						<el-date-picker size="small" v-model="timeArr" type="daterange" unlink-panels
							range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
						<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search"
							@click="getsnailShowRemittanceInfo" class="m_t_10 m_l_10">搜索</el-button>
						<el-button size="small" type="primary" :class="[theme]" @click="getAdd"
							class="m_t_10">充值</el-button>
						<el-button size="small" type="primary" :class="[theme]" @click="getAPPAdd"
							class="m_t_10">APP充值</el-button>
						<span style="font-weight: 600;line-height: 40px;margin-left: 10px;font-size: 14px;">
							当前可用缴费余额：
							<span style="color: #ff0000;font-size: 14px">{{ balance }}</span>
							兔兔币
						</span>
					</div>

					<el-table :data="singletableData" border class="table" ref="multipleTable"
						header-cell-class-name="table-header" :row-style="{ height: '20px' }"
						:cell-style="{ padding: '6px' }" :header-cell-style="{ 'text-align': 'center' }"
						:row-class-name="tableRowClassName" @selection-change="handleSelectionChange">
						<el-table-column prop="orderNo" label="订单号" align="center" width="300"></el-table-column>
						<el-table-column prop="batchNo" label="批次号" align="center"></el-table-column>
						<el-table-column prop="rechargeNumber" label="充值号码" align="center"
							width="130"></el-table-column>

						<el-table-column prop="money" label="划账金额" align="center"></el-table-column>
						<el-table-column prop="operateType" label="划账类型" align="center">
							<template #default="scope">
								<span v-if="scope.row.operateType == 'DRE'">代理商充值</span>
								<span v-if="scope.row.operateType == 'BRO'">批量回退</span>
								<span v-if="scope.row.operateType == 'DP'">代理商带预存</span>
							</template>
						</el-table-column>
						<el-table-column prop="operateTime" label="提交时间" width="180" align="center">
							<template #default="scope">
								{{ timestampToDateString2(scope.row.operateTime) }}
							</template>
						</el-table-column>
						<el-table-column prop="deductNumber" label="划账号码" align="center" width="130"></el-table-column>
						<el-table-column prop="balance" label="当前余额" align="center" width="130"></el-table-column>
					</el-table>
					<div class="pagination">
						<el-pagination background layout="total, sizes, prev, pager, next, jumper"
							:current-page="singlequery.pageIndex" :page-sizes="[10, 30, 50, 100, 300]"
							:page-size="singlequery.pageSize" :total="singletotal" @size-change="singlehandleSizeChange"
							@current-change="singlehandlePageChange"></el-pagination>
					</div>
				</el-tab-pane>
				<el-tab-pane label="回退" name="batch">
					<div class="handle-box">
						<el-input size="small" v-model="htquery.fileName" clearable placeholder="文件名称"
							style="width: 180px;" class="handle-input mr10"></el-input>
						<el-button size="small" type="primary" :class="[theme]"
							@click="getsnailTopUpRecord">搜索</el-button>
						<el-button size="small" type="primary" :class="[theme]" @click="getaddVisible">回退</el-button>
					</div>
					<el-table :data="batchtableData" border class="table" ref="multipleTable"
						header-cell-class-name="table-header" :row-style="{ height: '20px' }"
						:cell-style="{ padding: '6px' }" :header-cell-style="{ 'text-align': 'center' }"
						:row-class-name="tableRowClassName" @selection-change="handleSelectionChange">
						<el-table-column prop="operNo" label="操作号码" align="center" width="130"></el-table-column>
						<el-table-column prop="operName" label="操作人" align="center" width="130"></el-table-column>
						<el-table-column prop="importFileName" label="导入文件名" align="center"></el-table-column>
						<el-table-column prop="fileUrl" label="下载地址" align="center" width="120">
							<template #default="scope">
								<a :href="scope.row.fileUrl" style="cursor: pointer;color: #ff0000;">
									<i class="el-icon-download"></i>
									下载文件
								</a>
							</template>
						</el-table-column>
						<el-table-column prop="exportFileName" label="下载文件名" align="center"></el-table-column>
						<el-table-column prop="createTime" label="创建时间" align="center">
							<template #default="scope">
								{{ timestampToDateString2(scope.row.createTime) }}
							</template>
						</el-table-column>
						<el-table-column prop="finishTime" label="完成时间" align="center">
							<template #default="scope">
								{{ timestampToDateString2(scope.row.finishTime) }}
							</template>
						</el-table-column>
					</el-table>
					<div class="pagination">
						<el-pagination background layout="total, sizes, prev, pager, next, jumper"
							:current-page="batchquery.pageIndex" :page-sizes="[10, 30, 50, 100, 300]"
							:page-size="batchquery.pageSize" :total="batchpageTotal"
							@size-change="batchhandleSizeChange"
							@current-change="batchhandlePageChange"></el-pagination>
					</div>
				</el-tab-pane>
			</el-tabs>
			<el-dialog title="回退" v-model="addVisible" width="520px" @close="closeDialog">
				<el-form label-width="120px" :model="addForm" ref="addForm">
					<el-form-item label="选择模式">
						<el-radio-group v-model="addForm.type">
							<el-radio label="1">手动输入</el-radio>
							<el-radio label="2">批量模板</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="手机号码" prop="phones" v-if="addForm.type == '1'">
						<el-input size="small" :rows="5" type="textarea" v-model="addForm.phones" auto-complete="off"
							placeholder="一行一个,最多可回退500个号码"></el-input>
					</el-form-item>
					<el-form-item label="上传文件" prop="" v-if="addForm.type == '2'">
						<el-upload ref="upload" action="/api/Snail/BatchDeduction" :headers="token" :limit="1"
							:on-success="uploadSuccess2" :auto-upload="false" :data="addForm">
							<el-button size="small" type="primary" :class="[theme]"
								style="margin-top: 50px;">选择文件</el-button>
							<div class="el-upload__tip">只能导入txt文件</div>
						</el-upload>
					</el-form-item>
					<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
						<el-button size="small" @click="closeDialog">取消</el-button>
						<el-button size="small" type="primary" :class="[theme]" v-if="addForm.type == '1'"
							@click="getSnailBatchDeduction">确定</el-button>
						<el-button size="small" type="primary" :class="[theme]" v-if="addForm.type == '2'"
							@click="submitUpload2">确定</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>
			<el-dialog title="充值" v-model="singleVisible" width="520px" @close="closesingleDialog">
				<el-form label-width="120px" :model="singleForm" ref="editForm">
					<el-form-item label="选择模式">
						<el-radio-group v-model="singleForm.type">
							<el-radio label="1">手动输入</el-radio>
							<el-radio label="2">批量模板</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="金额" v-if="singleForm.type == '1'">
						<el-radio-group v-model="singleForm.amount">
							<el-radio v-for="item in amountList" :key="item.value" :label="item.value"
								:value="item.value" style="margin: 18px 10px;float: left;">
								{{ item.label }}
							</el-radio>
							<el-input v-model="singleForm.amount" size="small" placeholder="其他金额"
								style="width: 160px;margin: 5px 10px;float: left;" type="number"></el-input>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="手机号码" prop="phones" v-if="singleForm.type == '1'">
						<el-input size="small" :rows="5" type="textarea" v-model="singleForm.phones" auto-complete="off"
							placeholder="一行一个,英文逗号分隔,支持批量充值"></el-input>
					</el-form-item>
					<el-form-item label="示例" v-if="singleForm.type == '2'">
						<div style="display: flex;flex-direction: column;">
							<span>17012345678,10</span>
							<span>一行一个英文逗号分隔</span>
						</div>
					</el-form-item>

					<el-form-item label="上传文件" prop="" v-if="singleForm.type == '2'">
						<el-upload ref="upload" action="/api/Snail/topUp" :headers="token" :limit="1"
							:on-success="uploadSuccess" :auto-upload="false" :data="singleForm">
							<el-button size="small" type="primary" :class="[theme]"
								style="margin-top: 50px;">选择文件</el-button>
							<div class="el-upload__tip">只能导入txt文件</div>
						</el-upload>
					</el-form-item>
					<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
						<el-button size="small" @click="closesingleDialog">取消</el-button>
						<el-button size="small" type="primary" :class="[theme]" v-if="singleForm.type == '1'"
							@click="getSnailTopUp">确定</el-button>
						<el-button size="small" type="primary" :class="[theme]" v-if="singleForm.type == '2'"
							@click="submitUpload">确定</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>
			<el-dialog title="APP充值" v-model="APPVisible" width="450px" @close="closeAPPDialog">
				<el-form label-width="120px" :model="APPForm" ref="editForm">
					<el-form-item label="APP充值:" prop="bon">
						<el-radio-group v-model="APPForm.bon">
							<el-radio :label="true">开启</el-radio>
							<el-radio :label="false">关闭</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
						<el-button size="small" @click="closeAPPDialog">取消</el-button>
						<el-button size="small" type="primary" :class="[theme]" @click="getstopDxtApp">确定</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>
			<el-dialog v-model="filterBox" width="450px">
				<div class="filter-box">
					<div>
						<i class="el-icon-success" style="color: #40FF63;"></i>
						导入成功：{{ successData.length }}条
					</div>
					<div v-if="failedData.length > 0" @click="errortab = true" class="icon-class">
						<i class="el-icon-error" style="color: #FFB640;"></i>
						格式错误：
						<span>{{ failedData.length }}</span>
						条
					</div>
					<div v-else>
						<i class="el-icon-error" style="color: #FFB640;"></i>
						格式错误：0 条
					</div>
				</div>
			</el-dialog>
			<el-dialog v-model="errortab" width="450px">
				<el-table :data="failedData" border class="table" ref="multipleTable"
					header-cell-class-name="table-header" :row-style="{ height: '20px' }"
					:cell-style="{ padding: '6px' }" :header-cell-style="{ 'text-align': 'center' }">
					<el-table-column prop="label" label="号码" align="center" width="200"></el-table-column>
					<el-table-column prop="value" label="原因" align="center"></el-table-column>
				</el-table>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import {
		batchQueryRecord,
		fundPoolAdd,
		fundPool,
		snailShowRemittanceInfo,
		batchTopUpRecord,
		topUpRecordDownload,
		snailTopUp,
		stopWNApp,
		WNEnabled,
		ReturnPremium,
		snailBatchDeduction,
		snailTopUpRecord,
		queryDeptBalance
	} from '../api/dijia.js';
	import {
		fetchCompanyByAgentId,
		fetchDefaultCompanies
	} from '../api/companyIndex';
	import {
		fetchAgent
	} from '../api/agentIndex';
	export default {
		name: 'wnRecharge',
		data() {
			return {
				theme: localStorage.getItem('theme'),
				theme3: localStorage.getItem('theme3'),
				agents: [],
				companys: [],
				singlequery: {
					pageIndex: 1,
					pageSize: 10,
					phone: '',
					batchNo: '',
					startTime: '',
					endTime: ''
				},
				singletableData: [],
				singletotal: '',
				amountList: [{
						value: 10,
						label: '10兔兔币'
					},
					{
						value: 20,
						label: '20兔兔币'
					},
					{
						value: 30,
						label: '30兔兔币'
					},
					{
						value: 50,
						label: '50兔兔币'
					},
					{
						value: 100,
						label: '100兔兔币'
					},
					{
						value: 300,
						label: '300兔兔币'
					},
					{
						value: 500,
						label: '500兔兔币'
					}
				],
				singleVisible: false,
				singleForm: {
					userId: localStorage.getItem('user'),
					phones: '',
					amount: '',
					type: '1'
				},
				addForm: {
					userId: localStorage.getItem('user'),
					phones: '',
					type: '1'
				},
				batchquery: {
					pageIndex: 1,
					pageSize: 10
				},
				batchtableData: [],
				batchpageTotal: 0,
				dxtDet: '',
				activeName: 'single',
				uploadData: {
					userId: localStorage.getItem('user')
				},
				addVisible: false,
				tableData: [],
				pageTotal: 0,
				token: {
					Authorization: localStorage.getItem('token'),
					UserRole: localStorage.getItem('ms_role')
				},
				path: process.env.BASE_URL,
				query: {
					pageIndex: 1,
					pageSize: 10,
					userId: localStorage.getItem('user')
				},
				addCapitalNum: false,
				capitalForm: {
					amount: ''
				},

				capitaltotal: '',
				APPVisible: false,
				APPForm: {
					bon: false
				},
				filterBox: false,
				successData: [],
				failedData: [],
				errortab: false,
				timeArr: [],
				htquery: {
					fileName: '',
					pageIndex: 1,
					pageSize: 10
				},
				balance: '',
				batchNumber: ''
			};
		},

		created() {
			queryDeptBalance().then(res => {
				if (res.code == 200) {
					this.balance = res.data;
				} else {
					this.$message.error(res.message);
				}
			});
			this.getsnailShowRemittanceInfo();
		},

		methods: {
			tableRowClassName({
				rowIndex
			}) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},
			handleClick(tab, event) {
				this.activeName = tab.props.name;
				if (tab.props.name == 'single') {
					this.getsnailShowRemittanceInfo();
				} else if (tab.props.name == 'batch') {
					this.getsnailTopUpRecord();
				}
			},
			getsnailShowRemittanceInfo() {
				if (this.timeArr.length > 0) {
					let data = {
						phone: this.singlequery.phone,
						batchNo: this.singlequery.batchNo,
						startTime: this.convertDateStringToTimestamp(this.toDateTimeStr(this.timeArr[0])),
						endTime: this.convertDateStringToTimestamp(this.toDateTimeStr(this.timeArr[1])) + 86399000,
						pageIndex: this.singlequery.pageIndex,
						pageSize: this.singlequery.pageSize
					};
					snailShowRemittanceInfo(data).then(res => {
						if (res.code == 200) {
							this.singletableData = res.data.list;
							this.singletotal = res.data.total;
						} else {
							this.$message.error(res.message);
						}
					});
				} else {
					let data = {
						phone: this.singlequery.phone,
						batchNo: this.singlequery.batchNo,
						pageIndex: this.singlequery.pageIndex,
						pageSize: this.singlequery.pageSize
					};
					snailShowRemittanceInfo(data).then(res => {
						if (res.code == 200) {
							this.singletableData = res.data.list;
							this.singletotal = res.data.total;
						} else {
							this.$message.error(res.message);
						}
					});
				}
			},

			getsnailTopUpRecord() {
				snailTopUpRecord(this.batchquery).then(res => {
					if (res.code == 200) {
						this.batchtableData = res.data.list;
						this.batchpageTotal = res.data.total;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			submitUpload() {
				if (this.$refs.upload.uploadFiles[0]) {
					this.loading = true;
					this.$refs.upload.submit();
					if (this.$refs.upload.uploadFiles[0].response.code == 200) {

						this.$confirm(this.$refs.upload.uploadFiles[0].response.data, '提示', {
								type: 'success',
								confirmButtonText: '复制',
							})
							.then(() => {
								let cInput = document.createElement('input');
								cInput.value = this.$refs.upload.uploadFiles[0].response.data;
								document.body.appendChild(cInput);
								cInput.select();
								document.execCommand('copy');
								this.$message({
									type: 'succes',
									message: '复制成功'
								});
								document.body.removeChild(cInput);
							})
							.catch(() => {});
					}
				} else {
					this.$message.error('请选择上传文件');
				}
			},
			submitUpload2() {
				if (this.$refs.upload.uploadFiles[0]) {
					this.loading = true;
					this.$refs.upload.submit();
					this.addVisible = false;
				} else {
					this.$message.error('请选择上传文件');
				}
			},
			getReturnPremium(row) {
				this.$confirm('确定要返销该批次数据吗？', '提示', {
						type: 'warning'
					})
					.then(() => {
						let data = {
							userId: localStorage.getItem('user'),
							uid: row.uid,
							phone: row.phone
						};
						ReturnPremium(data).then(res => {
							if (res.code == 200) {
								this.getsnailShowRemittanceInfo();
							} else {
								this.getsnailShowRemittanceInfo();
								this.$message.error(res.message);
							}
						});
					})
					.catch(() => {});
			},
			gettopUpRecordDownload(uid) {
				this.$confirm('确定要下载该批次数据吗？', '提示', {
						type: 'warning'
					})
					.then(() => {
						let data = {
							uid: uid
						};
						topUpRecordDownload(data).then(res => {
							let link = document.createElement('a');
							link.style.display = 'none';
							link.href = res.data;
							document.body.appendChild(link);
							link.click();
						});
					})
					.catch(() => {});
			},
			//导入上传
			uploadSuccess(success) {
				// this.filterBox = true;
				this.$confirm(success.data, '提示', {
						type: 'success',
						confirmButtonText: '复制',
					})
					.then(() => {
						let cInput = document.createElement('input');
						cInput.value = success.data;
						document.body.appendChild(cInput);
						cInput.select();
						document.execCommand('copy');
						this.$message({
							type: 'succes',
							message: '复制成功'
						});
						document.body.removeChild(cInput);
						this.singleVisible = false;

					})
					.catch(() => {});
				this.getsnailShowRemittanceInfo();
				this.singleVisible = false;
				this.successData = success.data.success;
				this.failedData = success.data.failed;
			},

			getaddVisible() {
				this.addVisible = true;
			},

			getAdd() {
				this.singleVisible = true;
			},

			closesingleDialog() {
				this.singleForm.type = '1';
				this.singleForm.phones = '';
				this.singleForm.amount = '';
				this.singleVisible = false;
			},
			getSnailTopUp() {
				if (this.singleForm.amount > this.balance) {
					this.$message.error('余额不足');
				} else {
					let data = {
						phones: this.singleForm.phones,
						amount: this.singleForm.amount,
						type: this.singleForm.type,
						file: this.singleForm.file,
						userId: localStorage.getItem('user')
					};
					snailTopUp(data).then(res => {
						if (res.code == 200) {
							// this.filterBox = true;
							this.getsnailShowRemittanceInfo();
							this.singleVisible = false;
							// this.successData = res.data.success;
							// this.failedData = res.data.failed;
							this.$confirm(res.data, '提示', {
									type: 'success',
									confirmButtonText: '复制',
								})
								.then(() => {
									let cInput = document.createElement('input');
									cInput.value = res.data;
									document.body.appendChild(cInput);
									cInput.select();
									document.execCommand('copy');
									this.$message({
										type: 'succes',
										message: '复制成功'
									});
									document.body.removeChild(cInput);
								})
								.catch(() => {});
						} else {
							this.$message.error(res.message);
						}
					});
				}
			},
			getAPPAdd() {
				this.APPVisible = true;
				this.getdxtEnabled();
			},
			closeAPPDialog() {
				this.APPVisible = false;
			},

			getstopDxtApp() {
				let data = {
					bon: this.APPForm.bon,
					userId: localStorage.getItem('user')
				};
				stopWNApp(data).then(res => {
					if (res.code == 200) {
						this.$message.success(res.data);
						this.APPVisible = false;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			getdxtEnabled() {
				WNEnabled().then(res => {
					if (res.code == 200) {
						this.APPForm.bon = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			singlehandlePageChange(val) {
				this.singlequery.pageIndex = val;
				this.getsnailShowRemittanceInfo();
			},
			singlehandleSizeChange(val) {
				this.singlequery.pageSize = val;
				this.getsnailShowRemittanceInfo();
			},
			batchhandlePageChange(val) {
				this.batchquery.pageIndex = val;
				this.getsnailTopUpRecord();
			},
			batchhandleSizeChange(val) {
				this.batchquery.pageSize = val;
				this.getsnailTopUpRecord();
			},
			addCapital() {
				this.addCapitalNum = true;
			},
			closeCapitalNum() {
				this.addCapitalNum = false;
				this.capitalForm.amount = '';
			},
			getcapitalPoolAdd() {
				let data = {
					amount: this.capitalForm.amount,
					userId: localStorage.getItem('user')
				};
				fundPoolAdd(data).then(res => {
					if (res.code == 200) {
						this.$message.success(res.data);
						this.addCapitalNum = false;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			getTime() {
				//时间
				let startTime = new Date(new Date());
				let endTime = new Date();
				this.timeArr.push(startTime);
				this.timeArr.push(endTime);
			},

			toDateTimeStr(date) {
				let y = date.getFullYear();
				let m = date.getMonth() + 1;
				let d = date.getDate();
				if (m < 10) {
					m = '0' + m;
				}
				if (d < 10) {
					d = '0' + d;
				}
				return y + '-' + m + '-' + d;
			},
			convertDateStringToTimestamp(dateString) {
				// 假设dateString的格式为"YYYY-MM-DD"
				const parts = dateString.split('-');
				const year = parseInt(parts[0], 10);
				const month = parseInt(parts[1], 10) - 1; // 注意月份是从0开始的
				const day = parseInt(parts[2], 10);

				// 创建一个新的Date对象
				const date = new Date(year, month, day);

				// 转换为时间戳
				const timestamp = date.getTime();

				return timestamp;
			},
			timestampToDateString(timestamp) {
				const date = new Date(timestamp);
				const year = date.getFullYear();
				const month = String(date.getMonth() + 1).padStart(2, '0');
				const day = String(date.getDate()).padStart(2, '0');
				return `${year}-${month}-${day}`;
			},
			timestampToDateString2(timestamp) {
				const date = new Date(timestamp);
				const year = date.getFullYear();
				const month = String(date.getMonth() + 1).padStart(2, '0');
				const day = String(date.getDate()).padStart(2, '0');
				const hours = String(date.getHours()).padStart(2, '0');
				const minutes = String(date.getMinutes()).padStart(2, '0');
				const seconds = String(date.getSeconds()).padStart(2, '0');

				// 拼接年月日时分秒字符串
				return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
			},
			getSnailBatchDeduction() {
				let data = {
					phones: this.addForm.phones,
					type: this.addForm.type,
					file: this.addForm.file,
					userId: localStorage.getItem('user')
				};
				snailBatchDeduction(data).then(res => {
					if (res.code == 200) {
						this.$message.success(res.message);
						this.addVisible = false;
						this.addForm.phones = '';
						this.addForm.type = '1';
					} else {
						this.$message.error(res.message);
					}
				});
			},
			uploadSuccess2(success) {
				this.addVisible = false;
				this.getsnailTopUpRecord();
				this.$message.success(success.message);
			},
			closeDialog() {
				this.addVisible = false;
				this.addForm.phones = '';
				this.addForm.type = '1';
			}
		}
	};
</script>

<style scoped>
	.handle-box {
		margin-bottom: 20px;
	}

	/* a:hover {
	background: #66b1ff;
} */
	.handle-select {
		width: 240px;
	}

	.table {
		width: 100%;
		font-size: 12px;
	}

	.red {
		color: #f56c6c;
	}

	.mr10 {
		margin-right: 10px;
	}

	.mr100 {
		position: absolute;
		right: 100px;
	}

	.myRight {
		position: absolute;
		right: 10px;
		bottom: 10px;
	}

	.handle-input {
		width: 200px;
		display: inline-block;
	}

	.recharge-box .el-radio__input {
		display: none;
	}

	.recharge-box .el-radio.is-bordered {
		padding: 8px 14px 0 5px;
		border-radius: 4px;
		border: 1px solid #dcdfe6;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		height: 30px;
	}

	.table-box {
		width: 1100px;
		border: 1px solid #ebeef5;
		display: flex;
		flex-direction: row;
	}

	.user-info-box {
		/* border: 1px solid #ebeef5; */

		width: 300px;
	}

	.user-info-tit {
		background: #fafafa;
		color: #000;
		font-weight: 600;
		text-align: center;
		height: 34px;
		line-height: 34px;
		border-right: 1px solid #ebeef5;
		border-bottom: 1px solid #ebeef5;
	}

	.info-box {
		display: flex;
		flex-direction: row;
		height: 34px;
		line-height: 34px;
		border-bottom: 1px solid #ebeef5;
		border-right: 1px solid #ebeef5;
	}

	.info-name {
		background: #fafafa;
		border-right: 1px solid #ebeef5;
		width: 120px;
		padding-left: 10px;
		font-weight: 600;
	}

	.user-info-box .info-box .info {
		padding-left: 10px;
		color: green;
	}

	.down {
		border: 1px solid #dcdfe6;
		background: #4f7afd;
		color: #ffffff;
		transition: 0.1s;
		font-weight: 500;
		padding: 6px 12px;
		font-size: 12px;
		border-radius: 4px;

		display: inline-block;
	}

	.grid-content {
		display: flex;
		align-items: center;
		height: 100px;
	}

	/deep/ .el-col-16 {
		flex: 0 0 59.9%;
	}

	.grid-cont-right {
		flex: 1;
		text-align: center;
		font-size: 20px;
		font-weight: bold;
		color: #999;
	}

	.grid-num {
		font-size: 30px;
		font-weight: bold;
	}

	.grid-con-icon {
		font-size: 50px;
		width: 100px;
		height: 100px;
		text-align: center;
		line-height: 100px;
		color: #fff;
	}

	.grid-con-1 .grid-con-icon {
		background: linear-gradient(#ffb199, #ff0844);
	}

	.new {
		color: #b5b4b4;
		font-size: 12px;
	}

	.grid-con-1 .grid-num {
		color: #4f7afd;
	}

	.grid-con-2 .grid-con-icon {
		background: linear-gradient(#48c6ef, #6f86d6);
	}

	.grid-con-2 .grid-num {
		color: #4f7afd;
	}

	.grid-con-3 .grid-con-icon {
		background: linear-gradient(#e5b2ca, #7028e4);
	}

	.grid-con-3 .grid-num {
		color: #4f7afd;
	}

	.grid-con-4 .grid-con-icon {
		background: linear-gradient(#00c6fb, #005bea);
	}

	.grid-con-4 .grid-num {
		color: #4f7afd;
	}

	.grid-con-5 .grid-con-icon {
		background: linear-gradient(#89f7fe, #66a6ff);
	}

	.grid-con-5 .grid-num {
		color: #4f7afd;
	}

	.icon-class {
		cursor: pointer;
	}

	.icon-class span {
		color: #ff0000;
		font-weight: 600;
		text-decoration: underline;
	}
</style>