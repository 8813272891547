<template>
	<div>
		<audio @timeupdate.stop="updateProgress" controls ref="audioRef" style="display: none" preload>
			<source :src="fileurl" type="audio/ogg" />
			<source :src="fileurl" type="audio/mpeg" />
			您的浏览器不支持音频播放
		</audio>

		<div class="audio_right" @click.stop>
			<div v-if="convertToNumber(durationTime) > 0">
				<img v-if="!audioIsPlay" @click.stop="playAudio" class="audio_icon" src="../assets/img/pause.png" alt="暂停" />
				<img v-if="audioIsPlay" @click.stop="playAudio" class="audio_icon" src="../assets/img/play.png" alt="播放" />
				&nbsp;&nbsp;
				<span class="audio_current">{{ audioStart }}</span>
				<el-slider class="slider_box" v-model="currentProgress" :show-tooltip="false" @change="handleProgressChange" />
				&nbsp;&nbsp;
				<span class="audio_total">{{ durationTime }}</span>
				<el-select v-model="value" size="small" placeholder="请选择" @change="changeRate" style="width: 90px;padding-left: 10px;">
					<el-option label="0.5倍" value="0.5"></el-option>
					<el-option label="1倍" value="1"></el-option>
					<el-option label="1.25倍" value="1.25"></el-option>
					<el-option label="1.5倍" value="1.5"></el-option>
					<el-option label="1.75倍" value="1.75"></el-option>
					<el-option label="2倍" value="2"></el-option>
					<el-option label="2.5倍" value="2.5"></el-option>
					<el-option label="3倍" value="3"></el-option>
				</el-select>
				<el-button size="small" type="text" icon="el-icon-download" @click.stop="downloadRecord2()">下载</el-button>
			</div>
			<div v-else style="display: flex;flex-direction: column;">
				<div>
					<img class="audio_icon" src="../assets/img/play2.png" alt="播放" />
					&nbsp;&nbsp;
					<span class="audio_current">{{ audioStart }}</span>
					<span class="slider_style"></span>
					&nbsp;&nbsp;
					<span class="audio_total">{{ durationTime }}</span>
					&nbsp;&nbsp;&nbsp;&nbsp;
					<span class="audio_text">暂无录音</span>
				</div>
				<el-button size="small" type="text" icon="el-icon-refresh" class="record_btn" @click.stop="getfindRecordingPath()">修复</el-button>
			</div>
		</div>
	</div>
</template>

<script>
import { download, findRecordingPath } from '../api/recordIndex';

export default {
	data() {
		return {
			audioRef: null,
			audioIsPlay: true,
			audioStart: '0:00',
			durationTime: '0:00',
			duration: 0,
			audioVolume: 100,
			currentProgress: 0,
			value: '1倍'
		};
	},
	props: {
		fileurl: {
			type: String,
			default: ''
		},
		row: {
			type: Object,
			default: {}
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.calculateDuration();
		});
	},
	methods: {
		changeRate(e) {
			this.value = e;
			this.$refs.audioRef.playbackRate = e;
		},
		calculateDuration() {
			var myVid = this.$refs.audioRef;
			myVid.loop = false;
			myVid.src = this.fileurl;
			// console.log(myVid, 'ooooo');
			// 监听音频播放完毕
			myVid.addEventListener(
				'ended',
				function() {
					this.audioIsPlay = true;
					this.currentProgress = 0;
				},
				false
			);
			// console.log(myVid, 'myVid');

			if (myVid != null) {
				myVid.oncanplay = () => {
					this.duration = myVid.duration; // 计算音频时长
					// console.log(this.transTime(parseInt(myVid.duration)), 'this.transTime(parseInt(myVid.duration))');
					this.durationTime = this.transTime(parseInt(myVid.duration)); //换算成时间格式
				};
				myVid.volume = 1; // 设置默认音量50%
			}
		},
		convertToNumber(time) {
			const [hours, minutes] = time.split(':').map(Number);
			return minutes + hours * 60;
		},
		transTime(duration) {
			const minutes = Math.floor(duration / 60);
			const seconds = Math.floor(duration % 60);
			const formattedMinutes = String(minutes).padStart(2, '0'); //padStart(2,"0") 使用0填充使字符串长度达到2
			const formattedSeconds = String(seconds).padStart(2, '0');
			return `${formattedMinutes}:${formattedSeconds}`;
		},
		playAudio() {
			if (this.$refs.audioRef.duration) {
				if (this.$refs.audioRef.paused) {
					this.$refs.audioRef.play();
					this.audioIsPlay = false;
				} else {
					this.$refs.audioRef.pause();
					this.audioIsPlay = true;
				}
			} else {
				return;
			}
		},
		updateProgress(e) {
			var value = this.$refs.audioRef.currentTime / this.$refs.audioRef.duration;
			if (this.$refs.audioRef.play) {
				this.currentProgress = value * 100;
				this.audioStart = this.transTime(parseInt(this.$refs.audioRef.currentTime));
			}
		},

		//调整播放进度
		handleProgressChange(val) {
			// console.log(val, '是是是');
			if (!val) {
				return;
			}
			let currentTime = this.duration * (val / 100);
			this.$refs.audioRef.currentTime = currentTime;
		},

		//下载
		downloadRecord2() {
			let indexOf = this.fileurl.indexOf('=');
			let path = this.fileurl.substring(indexOf + 1);
			if (path === '') {
				this.$message.error('录音地址为空');
				return;
			}
			let date = new Date();
			let fileName = this.fileurl.substring(indexOf + 32);

			download(path).then(res => {
				let url = window.URL.createObjectURL(new Blob([res]), {
					type: 'application/mp3'
				});

				let link = document.createElement('a');

				link.style.display = 'none';
				link.href = url;
				link.setAttribute('download', fileName + '.mp3');
				document.body.appendChild(link);
				link.click();
			});
		},
		getfindRecordingPath() {
			let data = {
				id: this.row.id
			};
			findRecordingPath(data).then(res => {
				if (res.code == 200) {
					this.$message.success(res.data);
				} else {
					this.$message.error(res.message);
				}
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.audio_right div {
	width: 100%;
	height: 40px;
	display: flex;
	align-items: center;
	border-radius: 4px;
	box-sizing: border-box;
	position: relative;
	.slider_box {
		width: 160px;
		height: 0px;
		border-radius: 5px;
		background-color: #f1f1f1;
		flex: 1;
		margin: 0 8px 0px;
	}
	.audio_icon {
		width: 30px;
		height: 30px;
		cursor: pointer;
	}
	.audio_time {
		color: #f1f1f1;
		overflow: hidden;
		font-size: 12px;
		position: absolute;
		bottom: 3px;
		left: 80px;
		.audio_total {
			float: right;
		}
		.audio_current {
			float: left;
		}
	}
}
.volume {
	position: relative;
	.volume_progress {
		width: 32px;
		height: 140px;
		position: absolute;
		top: -142px;
		right: -4px;
	}
	.volume_bar {
		background: #f1f1f1;
		border-radius: 4px;
	}
	.volume_icon {
		width: 24px;
		height: 24px;
		cursor: pointer;
	}
}
</style>
<style lang="scss">
.slider_box,
.volume_bar {
	.el-slider__button {
		width: 8px;
		height: 8px;
		border: none;
	}
	.el-slider__bar {
		background: #00db15;
	}
}
.slider_box {
	.el-slider__button-wrapper {
		width: 8px;
	}
}
.slider_style {
	width: 140px;
	height: 6px;
	background-color: #e4e7ed;
	margin: 0 10px;
	border-radius: 6px;
}
.audio_text {
	color: #606266;
}
.volume_bar {
	.el-slider__runway {
		margin: 0 14px !important;
	}
}
</style>
